import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function SubServiceSelect(props) {
    const services = [...props.services];
    const mainServiceId = props.rowData.mainService;
    const subServices = [];

    const mainService = services.find(service => service.id === mainServiceId);

    if (mainService) {
        mainService.subServices.forEach(subService => {
            subService.enabled && subServices.push({
                id: subService.id,
                code: subService.code,
                name: subService.name,
            });
        });
    }

    // handle subService value when the main onChange is fired
    const inSubServices = subServices.some(element => element.id === props.value);
    const value = inSubServices ? props.value : 0;
    if (!inSubServices) {
        props.rowData.subService = 0;
    }

    return (
        <DefaultSelect
            id="sub-service-select"
            none={true}
            isFilter={false}
            multiple={false}
            value={value}
            onChange={e => props.onChange(e.target.value)}
            data={subServices}
            itemKey="code"
            itemValue="id"
            itemName="name"
        />
    )
}
