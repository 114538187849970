import React from 'react';

const BidProjectSpan = ({ isConfirmed = true, }) => {
    return (
        <span style={{
            backgroundColor: isConfirmed ? '#745492' : '#C06A1F',
            color: 'white'
        }}>AO</span>
    )
}

export default BidProjectSpan
