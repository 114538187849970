import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    users: [],
    user: null,
    loading: false,
    totalcount: 0
}

// default reducers
const getUserDefaultSuccess = (state, action) => {
    return updateObject(state, {
        users: action.users,
        loading: false
    });
}

//////////////////////////////  fecth users
const fetchUserStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const fetchUserSuccess = (state, action) => {
    return updateObject(state, {
        users: [...action.users],
        loading: false,
        totalcount: action.total
    });
};

const fecthUserFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

////////////////////////////// update user role + position
const setUserStart = (state, _action) => {
    return updateObject(state, {loading: true});
};

const setUserSuccess = (state, action) => {
    return getUserDefaultSuccess(state, action);
};

const setUserFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

////////////////////////////// get user details
const getUserStart = (state, _action) => {
    return updateObject(state, {loading: true});
};

//// set users to reuse the same function to et single shifts
const getUserSuccess = (state, action) => {
    return updateObject(state, {
        user: action.user,
        loading: false
    });
};

const getUserFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

////////////////////////////// get single user details for single shifts
const getSingleUserSuccess = (state, action) => {
    return getUserDefaultSuccess(state, action);
};


const userReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // fetch user data
        case actionTypes.FETCH_USERS_START: return fetchUserStart(state, action);
        case actionTypes.FETCH_USERS_SUCCESS: return fetchUserSuccess(state, action);
        case actionTypes.FETCH_USERS_FAIL: return fecthUserFail(state, action);
        // update user role + position
        case actionTypes.SET_USER_START: return setUserStart(state, action);
        case actionTypes.SET_USER_SUCCESS: return setUserSuccess(state, action);
        case actionTypes.SET_USER_FAIL: return setUserFail(state, action);
        // get user
        case actionTypes.GET_USER_START: return getUserStart(state, action);
        case actionTypes.GET_USER_SUCCESS: return getUserSuccess(state, action);
        case actionTypes.GET_USER_FAIL: return getUserFail(state, action);
        case actionTypes.GET_SINGLE_USER_SUCCESS: return getSingleUserSuccess(state, action);

        default: return state;
    }
};

export default userReducer;
