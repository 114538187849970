import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    affectations: [],
    affectation: null,
    loading: false,
    totalcount: 0,
    mercureHubUrl: null,
    mercureToken: '',
    actionCreatorMercure: false,
    data: null,
    globalStats: null
}

// set Mercure subscriber url + token
const setMercureData = (state, action) => {
    return updateObject(state, {
        mercureHubUrl: action.mercureHubUrl,
        mercureToken: action.mercureToken
    })
}
// fecth affectation actions
const fectchAffectationStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const fetchStatsSuccess = (state, action) => {
    return updateObject(state, {
        data: action.data.weekly,
        globalStats: action.data.global
    });
}

const fetchAffectationSuccess = (state, action) => {
    return updateObject(state, {
        affectations: action.affectations,
        loading: false,
        totalcount: action.total
    });
}

const fetchAffectationFail = (state, _action) => {
    return updateObject(state, { loading: false });
}
// add affectation actions
const addAffectationStart = (state, action) => {
    return affectationSimBody1(state, action);
}

const addAffectationSuccess = (state, action) => {
    return updateObject(state, {
        // affectations: action.affectations,
        // affectation: action.affectation,
        loading: false,
        totalcount: action.total,
        actionCreatorMercure: false,
    });
}

const addAffectationFail = (state, action) => {
    return affectationSimBody2(state, action);
}

// edit affectation actions
const editAffectationStart = (state, action) => {
    return affectationSimBody1(state, action);
}

const editAffectationSuccess = (state, action) => {
    return affectationSimBody2(state, action);
}

const editAffectationFail = (state, action) => {
    return affectationSimBody2(state, action);
}

// delete affectation actions
const deleteAffectationStart = (state, action) => {
    return affectationSimBody1(state, action);
}

const deleteAffectationSuccess = (state, _action) => {
    return affectationSimBody2(state, _action);
}

const deleteAffectationFail = (state, action) => {
    return affectationSimBody2(state, action);
}

// prev week affectation action
const prevWeekAffectationStart = (state, action) => {
    return affectationSimBody1(state, action);
}

const prevWeekAffectationSuccess = (state, action) => {
    return affectationSimBody2(state, action);
}

const prevWeekAffectationFail = (state, action) => {
    return affectationSimBody2(state, action);
}

// approve list of  requests action
const approveListRequestsStart = (state, _action) => {
    return updateObject(state, {
        loading: true,
        actionCreatorMercure: false,
    });
}

const approveListRequestsSuccess = (state, action) => {
    return affectationSimBody2(state, action);
}

const approveListRequestsFail = (state, action) => {
    return affectationSimBody2(state, action);
}

const emptyAffectationsList = (state, _action) => {
    return updateObject(
        state,
        {
            affectations: [],
        }
    );
}

const affectationReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // reset list
        case actionTypes.RESET_APP_DASHBOARD_AFFECTATIONS: return emptyAffectationsList(state, action);
        // set hub URL
        case actionTypes.SETUP_MERCURE: return setMercureData(state, action);
        // fecth actions
        case actionTypes.FETCH_AFFECTATIONS_START: return fectchAffectationStart(state, action);
        case actionTypes.FETCH_AFFECTATIONS_SUCCESS: return fetchAffectationSuccess(state, action);
        case actionTypes.FETCH_AFFECTATIONS_FAIL: return fetchAffectationFail(state, action);
        // add actions
        case actionTypes.ADD_AFFECTATION_START: return addAffectationStart(state, action);
        case actionTypes.ADD_AFFECTATION_SUCCESS: return addAffectationSuccess(state, action);
        case actionTypes.ADD_AFFECTATION_FAIL: return addAffectationFail(state, action);
        // edit actions
        case actionTypes.EDIT_AFFECTATION_START: return editAffectationStart(state, action);
        case actionTypes.EDIT_AFFECTATION_SUCCESS: return editAffectationSuccess(state, action);
        case actionTypes.EDIT_AFFECTATION_FAIL: return editAffectationFail(state, action);
        // delete actions
        case actionTypes.DELETE_AFFECTATION_START: return deleteAffectationStart(state, action);
        case actionTypes.DELETE_AFFECTATION_SUCCESS: return deleteAffectationSuccess(state, action);
        case actionTypes.DELETE_AFFECTATION_FAIL: return deleteAffectationFail(state, action);
        // prev week actions
        case actionTypes.PREV_WEEK_AFFECTATION_START: return prevWeekAffectationStart(state, action);
        case actionTypes.PREV_WEEK_AFFECTATION_SUCCESS: return prevWeekAffectationSuccess(state, action);
        case actionTypes.PREV_WEEK_AFFECTATION_FAIL: return prevWeekAffectationFail(state, action);
        // approve list requests actions
        case actionTypes.APPROVE_LIST_AFFECTATION_START: return approveListRequestsStart(state, action);
        case actionTypes.APPROVE_LIST_AFFECTATION_SUCCESS: return approveListRequestsSuccess(state, action);
        case actionTypes.APPROVE_LIST_AFFECTATION_FAIL: return approveListRequestsFail(state, action);
        case actionTypes.FETCH_STATS_SUCCESS: return fetchStatsSuccess(state, action);

        default: return state;
    }
}

export default affectationReducer;


// re-write similar functions bodies for code quality
export const affectationSimBody1 = (state, _action) => {
    return updateObject(state, {
        loading: true,
        actionCreatorMercure: true,
    });
}

export const affectationSimBody2 = (state, _action) => {
    return updateObject(state, {
        loading: false,
        actionCreatorMercure: false,
    });
}

export const affectationSimBody3 = (state, action) => {
    return updateObject(state, {
        affectations: action.affectations,
        loading: false,
        actionCreatorMercure: false,
    });
}
