import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    projects: [],
    loading: false,
    totalcount: 0,
}

//////////////////////////////  fecth projects
const fetchProjectStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const fetchProjectSuccess = (state, action) => {
    return updateObject(state, {
        projects: action.projects,
        loading: false,
        totalcount: action.total,
    });
}

const fecthProjectFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

const projectReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // fecth projects
        case actionTypes.FETCH_PROJECTS_START: return fetchProjectStart(state, action);
        case actionTypes.FETCH_PROJECTS_SUCCESS: return fetchProjectSuccess(state, action);
        case actionTypes.FETCH_PROJECTS_FAIL: return fecthProjectFail(state, action);

        default: return state;
    }
}

export default projectReducer;
