import React from 'react';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core';

export default function RadioGroups(props) {
    return (
        <FormControl component="fieldset" variant="outlined" >
            <RadioGroup
                row={true}
                aria-label={props.label}
                name={props.name}
                value={props.defaultValue}
                onChange={props.onChangeData} >
                {props.dataArray.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option[props.optValueKey]}
                        label={option[props.optLabelKey]}
                        control={<Radio color="primary" />}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
}
