import { API_RESOURCES, axiosInstance } from "./utils/apiResources";

export const recurrenceRuleService = {
    deleteRecurrenceRule,
}

function deleteRecurrenceRule(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.RECURRENCE_RULES}/${id}`
    );
}
