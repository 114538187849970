import { isEmpty } from "lodash";

export const ROLE = {
    RESOURCE: 'ROLE_RESOURCE',
    PROJECT_MANAGER: 'ROLE_PROJECT_MANAGER',
    VALIDATOR: 'ROLE_VALIDATOR',
    ADMIN: 'ROLE_ADMIN',
}

/**
 * The `service` key is set from the API, by default it is the development service
 * unless there is a service assigned to the connected resource.
 * If it is null in the `Dashboard.jsx` we point to ALL_SERVICES(0) tab.
 */
export function setLocalStorageValues(data = {}, rememberMe = false) {
    if (isEmpty(data)) {
        return;
    }

    const params = [
        {
            key: 'token',
            value: data.token
        },
        {
            key: 'userId',
            value: data.data.userId
        },
    ];

    if (rememberMe) {
        params.push({
            key: 'refreshToken',
            value: data.refreshToken
        })
    }

    params.forEach(item => localStorage.setItem(item.key, item.value));
}
