import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { toTitleCase } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

import './Card.scss';
import variables from '../../../../assets/Styles/variables.module.scss';

class CardProfile extends Component {
  componentDidMount() {
    this.props.onGetUserRole(this.props.loggedUserId);
  }

  resetPwdSendEmail = () => {
    const resetPassEmailBody = {
      email: this.props.user.email
    }
    this.props.onResetPwdEmail(resetPassEmailBody);
  }

  render() {
    const userDetails = this.props.user;
    return (
      <div>
        <Card className="cardMain">
          {!this.props.loading && userDetails ? <>
            <CardHeader
              avatar={
                <Avatar style={{ backgroundColor: variables.mainColor }} className="avatar" aria-label="recipe" >
                  {toTitleCase(userDetails.fullName)}
                </Avatar>
              }
              title={userDetails.fullName}
              subheader={userDetails.location}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                My Details
              </Typography>
              <h5>email: </h5> <p>{userDetails.email}</p>
              <h5>service: </h5> <p>{userDetails.mainService?.name || 'None'}</p>
              <h5>jobtitle: </h5> <p>{userDetails.jobTitle}</p>
            </CardContent>
            <CardActions>
              <Button style={{ marginLeft: 'auto' }} size="medium" color="primary" onClick={() => this.resetPwdSendEmail()}>
                Forget my password
              </Button>
            </CardActions>
          </> : <>
            <div style={{ display: 'flex' }}>
              <Skeleton style={{ marginTop: '15px', marginLeft: '10px' }} variant="circle" width={40} height={40} />
              <CardHeader
                avatar={
                  <Skeleton variant="circle" />
                }
                title={<Skeleton variant="text" width={150} />}
                subheader={<Skeleton variant="text" width={100} />}
              />
            </div>
            <CardContent>
              <Skeleton variant="rect" width={300} height={250} />
            </CardContent>
          </>
          }
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading,
    token: state.auth.token,
    loggedUserId: state.app.user.userId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetUserRole: (userId) => dispatch(actions.getUser(userId, false)),
    onResetPwdEmail: (body) => dispatch(actions.resetPwdEmail(body))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardProfile)
