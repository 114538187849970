import React, { Component } from 'react';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import moment from 'moment';
import { debounce, isEmpty } from 'lodash';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Grid,
    IconButton,
    FormLabel,
    Box,
    Tabs,
    Tab,
    Radio,
    FormControlLabel,
    Chip,
    Avatar,
    TextField
} from '@material-ui/core';
import {
    Close as CloseIcon,
    Add as AddIcon,
    HelpOutline as HelpOutlineIcon,
    AccountTreeRounded as AccountTreeRoundedIcon,
    PersonRounded as PersonRoundedIcon,
    PriorityHighRounded as PriorityHighRoundedIcon,
    NotesRounded as NotesRoundedIcon,
    RepeatRounded as RepeatRoundedIcon,
    ArrowForward as ArrowForwardIcon,
    Done as DoneIcon,
} from '@material-ui/icons';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { LocalizationProvider, TimePicker } from '@material-ui/pickers';

import * as actions from '../../../../store/actions/index';
import {
    updateObject,
    transformEndDate,
    checkedOfficesData,
    transformEndDateDisplay,
    toTitleCase,
    DATE_TIME_FORMAT,
} from '../../../../shared/utility';
import { dayoffDataTypes, halfDayData, halfDayType, setDayOffStartEnd } from '../../../../shared/dayOffUtility';
import Toggle from '../../Toggle/Toggle';
import SelectBox from '../../SelectBox/SelectBox';
import DatePickerForm from '../../DatePicker/DatePickerForm';
import BigTextField from '../../BigTextFiled/BigTextField';
import RadioGroups from '../../RadioGroups/RadioGroups';
import DateRange1 from '../../DateRange/DateRange1';
import AutoCompleteGlobal from '../../AutoComplete/AutoCompleteGlobal';
import RecurrenceSelect from '../../Select/Recurrence/RecurrenceSelect'
import { buildRrulePayload } from '../../../../shared/rRule/rrule';
import { API_RESOURCES, API_URL } from '../../../../services/utils/apiResources';
import { PROJECT_FILTERS } from '../../../../services/utils/filters';
import { getEndWorkHour, getStartWorkHour } from '../../../../shared/fullCalendarUtility';
import { getmainSubServiceName } from '../../../../shared/userUtility';
import { canCUDDayOff } from '../../../../shared/authorizationUtility';

import classes from './ModalUI.module.scss';
import variables from '../../../../assets/Styles/variables.module.scss';

function PaperComponent(props) {
    return (
        <Draggable handle="#scroll-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} className={classes.draggableDialog}>
            <Paper {...props} className={`${classes.dialog} ${props.className}`} />
        </Draggable>
    );
}

class ModalUIAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            popperAnchorEl: null,
            formData: {
                project: null,
                projectSelect: '',
                user: '',
                userSelect: [],
                daySlot: '',
                date: '',
                priority: '',
                notes: '',
                startTime: '',
                endTime: '',
                daySlotOrHourly: 0,
            },
            touched: false,
            // for day off
            openRange: false,
            tabValue: 0,
            dayOffData: {
                actionType: 'standard',
                dayoffType: null,
                selectedDateRange: [null, null],
                halfDayStart: halfDayType.HALF_DAY,
                halfDayEnd: halfDayType.HALF_DAY,
                userdayoff: null,
                userSelectDayoff: null,
                userOrOffice: 0,
                offices: [...checkedOfficesData]
            },
            // used for projects aysnchronous load
            openAutoProject: false,
            // Common between `affectation` and `day-off`.
            // if no until is provided define a start + 1year as an endDateTime.
            rrule: {},
        }
    }

    componentDidMount() {
        // to open modal outside this modal component
        this.props.onRef(this.handleClickOpen.bind(this));
    }

    componentDidUpdate() {
        this.calendarApi = this.props.calendarRef.current.getApi();
    }

    //  modal { open + close }
    handleClickOpen = () => {
        const { project, user, daySlot, date, priority, notes, startTime, endTime, daySlotOrHourly } = this.props.formData1;
        const { selectedDateRange, halfDayStart, halfDayEnd, userdayoff, offices, actionType } = this.props.dayOffData1;

        // adjust state params for affectation;
        const findedUser = this.props.users.find(x => parseInt(x.id) === parseInt(user));
        const updatedElements = updateObject(this.state.formData, {
            project: project,
            user: user,
            daySlot: daySlot,
            date: date,
            priority: priority,
            notes: notes,
            userSelect: [{
                id: findedUser?.id || 0,
                fullname: findedUser?.fullName || ' None',
                isFictive: findedUser ? findedUser.isFictive : true,
                mainSubServiceName: findedUser ? getmainSubServiceName(findedUser) : ' None',
            }],
            projectSelect: {
                id: 0,
                name: ' None'
            },
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            daySlotOrHourly
        });
        // adjust state params for dayoff
        const findedUserDayoff = this.props.users.find(x => parseInt(x.id) === parseInt(userdayoff));

        const updatedElements1 = updateObject(this.state.dayOffData, {
            dayoffType: null,
            selectedDateRange: [...selectedDateRange],
            halfDayStart: halfDayStart,
            halfDayEnd: halfDayEnd,
            userdayoff: userdayoff,
            userSelectDayoff: findedUserDayoff ? {
                id: findedUserDayoff.id,
                fullname: findedUserDayoff.fullName,
            } : null,
            offices: [...offices],
            actionType: actionType,
        });

        this.setState({
            tabValue: 0,
            open: true,
            formData: updatedElements,
            dayOffData: updatedElements1,
        });
    };

    handleClose = () => {
        const updatedElements = updateObject(this.state.formData, {
            project: null,
            user: '',
            daySlot: '',
            date: '',
            priority: '',
            notes: ''
        });

        const updatedElementsDayOff = updateObject(this.state.dayOffData, {
            dayoffType: null,
            selectedDateRange: [null, null],
            halfDayStart: halfDayType.HALF_DAY,
            halfDayEnd: halfDayType.ALL_DAY,
            userdayoff: null,
            userOrOffice: 0,
            offices: [...checkedOfficesData]
        });
        this.setState({
            open: false,
            openRange: false,
            formData: updatedElements,
            dayOffData: updatedElementsDayOff,
            rrule: {},
        });
    };

    handleTypeFormChange = (tabValue) => {
        this.setState({
            tabValue,
            rrule: {}
        });
    }

    // handle for mistyped resoucre / project name
    errorFlag = () => parseInt(this.state.formData.projectSelect.id) !== 0 &&
        parseInt(this.state.formData.userSelect.id) !== 0
        ;
    //////////////////////////////////   onChange for add affectation inputs
    // onChange for day slot {cannot specify name property for Toggle Group}
    onChangeDaySlot = (_e, value) => {
        const updatedElements = updateObject(this.state.formData, {
            daySlot: value
        });
        this.setState({ formData: updatedElements });
    }

    // onChange for date Picker {no name property comes with onChange}
    onChangeDatePicker = (e) => {
        if (moment(e).isValid()) {
            const dateFormatted = moment(e).format();
            const updatedElements = updateObject(this.state.formData, {
                date: dateFormatted
            });
            this.setState({
                formData: updatedElements,
                rrule: {}
            });
        }
    }

    // onchange for standard inputs that have names
    onChangeStandard = (e) => {
        if (e.target.name === 'project') {
            this.setState({ touched: !this.state.touched });
        }

        const updatedElements = updateObject(this.state.formData, {
            [e.target.name]: e.target.value
        });
        this.setState({ formData: updatedElements });
    }

    handleMultiSelect = (e, v) => {
        const payload = {
            ...this.state.formData,
            userSelect: v
        }

        this.setState({
            formData: payload
        });
    }

    handleAutoComplete = (_e, value, inputname) => {
        const updatedElements = updateObject(this.state.formData, {
            [inputname === 'userauto' ? 'userSelect' : 'projectSelect']: value
        });

        this.setState({
            formData: updatedElements
        })
    }

    handleStartEndTimeOnChange = (date, type = 'start') => {
        const updatedElements = updateObject(
            this.state.formData,
            {
                [type === 'start' ? 'startTime' : 'endTime']: date
            }
        );
        this.setState({
            formData: updatedElements
        })
    }

    handleSave = (event) => {
        event.preventDefault();
        const { date, daySlot, priority, notes, projectSelect, userSelect, startTime, endTime, daySlotOrHourly } = this.state.formData;
        const startDateTime = moment(date);
        let endDateTime = null;

        if (parseInt(daySlotOrHourly) === 1) {
            const startHours = getStartWorkHour(moment(startTime), false);
            const endHours = getEndWorkHour(moment(endTime), false);

            startDateTime.set('hours', startHours).set('minutes', 0).set('seconds', 0);
            endDateTime = moment(endTime).set('hours', endHours).set('minutes', 0).set('seconds', 0);

            // An event that lasts to the end of day, its end should be considered as the next day midnight
            if (endHours === 0 && startDateTime.get('date') === endDateTime.get('date')) {
                endDateTime.add(1, 'days');
            }

            // If the affectation in the same day so end date == start date
            if (daySlot < 1) {
                endDateTime.set('date', startDateTime.date())
            }
            endDateTime = endDateTime.format(DATE_TIME_FORMAT);
        } else {
            endDateTime = transformEndDate(date, daySlot);
        }

        let affectationBody = {
            isConfirmed: false,
            exigencyLevel: priority,
            startDateTime: startDateTime.format(DATE_TIME_FORMAT),
            endDateTime,
            notes: notes,
            assignedUsers: userSelect
        }

        if (parseInt(projectSelect.id) !== 0) {
            affectationBody = { ...affectationBody, assignedProject: projectSelect.id }
        }

        // rrule check
        const rruleBody = buildRrulePayload(this.state.rrule);
        isEmpty(rruleBody) ?
            this.props.onAddAffectation(affectationBody, this.calendarApi) :
            this.props.onAddRecurrentAffectation(
                {
                    ...affectationBody,
                    ...rruleBody
                },
                this.calendarApi,
                this.props.affectQueryParams
            );
        this.setState({ formData: this.props.formData1 });
        this.handleClose();
    }

    addNewAfffectationAndQuit = (event) => {
        this.handleSave(event);
        this.handleClickOpen();
    }

    //////////////////////////////////   onChange for add dayoff inputs
    onChangeDayOffInputs = (inputName, e) => {
        let updatedElements = null;
        switch (inputName) {
            case 'dayoffType':
                updatedElements = updateObject(
                    this.state.dayOffData,
                    {
                        'dayoffType': e.target.value,
                        'userOrOffice': e.target.value === dayoffDataTypes.HOLIDAY ? 1 : 0,
                    }
                );
                this.setState({ dayOffData: updatedElements });
                break;
            case 'standard':
            case 'switchHalfDayToggle':
                updatedElements = updateObject(this.state.dayOffData, {
                    [e.target.name]: e.target.value
                });
                this.setState({ dayOffData: updatedElements });
                break;

            case 'rangeDate':
                updatedElements = updateObject(this.state.dayOffData, {
                    selectedDateRange: e
                });
                this.setState({
                    actionType: 'standard',
                    dayOffData: updatedElements,
                    openRange: e[1] ? false : true,
                    rrule: {}
                });
                break;

            case 'offices':
                const { offices } = this.state.dayOffData;
                const offices1 = [...offices];
                const index = offices1.indexOf(e);

                e.checked = !e.checked;
                offices1[index] = { ...e };

                updatedElements = updateObject(this.state.dayOffData, {
                    offices: [...offices1],
                    dayoffType: dayoffDataTypes.HOLIDAY,
                });
                this.setState({
                    dayOffData: updatedElements,
                    rrule: {}
                });
                break;
            case 'userdayoffauto':
                updatedElements = updateObject(this.state.dayOffData, {
                    userSelectDayoff: e,
                    userdayoff: e.id
                });
                this.setState({ dayOffData: updatedElements });
                break;

            default:
        }
    }

    disabeDayoff = () => {
        const { dayoffType, offices, selectedDateRange, userdayoff, userOrOffice } = this.state.dayOffData;
        const arrayChecker = [false, false, false];
        // for resource or office
        if (parseInt(userOrOffice) === 0) {
            if (!userdayoff) {
                arrayChecker[2] = true;
            } else {
                arrayChecker[2] = false;
            }
        } else if (parseInt(userOrOffice) === 1) {
            if (offices.find((obj) => obj.checked === true)) {
                arrayChecker[2] = false;
            } else {
                arrayChecker[2] = true;
            }
        }

        if (selectedDateRange.includes(null)) {
            arrayChecker[1] = true;
        } else {
            arrayChecker[1] = false;
        }

        if (!dayoffType) {
            arrayChecker[0] = true;
        } else {
            arrayChecker[0] = false;
        }

        return arrayChecker.includes(true);
    }

    addNewDayoff = (event) => {
        event.preventDefault();
        const {
            dayoffType,
            halfDayEnd,
            halfDayStart,
            offices,
            selectedDateRange,
            userOrOffice,
            userdayoff,
            actionType,
        } = this.state.dayOffData;

        const dayOffRange = setDayOffStartEnd(actionType, selectedDateRange, halfDayStart, halfDayEnd);
        const startDayBody = dayOffRange[0];
        const endDayBody = dayOffRange[1];
        const isOfficeHoliday = parseInt(userOrOffice) === 1;

        let checkedOffices = [];
        if (isOfficeHoliday) {
            offices.forEach(x => {
                if (x.checked) {
                    checkedOffices.push(x.label)
                }
            });
        } else {
            checkedOffices = null;
        }

        let dayOffBody = {
            "typeDayOff": dayoffType,
            "startDateTime": startDayBody,
            "endDateTime": endDayBody,
            "office": isOfficeHoliday ? checkedOffices : null,
            "userDayOff": isOfficeHoliday ? null : `${API_URL}${API_RESOURCES.USERS}/${userdayoff}`,
        }

        // rrule check
        const rruleBody = buildRrulePayload(this.state.rrule);
        isEmpty(rruleBody) ?
            this.props.onAddDayoff(dayOffBody, this.calendarApi) :
            this.props.onAddRecurrentDayOff(
                {
                    ...dayOffBody,
                    ...rruleBody
                },
                this.calendarApi,
                this.props.dayOffQueryParams
            )

        this.handleClose();
    }

    getMatchedProjects = debounce((e) => {
        e.persist();
        this.props.onFetchProjects({
            [PROJECT_FILTERS.NAME]: e.target.value,
            [PROJECT_FILTERS.PAGINATION]: false,
        });
    }, 400);

    render() {
        const { formData, dayOffData, tabValue, openRange, open, openAutoProject } = this.state
        const { daySlot, date, priority, notes, userSelect, projectSelect, startTime, endTime, daySlotOrHourly } = formData;
        const { dayoffType, selectedDateRange, halfDayStart, halfDayEnd, userOrOffice, offices, userSelectDayoff } = dayOffData
        const { users, projects, exigencyLevelTypes, dayOffTypes } = this.props;

        const usersResourcesData = [];
        const projectsData = [{
            id: 0,
            name: ' None'
        }];

        if (tabValue === 0) {
            usersResourcesData.push({
                id: 0,
                fullname: ' None',
                isFictive: true,
                mainSubServiceName: ' None',
            });
        }

        for (const key in users) {
            const singleUser = users[key];

            if (tabValue === 0 || (tabValue !== 0 && !singleUser?.isFictive)) {
                usersResourcesData.push({
                    id: singleUser.id,
                    fullname: singleUser.fullName,
                    isFictive: singleUser.isFictive,
                    mainSubServiceName: getmainSubServiceName(singleUser),
                });
            }
        }

        for (const key in projects) {
            projectsData.push({
                id: projects[key].id,
                name: projects[key].name,
            });
        }

        return (
            <Dialog
                disableEnforceFocus
                scroll="body"
                open={open}
                onClose={this.handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                style={{ overflow: 'initial' }}
            >
                <DialogTitle
                    disableTypography
                    className={classes.dialogTitle}
                    id="scroll-dialog-title"
                >
                    <Tabs
                        value={tabValue}
                        onChange={(_e, value) => this.handleTypeFormChange(value)}
                    >
                        <Tab
                            value={0}
                            label="Add Affectation"
                        />
                        {canCUDDayOff() && !userSelect?.isFictive &&
                            <Tab
                                value={1}
                                label="Add Day-Off"
                            />
                        }
                    </Tabs>
                    <IconButton onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ width: '100%' }} dividers>
                    {tabValue === 0 ?
                        <form id='add-affectation-form' onSubmit={this.handleSave}>
                            <Grid
                                id="scroll-dialog-description"
                                container direction="column">
                                <Grid container direction="column" alignItems="flex-start">
                                    <FormLabel style={{ color: 'black' }}><strong>
                                        Ressources And Projects
                                    </strong></FormLabel>

                                    <Box style={{ borderRadius: '5px', padding: '10px' }}>
                                        <Grid container alignContent="space-between">
                                            <AccountTreeRoundedIcon style={{ marginRight: '7px', marginTop: '7px' }} />
                                            <AutoCompleteGlobal
                                                id="project-select-demo"
                                                label="Choose a project"
                                                // asynchronous open props
                                                openAuto={openAutoProject}
                                                handleOpenAuto={() => this.setState({ openAutoProject: true })}
                                                handleCloseAuto={() => this.setState({ openAutoProject: false })}
                                                loadingProjects={openAutoProject && this.props.loadingProject}
                                                handleKeyUp={(e) => {
                                                    e.persist();
                                                    this.getMatchedProjects(e);
                                                }}
                                                // other standard props
                                                onChange={(e, v) => this.handleAutoComplete(e, v, 'projectauto')}
                                                value={projectSelect}
                                                options={projectsData}
                                                getOptionSelected={(option1, option2) => parseInt(option1.id) === parseInt(option2.id)}
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        {option.name}
                                                    </React.Fragment>
                                                )}
                                            />

                                        </Grid>
                                        <br />
                                        <Grid container alignContent="space-between" >
                                            <PersonRoundedIcon style={{ marginRight: '7px', marginTop: '7px' }} />
                                            <AutoCompleteGlobal
                                                id="country-select-demo"
                                                label="Choose a resource"
                                                multiple={true}
                                                onChange={(e, v) => this.handleMultiSelect(e, v)}
                                                value={userSelect}
                                                options={usersResourcesData
                                                    .sort((a, b) => -b.mainSubServiceName.localeCompare(a.mainSubServiceName) ||
                                                        -b.isFictive - a.isFictive ||
                                                        -b.fullname.localeCompare(a.fullname)
                                                    )
                                                }
                                                groupBy={option => option.mainSubServiceName}
                                                getOptionSelected={(option1, option2) => parseInt(option1.id) === parseInt(option2.id)}
                                                getOptionLabel={(option) => option.fullname}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <Avatar
                                                            key={option.id}
                                                            style={{
                                                                backgroundColor: variables.lightBlue,
                                                                color: 'black',
                                                                marginRight: '4px',
                                                                width: '28px',
                                                                height: '28px',
                                                                fontSize: '80%'
                                                            }}
                                                        >
                                                            {option.isFictive ? <HelpOutlineIcon /> : toTitleCase(option.fullname)}
                                                        </Avatar>
                                                        {option.fullname}
                                                    </React.Fragment>
                                                )}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                                <br />

                                <Grid container direction="column" alignItems="baseline">
                                    <FormLabel style={{ color: 'black' }}>
                                        <strong>
                                            Duration
                                        </strong>
                                    </FormLabel>
                                    <Box style={{ borderRadius: '5px', padding: '10px' }}>
                                        <Grid container direction='row'>
                                            <Grid item >
                                                <div>
                                                    <FormControlLabel
                                                        name="daySlotOrHourly"
                                                        checked={parseInt(daySlotOrHourly) === 0}
                                                        onChange={e => this.onChangeStandard(e)}
                                                        value={0}
                                                        control={<Radio color="primary" />}
                                                        label="Duration (Day slot)"
                                                    />
                                                    <br />
                                                    <div style={parseInt(daySlotOrHourly) === 1 ? { pointerEvents: 'none' } : {}}>
                                                        <Toggle
                                                            id="dayslot"
                                                            value={daySlot}
                                                            className={classes.dayslot}
                                                            label=""
                                                            onChangeData={(event, value) => this.onChangeDaySlot(event, value)}
                                                        />
                                                    </div>
                                                </div>
                                                <br />

                                                <div>
                                                    <FormControlLabel
                                                        name="daySlotOrHourly"
                                                        checked={parseInt(daySlotOrHourly) === 1}
                                                        onChange={e => this.onChangeStandard(e)}
                                                        value={1}
                                                        control={<Radio color="primary" />}
                                                        label="Duration (hourly)"
                                                    />
                                                    <br />

                                                    <div style={parseInt(daySlotOrHourly) === 0 ? { pointerEvents: 'none', textAlign: "center" } : { textAlign: "center" }}>
                                                        <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} dateFormats="HH">
                                                            <TimePicker
                                                                label=""
                                                                views={['hours']}
                                                                inputFormat='HH:00'
                                                                clearable={false}
                                                                ampm={false}
                                                                minTime={new Date(0, 0, 0, 9, 0, 0)}
                                                                maxTime={new Date(0, 0, 0, 17, 0, 0)}
                                                                shouldDisableTime={timeValue => 13 === timeValue}
                                                                renderInput={(props) => <TextField {...props} className={classes.timePickerInput} />}
                                                                value={startTime}
                                                                onChange={dateTime => this.handleStartEndTimeOnChange(dateTime, 'start')}
                                                            />
                                                        </LocalizationProvider>
                                                        <ArrowForwardIcon style={{ width: "40px", marginBottom: "-10px" }} />
                                                        <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} dateFormats="HH">
                                                            <TimePicker
                                                                label=""
                                                                views={['hours']}
                                                                inputFormat='HH:00'
                                                                clearable={true}
                                                                ampm={false}
                                                                minTime={new Date(0, 0, 0, 10, 0, 0)}
                                                                maxTime={new Date(0, 0, 0, 18, 0, 0)}
                                                                shouldDisableTime={timeValue =>
                                                                    14 === timeValue ||
                                                                    (
                                                                        startTime?.getDate() === endTime?.getDate() &&
                                                                        timeValue <= startTime?.getHours()
                                                                    )
                                                                }
                                                                renderInput={(props) => <TextField {...props} className={classes.timePickerInput} />}
                                                                value={endTime}
                                                                onChange={dateTime => this.handleStartEndTimeOnChange(dateTime, 'end')}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <br />
                                        <FormLabel style={{ color: 'black' }}>
                                            <strong>
                                                Date
                                            </strong>
                                        </FormLabel>
                                        <br />
                                        <div style={{ marginTop: "20px", display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                            <DatePickerForm
                                                label="start date"
                                                name="start"
                                                disabled={false}
                                                defaultValue={date}
                                                onChangeData={(event) => this.onChangeDatePicker(event)}
                                            />
                                            <ArrowForwardIcon style={{ width: "40px", marginTop: "-20px" }} />
                                            <DatePickerForm
                                                label="end date"
                                                name="start"
                                                disabled={true}
                                                defaultValue={transformEndDateDisplay(date, daySlot)}
                                            />
                                        </div>
                                    </Box>
                                </Grid>
                                <br />

                                <Grid container direction="column" alignItems="baseline">
                                    <FormLabel style={{ color: 'black' }} ><strong>
                                        Priority And Notes
                                    </strong></FormLabel>

                                    <Box style={{ borderRadius: '5px', padding: '10px', width: '100%' }}>
                                        <Grid item>
                                            <br />
                                            <PriorityHighRoundedIcon style={{ marginRight: '7px', marginTop: '7px' }} />
                                            <RadioGroups
                                                name="priority"
                                                label="Priority"
                                                defaultValue={priority}
                                                dataArray={exigencyLevelTypes}
                                                required={false}
                                                // for options
                                                optValueKey="value"
                                                optLabelKey="label"
                                                onChangeData={(event) => this.onChangeStandard(event)}
                                            />
                                        </Grid>
                                        <Grid item style={{ display: 'flex', alignItems: 'flex-start' }} >
                                            <NotesRoundedIcon style={{ marginRight: '7px', marginTop: '2px' }} />
                                            <BigTextField
                                                name="notes"
                                                label="Notes"
                                                value={notes}
                                                disabled={false}
                                                onChangeData={(event) => this.onChangeStandard(event)}
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />

                            <Grid container direction="column" alignItems="baseline">
                                <FormLabel style={{ color: 'black' }}>
                                    <strong>
                                        Other options
                                    </strong>
                                </FormLabel>
                                <Box style={{ borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'baseline' }}>
                                    <span><RepeatRoundedIcon /> Frequency :</span>
                                    <RecurrenceSelect
                                        startDate={this.state.formData.date}
                                        setRrule={rrule => this.setState({ rrule })}
                                    />
                                </Box>
                            </Grid>
                        </form> :
                        <>
                            <Grid item>
                                <SelectBox
                                    name="dayoffType"
                                    label="Day Off type"
                                    defaultValue={dayoffType}
                                    dataArray={dayOffTypes}
                                    none={true}
                                    required={true}
                                    // for options
                                    optValueKey="value"
                                    optLabelKey="label"
                                    onChangeData={(event) => this.onChangeDayOffInputs('dayoffType', event)}
                                />
                            </Grid>
                            <br />

                            <Grid item>
                                <DateRange1
                                    onOpen={() => this.setState({ openRange: true })}
                                    open={openRange}
                                    value={selectedDateRange}
                                    onChangeRange={(rangeDates) => this.onChangeDayOffInputs('rangeDate', rangeDates)}
                                />

                                <div className={classes.divitem}>
                                    <div className={classes.divStartHalfDay}>
                                        <RadioGroups
                                            name="halfDayStart"
                                            label="Start Half/All Day"
                                            defaultValue={halfDayStart}
                                            dataArray={halfDayData}
                                            required={false}
                                            // for options
                                            optValueKey="value"
                                            optLabelKey="label"
                                            onChangeData={(e) => this.onChangeDayOffInputs('switchHalfDayToggle', e)}
                                        />
                                    </div>

                                    <div>
                                        <RadioGroups
                                            name="halfDayEnd"
                                            label="End Half/All Day"
                                            defaultValue={halfDayEnd}
                                            dataArray={halfDayData}
                                            required={false}
                                            // for options
                                            optValueKey="value"
                                            optLabelKey="label"
                                            onChangeData={(e) => this.onChangeDayOffInputs('switchHalfDayToggle', e)}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <br />

                            <Grid container direction="row">
                                <Grid item>
                                    <FormLabel component="legend">Resource / Office</FormLabel>
                                    <FormControlLabel
                                        name="userOrOffice"
                                        checked={parseInt(userOrOffice) === 0}
                                        onChange={(e) => this.onChangeDayOffInputs('standard', e)}
                                        value={0}
                                        control={<Radio color="primary" />}
                                        label="Resource"
                                    />
                                    <AutoCompleteGlobal
                                        onChange={(_e, v) => this.onChangeDayOffInputs('userdayoffauto', v)}
                                        value={userSelectDayoff}
                                        id="country-select-demo"
                                        options={usersResourcesData}
                                        getOptionSelected={(option1, option2) => parseInt(option1.id) === parseInt(option2.id)}
                                        getOptionLabel={(option) => option.fullname}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <Avatar
                                                    style={{
                                                        backgroundColor: variables.lightBlue,
                                                        color: 'black',
                                                        marginRight: '4px',
                                                        width: '28px',
                                                        height: '28px',
                                                        fontSize: '80%'
                                                    }}
                                                    key={option.id}
                                                >{toTitleCase(option.fullname)}
                                                </Avatar>
                                                {option.fullname}
                                            </React.Fragment>
                                        )}
                                        label="Choose a resource"
                                    />
                                    <br />

                                    <FormControlLabel
                                        name="userOrOffice"
                                        checked={parseInt(userOrOffice) === 1}
                                        onChange={(e) => this.onChangeDayOffInputs('standard', e)}
                                        value={1}
                                        control={<Radio color="primary" />}
                                        label="Office"
                                    />

                                    {
                                        offices.map((data) => {
                                            return (
                                                <Chip
                                                    disabled={parseInt(userOrOffice) === 0}
                                                    key={data.key}
                                                    color={data.checked === true ? 'primary' : 'default'}
                                                    icon={<DoneIcon />}
                                                    label={data.label}
                                                    onClick={() => this.onChangeDayOffInputs('offices', data)}
                                                />
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <br />

                            {
                                parseInt(userOrOffice) === 0 &&
                                dayoffType?.toString() !== dayoffDataTypes.HOLIDAY &&
                                <Grid container direction="column" alignItems="baseline">
                                    <FormLabel style={{ color: 'black' }}>
                                        <strong>
                                            Other options
                                        </strong>
                                    </FormLabel>
                                    <Box style={{ borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'baseline' }}>
                                        <span><RepeatRoundedIcon /> Frequency :</span>
                                        <RecurrenceSelect
                                            startDate={this.state.dayOffData.selectedDateRange[0]}
                                            setRrule={rrule => this.setState({ rrule })}
                                        />
                                    </Box>
                                </Grid>
                            }
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    {tabValue === 0 ?
                        <>
                            <Button
                                autoFocus
                                onClick={this.addNewAfffectationAndQuit}
                                type="submit"
                                color="primary"
                                variant="outlined"
                                style={{ cursor: !this.errorFlag() ? 'not-allowed' : 'pointer' }}
                                disabled={!this.errorFlag()}
                            >
                                <AddIcon />
                                Submit and Add Another Affectation
                            </Button>
                            <Button
                                form='add-affectation-form'
                                type="submit"
                                color="primary"
                                variant="contained"
                                style={!this.errorFlag() ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
                                disabled={!this.errorFlag()}
                            >
                                Submit Affectation
                            </Button>
                        </> :
                        <>
                            <Button
                                autoFocus
                                type="submit"
                                color="primary"
                                variant="outlined"
                                style={{ cursor: 'pointer' }}
                                disabled={this.disabeDayoff()}
                            >
                                <AddIcon />
                                Submit and Add Another Day off
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                style={{ cursor: 'pointer' }}
                                disabled={this.disabeDayoff()}
                                onClick={(e) => this.addNewDayoff(e)}
                            >
                                Submit Day Off
                            </Button>
                        </>
                    }
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        // app reducer
        exigencyLevelTypes: state.app.exigencyLevelTypes,
        dayOffTypes: state.app.dayOffTypes,
        // auth redcuer
        token: state.auth.token,
        // projects + users reducers
        users: state.user.users,
        loading: state.user.loading,
        // project reducer
        projects: state.project.projects,
        loadingProject: state.project.loading,
        // affectation reducer
        affectations: state.affecta.affectations,
        // dayoff reducer
        dayoffs: state.dayoff.dayoffs,
        dayoffLoading: state.dayoff.loading,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAddAffectation: (affectationBody, affectationsData, calendarApi) => dispatch(actions.addAffectation(affectationBody, affectationsData, calendarApi)),
        onAddDayoff: (dayoffBody, calendarApi) => dispatch(actions.addDayoff(dayoffBody, calendarApi)),
        // Add recurrent events actions
        onAddRecurrentAffectation: (payloadData, calendarApi, queryParams = {}) =>
            dispatch(actions.addRecurrentAffectation(payloadData, calendarApi, queryParams)),
        onAddRecurrentDayOff: (payloadData, calendarApi, queryParams = {}) =>
            dispatch(actions.addRecurrentDayOff(payloadData, calendarApi, queryParams)),
        // used for asynchronous projects fetch
        onFetchProjects: (queryParams) => dispatch(actions.fetchProjects(queryParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUIAdd)
