import { Line } from '@reactchartjs/react-chart.js'

const lineColors = [
  'rgba(255, 99, 132, 0.7)',
  'rgba(54, 162, 235, 0.7)',
  'rgba(255, 206, 86, 0.7)',
  'rgba(75, 192, 192, 0.7)',
  'rgba(153, 102, 255, 0.7)',
  'rgba(255, 159, 64, 0.7)',
  'rgba(0, 204, 102, 0.7)'
];

const LineChart = ({data}) => {
  const chartData = {
    labels: Object.keys(data).reverse(),
    datasets: Object.keys(data[Object.keys(data)[0]]).map((label, index) => ({
      label,
      data: Object.values(data).reverse().map((entry) => entry[label]),
      backgroundColor: lineColors[index],
      borderColor: lineColors[index],
      fill: false
    })),
  };

  const options = {
    title: {
      display: true,
      text: 'Traffic OverView',
    }
  };

  return <Line data={chartData} options={options} />
};

export default LineChart;