import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Link, Redirect } from 'react-router-dom';

import './ForgetPasswordEmail.scss';
import { updateObject, checkValidity } from '../../../shared/utility';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';

class ForgetPasswordEmail extends Component {
    state = {
        controls: {
            username: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false,
            }
        }
    }

    inputChangeHandler = (event) => {
        // capture event names and their correposnding values
        const eventValue = event.target.value;
        const inputName = event.target.name;

        const updatedElements = updateObject(this.state.controls, {
            [inputName]: updateObject(this.state.controls[inputName], {
                value: eventValue,
                valid: checkValidity(eventValue, this.state.controls[inputName].validation),
                touched: true
            })
        });

        // updatedElements to update state values
        this.setState({ controls: updatedElements });
    }

    submitHandler = () => {
        const { username } = this.state.controls;
        const resetPassEmailBody = {
            email: username.value
        }

        this.props.onResetPwdEmail(resetPassEmailBody);
    }

    disableSubmitHandler = () => {
        return !this.state.controls.username.valid || this.props.loading;
    }

    render() {
        return (
            <div>
                <Link to="/"  >
                    <Button color="primary" variant="contained" className="back" >
                        <ArrowBackRoundedIcon />
                        Back
                    </Button>
                </Link>

                {this.props.loading && <Redirect to='/' />}
                <br /> <br /><br /><br /><br /><br />
                <div className="card col-md-10 col-lg-6 m-auto">
                    <div className="card-body">
                        <h2>Reset your password</h2>

                        <div className="form-group">
                            <label className="required" htmlFor="email">e-mail address</label>
                            <input type="email" className="form-control" id="email" required="required" autoFocus=""
                                name="username"
                                onChange={(event) => this.inputChangeHandler(event)} />
                            <div style={{ fontSize: 12, color: "red" }}>
                                {!this.state.controls.username.valid && this.state.controls.username.touched ? "invalid Email" : null}
                            </div>
                        </div>
                        <div>
                            <center><Button
                                onClick={() => this.submitHandler()}
                                disabled={this.disableSubmitHandler()}
                                className="resetPassword"
                                color="primary"
                                variant="contained" >Reset your password</Button></center>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateTpProps = state => {
    return {
        loading: state.resetPwd.loading,
        error: state.resetPwd.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPwdEmail: (body) => dispatch(actions.resetPwdEmail(body))
    };
};

export default connect(mapStateTpProps, mapDispatchToProps)(ForgetPasswordEmail)
