import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    ToggleButton,
    ToggleButtonGroup,
    Skeleton,
    AvatarGroup
} from '@material-ui/lab';
import {
    ArrowForward as ArrowForwardIcon,
    ArrowBack as ArrowBackIcon,
    AddCircle as AddCircleIcon,
    DoneAllRounded as DoneAllRoundedIcon,
} from '@material-ui/icons';
import {
    Button,
    Avatar,
    Tooltip,
    LinearProgress,
    AppBar,
} from '@material-ui/core';
import { EventSourcePolyfill } from 'event-source-polyfill';

import FullCalendarResource from '../Calendar-Schedular/FullCalendarResource';
import ModalUIAdd from '../../components/UI/Modal/ModalUIAdd/ModalUIAdd';
import {
    toTitleCase,
    checkedOfficesData,
    transformDaySlot,
    transformEndDate,
    differenceTwoDates,
    dateInRange,
    parseStrToInt,
    dateRangesIntersect,
} from '../../shared/utility';
import { dayoffDataTypes, halfDayType } from '../../shared/dayOffUtility';
import ModalUIUser from '../../components/UI/Modal/ModalUsers/ModalUIUser';
import DateNavigator from '../../components/UI/DateNavigator/DateNavigator';
import ServiceNavigation from '../../components/Navigation/ServiceNavigation/ServiceNavigation';
import * as actions from '../../store/actions/index';
import {
    AFFECTATION_FILTERS,
    DAY_OFF_FILTERS,
    EXTERNAL_DAY_OFF_FILTERS,
    ORDER_FILTERS,
    PROJECT_FILTERS,
    SERVICE_FILTERS,
    USER_FILTERS
} from '../../services/utils/filters';
import {
    API_RESOURCES,
    BACK_BASE_URL
} from '../../services/utils/apiResources';
import {
    addEventToFullCalendar,
    EVENT_DAY_OFF_PREFIX,
    EVENT_EXTERNAL_DAY_OFF_PREFIX,
    getRenderingEventColor,
    updateEventInFullCalendar
} from '../../shared/fullCalendarUtility';
import PlanningProjectSelectFilter from '../../components/UI/Select/PlanningProjectFilter/PlanningProjectSelectFilter.jsx';
import SnackbarUtils from '../../shared/SnackbarUtils';
import { SnackbarCalendarEventContent } from '../../hoc/SnackBar/SnackbarProviderSetup';
import { ROLE } from '../../shared/authUtility';
import {
    canApproveNonDiscordantAffectations,
    canUDAffectation,
    hasOnlyResourceRole,
    isProjectManager
} from '../../shared/authorizationUtility';

import classes from './dashboard.module.scss';

const activeViewStyle = {
    color: classes.mainColor,
    backgroundColor: 'white',
    borderColor: classes.mainColor,
    height: '35px',
    width: '52%',
    zIndex: '1'
}

const noActiveViewStyle = {
    height: '30px',
    background: '#000000',
    opacity: '0.1',
    color: 'white'
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_MONTH_FORMAT = "YYYY-MM-DD";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
        let startDateWeek = moment().startOf('week').format(DATE_MONTH_FORMAT);
        let endDateWeek = moment().endOf('week').format(DATE_MONTH_FORMAT);
        let dateNav = moment().format(DATE_MONTH_FORMAT);
        if (isProjectManager()) {
            startDateWeek = moment().startOf('week').add(7, 'days').format(DATE_MONTH_FORMAT);
            endDateWeek = moment().endOf('week').add(9, 'days').format(DATE_MONTH_FORMAT);
            dateNav = moment().add(7, 'days').format(DATE_MONTH_FORMAT);
        }

        this.state = {
            value: parseStrToInt(props.loggedUserService),
            rangeWeek: null,
            startDateWeek: startDateWeek,
            endDateWeek: endDateWeek,
            openDatepicker: false,
            dateNav: dateNav,
            eventSource: null,
            singleShiftsActive: false,
            // needed for prev & next date navigators
            assignedUserID: '',
            mercureHubUrl: null,
            filteredProjects: [],
        }
    }

    addAffectInitialData = {
        project: null,
        user: 0,
        daySlot: 0.5,
        date: moment((new Date()).setHours(0, 0, 0, 0)).add(7, 'days').format(DATE_FORMAT),
        priority: 'Low',
        notes: '',
        startTime: moment().set('hours', 9).add(7, 'days').format(DATE_FORMAT),
        endTime: moment().set('hours', 10).add(7, 'days').format(DATE_FORMAT),
        daySlotOrHourly: 0
    }

    addDayOffInitialData = {
        dayoffType: null,
        selectedDateRange: [moment().format(DATE_FORMAT), moment().format(DATE_FORMAT)],
        halfDayStart: halfDayType.HALF_DAY,
        halfDayEnd: halfDayType.HALF_DAY,
        userdayoff: 0,
        offices: [...checkedOfficesData],
        actionType: 'standard'
    }

    componentDidMount() {
        const { startDateWeek, endDateWeek, value, assignedUserID } = this.state;

        // IF DBAL isn't in redux store fetch it
        if (
            this.props.exigencyLevelTypes.length === 0 ||
            this.props.dayOffTypes.length === 0
        ) {
            this.props.onFetchAppDBALData();
        }

        this.props.onFetchServices({
            [SERVICE_FILTERS.ENABLED]: true,
            [SERVICE_FILTERS.POSITION_ORDER]: ORDER_FILTERS.ASC,
            [SERVICE_FILTERS.PAGINATION]: false
        });
        this.props.onFetchUsers({
            [USER_FILTERS.ROLES]: 'resource',
            [USER_FILTERS.PAGINATION]: false,
            ...(parseInt(value) === 0 ?
                { [USER_FILTERS.MAIN_SERVICE_EXIST]: true } :
                { [USER_FILTERS.MAIN_SERVICE]: value }
            ),
        });

        if (hasOnlyResourceRole()) {
            this.props.onFetchAffectations({
                [AFFECTATION_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                [AFFECTATION_FILTERS.IS_CONFIRMED]: true,
                [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: value !== 0 ? value : '',
                [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: assignedUserID,
                [AFFECTATION_FILTERS.PAGINATION]: false,
            });
        } else {
            // initialise projects depending on project manager
            this.props.onFetchProjects({
                [PROJECT_FILTERS.PROJECT_MANAGER_FULL_NAME]: this.props.loggedUserFullName
            });
            this.props.onFetchAffectations({
                [AFFECTATION_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: value !== 0 ? value : '',
                [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: assignedUserID,
                [AFFECTATION_FILTERS.PAGINATION]: false,
            });
        }
        this.props.onFetchDayoffs(
            {
                [DAY_OFF_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                [DAY_OFF_FILTERS.PAGINATION]: false,
            },
            {
                [EXTERNAL_DAY_OFF_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                [EXTERNAL_DAY_OFF_FILTERS.PAGINATION]: false,
            }
        );
        this.calendarApi = this.calendarRef.current.getApi();
    }

    componentDidUpdate(prevProps, prevState) {
        const { rangeWeek, value, mercureHubUrl } = this.state;
        const { hubURL, hubToken } = prevProps;

        this.range = this.calendarApi.view.title;
        // needed in did update as first render for week range
        if (this.range !== rangeWeek) {
            this.setState({ rangeWeek: this.calendarApi.view.title });
        }

        // To prevent display duplicated events on navigation and service change.
        if (prevState.value !== value || prevState.rangeWeek !== rangeWeek) {
            this.calendarApi.removeAllEvents();
        }

        if (hubURL && mercureHubUrl !== hubURL) {
            this.setState({ mercureHubUrl: hubURL });

            // Init event source + topic {Add, Edit, Delete}
            const url = new URL(hubURL);
            url.searchParams.append('topic', `${BACK_BASE_URL}${API_RESOURCES.AFFECTATIONS}/{id}`);

            this.eventSource = new EventSourcePolyfill(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${hubToken}`,
                    }
                }
            );
            this.eventSource.onmessage = (event, _ev) => {
                if (!this.props.actionCreatorMercure) {
                    const data = JSON.parse(event.data);
                    if (data.id) {
                        this.addOrEditEventMercure(data);
                    } else {
                        const string = data['@id'];
                        const numberPattern = /\d+/g;
                        const number = string.match(numberPattern).join('');
                        this.removeEventMercure(number);
                    }
                }
            }
            this.eventSource.onerror = () => console.warn('Real time bad sync');
        }
    }

    /**
     * `onResetResources`:  Used to force full-calendar events(`affectation` and `dayoffs` from `redux` state) re-render.
     *      e.g.: when navigating from `Dashboard` view to `users` view and go back to `dashboard`, redux states
     *      that contain events props might be the same so react will not force their update.
     *
     * `eventSource`: We have to close eventSource connection to avoid unnecessary event source requests on other views !== of `dashboard` 
     */
    componentWillUnmount() {
        this.props.onResetResources();
        this.state.mercureHubUrl && this.eventSource?.close();
    }

    /**
     * On the creation of a recurrent affectation, the back-end server create multi events that are grouped by the defined recurrence rule,
     * so it sends multi-updates after the persist operations (POST::WRITE),
     * since the front is subscribed to Mercure-HUB we add to calendar only the ones that intersect with the active week range.
     */
    addOrEditEventMercure(data) {
        const start = data.startDateTime.split('+')[0];
        const end = data.endDateTime.split('+')[0];
        if (dateRangesIntersect(start, end, this.state.startDateWeek, this.state.endDateWeek)) {
            const event = this.calendarApi.getEventById(data.id);
            if (event === null) {
                // NO Real-time synchro when the logged-in user hase a `ROLE_RESOURCE` and the event is a request (non validated affectation).
                if (isProjectManager() ||
                    (
                        hasOnlyResourceRole() &&
                        data.isConfirmed === true
                    )
                ) {
                    addEventToFullCalendar(data, this.calendarApi);
                    SnackbarUtils.toast(
                        '⚡ Event saved',
                        {
                            autoHideDuration: 6000,
                            content: (key, message) =>
                                <SnackbarCalendarEventContent
                                    key={key}
                                    message={message}
                                    event={data}
                                />
                        }
                    );
                }
            } else {
                updateEventInFullCalendar(event, {
                    start,
                    end,
                    resourceId: data.assignedUser.id,
                    exigencyLevel: data.exigencyLevel,
                    notes: data.notes,
                    recurrenceRule: data.recurrenceRule,
                    isConfirmed: data.isConfirmed
                });
                const isApproveAction = Boolean(event._def.extendedProps.isConfirmed) !== data.isConfirmed;
                SnackbarUtils.toast(`⚡ Event ${isApproveAction ? 'approv' : 'updat'}ed`)
            }
        }
    }

    removeEventMercure(id) {
        this.calendarApi.getEventById(id)?.remove();
        SnackbarUtils.toast('⚡ Event removed');
    }

    onClick(handleClickOpen) {
        this.handleClickOpen = handleClickOpen;
    }

    onclickModalUser(handleClickUserPop) {
        this.handleClickUserPop = handleClickUserPop;
    }

    handleChange = (_event, newValue) => {
        const { startDateWeek, endDateWeek, assignedUserID } = this.state;
        // when newValue is null we assign it is meant that we want to refresh the same service
        newValue = newValue ?? this.state.value;
        this.setState({ value: newValue });

        this.props.onFetchUsers({
            [USER_FILTERS.ROLES]: 'resource',
            [USER_FILTERS.PAGINATION]: false,
            ...(newValue !== '' && parseInt(newValue) === 0 ?
                { [USER_FILTERS.MAIN_SERVICE_EXIST]: true } :
                { [USER_FILTERS.MAIN_SERVICE]: newValue }
            ),
        });
        this.props.onFetchAffectations({
            [AFFECTATION_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
            [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: newValue !== 0 ? newValue : '',
            [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: assignedUserID,
            [AFFECTATION_FILTERS.PAGINATION]: false,
        });
    };

    handlePrevNext = (e, navigationType) => {
        // disable get from back when a date is inside the current week range
        if (navigationType === 'newWeek') {
            const start = moment(this.calendarApi.view.activeStart).format(DATE_MONTH_FORMAT);
            const end = moment(this.calendarApi.view.activeEnd).format(DATE_MONTH_FORMAT);
            if (moment(e).isAfter(start) && moment(e).isBefore(end)) {
                return;
            }
        }

        switch (navigationType) {
            case 'prev':
                this.calendarApi.prev();
                break;
            case 'next':
                this.calendarApi.next();
                break;
            case 'newWeek':
                if (moment(e).isValid()) {
                    this.calendarApi.gotoDate(moment(e).format(DATE_MONTH_FORMAT));
                }
                break;
            default:
        }

        const moment1 = moment(this.calendarApi.view.activeStart).format(DATE_MONTH_FORMAT);
        const moment2 = moment(this.calendarApi.view.activeEnd).add(2, 'days').format(DATE_MONTH_FORMAT);
        const { assignedUserID, value } = this.state;

        this.setState({
            rangeWeek: this.calendarApi.view.title,
            dateNav: moment1,
            startDateWeek: moment1,
            endDateWeek: moment2,
        });

        this.props.onFetchAffectations({
            [AFFECTATION_FILTERS.START_END_RANGE]: `${moment1}..${moment2}`,
            [AFFECTATION_FILTERS.IS_CONFIRMED]: hasOnlyResourceRole() ? true : '',
            [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: value,
            [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: assignedUserID,
            [AFFECTATION_FILTERS.PAGINATION]: false,
        });
        this.props.onFetchDayoffs(
            {
                [DAY_OFF_FILTERS.START_END_RANGE]: `${moment1}..${moment2}`,
                [DAY_OFF_FILTERS.PAGINATION]: false,
            },
            {
                [EXTERNAL_DAY_OFF_FILTERS.START_END_RANGE]: `${moment1}..${moment2}`,
                [EXTERNAL_DAY_OFF_FILTERS.PAGINATION]: false,
            }
        );
    }

    handleValidateRequests = () => {
        const { startDateWeek, endDateWeek } = this.state;
        const resources = [...this.calendarApi.getResources()]
        const events = [...this.calendarApi.getEvents()];
        const pureAffectations = events.filter(x => x.rendering !== 'background');
        const requests = events.filter(x => x.rendering !== 'background' && !!x._def.extendedProps.isConfirmed === false);
        // through requests list get the non overlapped
        const requests1 = [];
        requests.forEach(x => {
            if (dateInRange(x.start, startDateWeek, endDateWeek) &&
                dateInRange(x.end, startDateWeek, endDateWeek)) {
                requests1.push({ id: x.id, start: x.start, end: x.end, resourceId: x._def.resourceIds[0] });
            }
        });

        const mappedRequests = [];
        requests1.forEach(x => {

            const loopNumber = transformDaySlot(x.start, x.end) / 0.5;
            // 2nd alternative
            x.end = transformEndDate(x.start, 0.5);
            let index = 0;
            for (; index < loopNumber; index++) {
                const items = pureAffectations.filter(ele => parseInt(ele._def.resourceIds[0]) === parseInt(x.resourceId)
                    && differenceTwoDates(ele.start, x.start) <= 0
                    && differenceTwoDates(ele.end, x.end) >= 0);
                if (items.length > 1) {
                    break;
                }
                x.start = x.end;
                x.end = transformEndDate(x.start, 0.5);

            }
            if (index === loopNumber) {
                mappedRequests.push(x);
            }
        });

        const requestsResouFilter = mappedRequests.filter(x1 => -1 !== resources.findIndex(x2 => parseInt(x2.id) === parseInt(x1.resourceId)))
        const requestIdsList = requestsResouFilter.map(x => parseInt(x.id));
        if (requestIdsList.length > 0) {
            const requestsListBody = {
                requests: requestIdsList
            }
            const calenApi = this.calendarRef.current.getApi();
            this.props.onApproveListAffectation(requestsListBody, calenApi);
        } else {
            SnackbarUtils.toast('Validate requests individually');
        }
    }

    // Toggle show single events of connected user
    loadSingleShifts = (_e, valBool) => {
        const { startDateWeek, endDateWeek, value } = this.state;
        const userId = this.props.loggedUserId;
        this.setState({
            singleShiftsActive: valBool,
            assignedUserID: valBool ? userId : '',
        });

        // if single shifts is activated: No requests are loaded
        if (valBool) {
            this.props.onGetUser(userId);
            this.props.onFetchAffectations({
                [AFFECTATION_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                [AFFECTATION_FILTERS.IS_CONFIRMED]: true,
                [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: value,
                [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: userId,
                [AFFECTATION_FILTERS.PAGINATION]: false,
            });
        } else {
            // When single shifts is not enabled retrieve the standard view depending on the connected user role
            this.props.onFetchUsers({
                [USER_FILTERS.ROLES]: 'resource',
                [USER_FILTERS.PAGINATION]: false,
                ...(parseInt(value) === 0 ?
                    { [USER_FILTERS.MAIN_SERVICE_EXIST]: true } :
                    { [USER_FILTERS.MAIN_SERVICE]: value }
                ),
            });
            this.props.onFetchAffectations({
                [AFFECTATION_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                [AFFECTATION_FILTERS.IS_CONFIRMED]: hasOnlyResourceRole() ? true : '',
                [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: value,
                [AFFECTATION_FILTERS.PAGINATION]: false,
            })
        }
    }

    generateSubServiceName = (resourceMainService, resourceSubService) => {
        const mainPosition = resourceMainService ? resourceMainService.position : '1';
        if (resourceMainService && resourceSubService) {
            return `${mainPosition}${resourceSubService.position}${resourceSubService.name}`;
        }

        return resourceMainService ? `${mainPosition}${resourceMainService.name}` : `${mainPosition}No Service`;
    }

    setFilteredProjects = (filteredProjects) => {
        this.setState({ filteredProjects })
    }

    render() {
        const { rangeWeek, value, dateNav, openDatepicker, singleShiftsActive, startDateWeek, endDateWeek, assignedUserID, filteredProjects } = this.state;
        const { loadingAffect, users, affectations, dayoffs, openDrawerState, dayoffLoading, loading, services, loadingService } = this.props;
        // roles restrictions for add ,click cell
        const rolesLimits = isProjectManager();
        const disableAddAffButton = singleShiftsActive ||
            (moment(dateNav).diff(moment().startOf('week'), 'days') < 0 || loadingAffect);
        // if 'singleShiftsActive' is enabled && connected user is not a resource ---> No shifts
        const checkRoles = singleShiftsActive && !loadingAffect &&
            users.length === 1 && !users[0].roles.includes(ROLE.RESOURCE);
        const viewExtraSpace = (<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>);

        // build  full-calendar resources + events data
        const resourcesData = [];
        const eventsData = [];
        const officesResourcesIds = [[], [], [], [], []];
        const projectsToFilter = [];

        if (users.length !== 0) {
            if (!singleShiftsActive || (singleShiftsActive && users[0].roles.includes(ROLE.RESOURCE))) {
                for (const key in users) {
                    const singleResource = users[key];
                    const resourceMainService = singleResource.mainService;
                    const resourceSubService = singleResource.subService;

                    resourcesData.push({
                        id: singleResource.id,
                        title: singleResource.fullName,
                        office: singleResource.location,
                        mainService: resourceMainService ? resourceMainService.id : 0,
                        subService: resourceSubService ? resourceSubService.id : 0,
                        subServiceName: this.generateSubServiceName(resourceMainService, resourceSubService),
                        orderPosition: resourceSubService ? resourceSubService.position : '1',
                        isFictive: singleResource.isFictive,
                        email: singleResource.email
                    });

                    // to detect national public dayoffs
                    if (['Bordeaux', 'Londres', 'Paris', 'Tunis', 'Autre/Exter'].includes(singleResource.location)) {
                        switch (singleResource.location) {
                            case 'Bordeaux': officesResourcesIds[0].push(singleResource.id);
                                break;
                            case 'Londres': officesResourcesIds[1].push(singleResource.id);
                                break;
                            case 'Paris': officesResourcesIds[2].push(singleResource.id);
                                break;
                            case 'Tunis': officesResourcesIds[3].push(singleResource.id);
                                break;
                            case 'Autre/Exter': officesResourcesIds[4].push(singleResource.id);
                                break;
                            default:
                        }
                    }
                }
            }
        }

        if (affectations.length !== 0) {
            for (const key in affectations) {
                const singleAffect = affectations[key];
                const assignedProject = singleAffect.assignedProject;

                // get rid of null property with project manager on a project
                const projectManager = assignedProject?.projectManager || {
                    id: null,
                    fullName: ''
                }

                let isEventEditable = canUDAffectation(singleAffect.createdBy.id, singleAffect.isConfirmed);
                if (moment(singleAffect.endDateTime.split('+')[0]).diff(moment(), 'days') < 0) {
                    isEventEditable = false;
                }

                eventsData.push({
                    id: singleAffect.id,
                    title: assignedProject.name,
                    start: singleAffect.startDateTime.split('+')[0],
                    end: singleAffect.endDateTime.split('+')[0],
                    resourceId: singleAffect.assignedUser.id,
                    projectId: assignedProject.id,
                    projectName: assignedProject.name,
                    isBidProject: assignedProject.isBid,
                    exigencyLevel: singleAffect.exigencyLevel,
                    isConfirmed: singleAffect.isConfirmed,
                    notes: singleAffect.notes,
                    // Affectation maintainers
                    projectManagerId: projectManager.id,
                    projectManagerName: projectManager.fullName,
                    createdById: singleAffect.createdBy.id,
                    createdByName: singleAffect.createdBy.fullName,
                    // Interaction limits
                    editable: isEventEditable,
                    startEditable: isEventEditable,
                    resourceEditable: isEventEditable,
                    durationEditable: isEventEditable,
                    // do not name the key `rrule` because it a reserved keyword
                    ...(singleAffect.recurrenceRule && {
                        recurrenceRule: singleAffect.recurrenceRule
                    }),
                    // Style
                    color: getRenderingEventColor(singleAffect.isConfirmed, assignedProject.isBid),
                    textColor: 'white',
                    // icon: 'asterisk'
                    // classNames: ['myEvents'],
                });

                if (!projectsToFilter.includes(assignedProject.name)) {
                    projectsToFilter.push(assignedProject.name)
                }
            }
        }

        // concat dayoffs' events with affectations events
        // Day-off sources are two: internal(source is planning-tool) external(coreavostra-payfit)
        if (dayoffs.length > 0) {
            for (const key in dayoffs) {
                const singleDayOff = dayoffs[key];
                const dayOffCalendarId = `${singleDayOff.isExternal ? EVENT_EXTERNAL_DAY_OFF_PREFIX : EVENT_DAY_OFF_PREFIX}${singleDayOff.id}`;

                if (singleDayOff.isExternal) {
                    eventsData.push({
                        id: dayOffCalendarId,
                        resourceId: singleDayOff.user.id,
                        title: 'External Pay-fit',
                        start: singleDayOff.startDateTime.split('+')[0],
                        end: singleDayOff.endDateTime.split('+')[0],
                        office: null,
                        isExternal: true,
                        overlap: false,
                        editable: false,
                        clickable: false,
                        rendering: 'background',
                        classNames: ["stripes"]
                    });
                } else if (singleDayOff.userDayOff) {
                    eventsData.push({
                        id: dayOffCalendarId,
                        resourceId: singleDayOff.userDayOff.id,
                        title: singleDayOff.typeDayOff,
                        start: singleDayOff.startDateTime.split('+')[0],
                        end: singleDayOff.endDateTime.split('+')[0],
                        office: singleDayOff.office,
                        typeDayOff: singleDayOff.typeDayOff,
                        // Interaction limits
                        overlap: false,
                        editable: false,
                        clickable: false,
                        // TO not confuse with the defined keywrod RRule and it's not valid for offices' holidays
                        ...(singleDayOff.recurrenceRule && {
                            recurrenceRule: singleDayOff.recurrenceRule
                        }),
                        // Styles
                        rendering: 'background',
                        classNames: ["stripes"]
                    });
                } else if (singleDayOff.office?.length > 0) {
                    singleDayOff.office.forEach(x => {
                        const pos = checkedOfficesData.findIndex(offi => offi.label === x);
                        if (officesResourcesIds[pos].length > 0 && pos !== -1) {
                            eventsData.push({
                                id: dayOffCalendarId,
                                resourceIds: officesResourcesIds[pos],
                                title: singleDayOff.typeDayOff,
                                start: singleDayOff.startDateTime.split('+')[0],
                                end: singleDayOff.endDateTime.split('+')[0],
                                office: singleDayOff.office,
                                typeDayOff: dayoffDataTypes.HOLIDAY, // Already a holiday
                                // Interaction limits
                                overlap: false,
                                editable: false,
                                clickable: false,
                                isOfficeHoliday: true,
                                // Styles
                                rendering: 'background',
                                classNames: singleDayOff.typeDayOff === 'Holiday' ? ["stripes holiday"] : ["stripes"]
                            });
                        }
                    })
                }
            }
        }

        return (
            <div>
                <div>
                    <AppBar className={classes.appbar} style={{ backgroundColor: 'white' }}>
                        <div
                            style={{ alignItems: 'center', display: 'flex', marginLeft: openDrawerState ? '290px' : '120px', marginTop: '13px', marginBottom: '13px' }}
                            className={classes.inner}
                        >
                            {rolesLimits &&
                                <div className={classes.outsideactions}>
                                    <ModalUIAdd
                                        currentTeam={value}
                                        calendarRef={this.calendarRef}
                                        formData1={this.addAffectInitialData}
                                        dayOffData1={this.addDayOffInitialData}
                                        onRef={this.onClick.bind(this)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={disableAddAffButton}
                                        onClick={() => this.handleClickOpen()}
                                    >
                                        <AddCircleIcon /> Add Affectation
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginLeft: '10px' }}
                                        hidden={!canApproveNonDiscordantAffectations()}
                                        disabled={disableAddAffButton}
                                        onClick={() => this.handleValidateRequests()}
                                    >
                                        <DoneAllRoundedIcon /> Approve non discordant Requests
                                    </Button>
                                </div>
                            }
                            <ToggleButtonGroup
                                exclusive
                                size="medium"
                                value={singleShiftsActive}
                                onChange={(e, val) => this.loadSingleShifts(e, val)}
                                style={{ marginLeft: 'auto', alignItems: 'center' }}
                                className={classes.toggleButton}
                            >
                                <ToggleButton
                                    disabled={loadingAffect}
                                    value={true}
                                    style={{ borderRadius: '25px', ...(singleShiftsActive ? activeViewStyle : noActiveViewStyle) }}
                                > Perso View {!singleShiftsActive && viewExtraSpace}
                                </ToggleButton>
                                <ToggleButton
                                    disabled={loadingAffect}
                                    value={false}
                                    style={{ borderRadius: '25px', marginLeft: '-13%', ...(!singleShiftsActive ? activeViewStyle : noActiveViewStyle) }}
                                >
                                    {singleShiftsActive && viewExtraSpace} Group View
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        {dayoffLoading ? <LinearProgress style={{ marginTop: 'auto' }} /> : null}
                    </AppBar>
                </div>
                <br /> <br /><br /> <br />
                <div>
                    <div className={classes.maintabsavatar}>
                        <div className={classes.avatarsDiv}>
                            {!loading &&
                                !singleShiftsActive &&
                                <div style={{ display: 'flex', wordSpacing: '10px' }}>
                                    <h6 className={classes.membersnumber} >
                                        {users.length} <br /> Members
                                    </h6>
                                    <AvatarGroup
                                        spacing={-10}
                                        max={6}
                                        onClick={(e) => this.handleClickUserPop(e)}
                                    >
                                        {users.map(obj =>
                                            <Tooltip key={obj.id} title={obj.fullName}>
                                                <Avatar
                                                    key={obj.id}
                                                    className={classes.avatarStyle}
                                                >
                                                    {toTitleCase(obj.fullName)}
                                                </Avatar>
                                            </Tooltip>
                                        )}
                                    </AvatarGroup>
                                    <ModalUIUser service={value} usersData={users} onRefUser={this.onclickModalUser.bind(this)} />
                                </div>
                            }
                            {loading && !singleShiftsActive && <div style={{ display: 'flex' }}>
                                <div style={{ width: '20%' }}>
                                    <Skeleton width="30%" />
                                    <Skeleton />
                                </div>
                                {
                                    [...Array(6)].map((_e, i) => {
                                        return <Skeleton style={{ marginLeft: '7px' }} key={i} variant="circle" width={49} height={40} />;
                                    })
                                }
                            </div>}
                            {singleShiftsActive && <h3>Single Shifts</h3>}
                        </div>
                        {!singleShiftsActive &&
                            <ServiceNavigation
                                value={value}
                                services={services}
                                onChange={this.handleChange}
                                loadingService={loadingService}
                            />
                        }
                        {rangeWeek ?
                            <div className={classes.navigationDiv}>
                                <ToggleButtonGroup
                                    exclusive
                                    size="small"
                                    hidden={checkRoles}
                                >
                                    <Tooltip key="prev" title="Previous Week">
                                        <ToggleButton
                                            key="prev"
                                            value="prev"
                                            className={classes.navigationButton}
                                            onClick={(e) => this.handlePrevNext(e, 'prev')}
                                        >
                                            <ArrowBackIcon className={classes.navigationIcon} />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip key="slecteDate" title="Go By Date">
                                        <ToggleButton
                                            key="range"
                                            value="range"
                                            className={classes.dateRange}
                                            selected={openDatepicker}
                                            onClick={() => this.setState({ openDatepicker: true })}
                                        >
                                            {rangeWeek}
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip key="next" title="Next Week">
                                        <ToggleButton
                                            key="next"
                                            value="next"
                                            className={classes.navigationButton}
                                            onClick={(e) => this.handlePrevNext(e, 'next')}
                                        >
                                            <ArrowForwardIcon className={classes.navigationIcon} />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                                <DateNavigator
                                    open={openDatepicker}
                                    onClose={() => this.setState({ openDatepicker: false })}
                                    value={dateNav}
                                    handleDateChange={(e) => {
                                        this.setState({ dateNav: moment(e) });
                                        this.handlePrevNext(e, 'newWeek');
                                    }}
                                />
                            </div> : <Skeleton
                                variant="rect"
                                width={600}
                                height={30}
                                style={{ backgroundColor: "blue" }}
                            />
                        }
                    </div>
                    <PlanningProjectSelectFilter
                        loading={loadingAffect}
                        projects={projectsToFilter}
                        selected={filteredProjects}
                        setSelected={this.setFilteredProjects}
                    />

                    <div className={classes.calendarDiv}>
                        <FullCalendarResource
                            currentTeam={value}
                            calendarRef={this.calendarRef}
                            dateNav={dateNav}
                            rolesLimits={rolesLimits}
                            singleShiftsActive={singleShiftsActive}
                            resources={resourcesData}
                            events={eventsData}
                            filteredProjects={filteredProjects}
                            affectQueryParams={{
                                [AFFECTATION_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                                [AFFECTATION_FILTERS.ASSIGNED_USER_MAIN_SERVICE_ID]: value !== 0 ? value : '',
                                [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: assignedUserID,
                                [AFFECTATION_FILTERS.PAGINATION]: false,
                            }}
                            dayOffQueryParams={{
                                [DAY_OFF_FILTERS.START_END_RANGE]: `${startDateWeek}..${endDateWeek}`,
                                [DAY_OFF_FILTERS.PAGINATION]: false,
                            }}
                        />
                        {checkRoles && <h3>No Shifts for you</h3>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // app
        loggedUserId: state.app.user.userId,
        loggedUserFullName: state.app.user.fullName,
        loggedUserService: state.app.user.service,
        exigencyLevelTypes: state.app.exigencyLevelTypes,
        dayOffTypes: state.app.dayOffTypes,
        // user
        users: state.user.users,
        loading: state.user.loading,
        totaC: state.user.totalcount,
        // project
        projects: state.project.projects,
        loadingProject: state.project.loading,
        // affecta
        loadingAffect: state.affecta.loading,
        affectations: state.affecta.affectations,
        actionCreatorMercure: state.affecta.actionCreatorMercure,
        // hub affecta URL
        hubURL: state.affecta.mercureHubUrl,
        hubToken: state.affecta.mercureToken,
        // dayoff
        dayoffLoading: state.dayoff.loading,
        dayoffs: state.dayoff.dayoffs,
        // auth
        token: state.auth.token,
        // ui-utils
        openDrawerState: state.uiUtils.sideDrawerOpen,
        // service
        services: state.service.services,
        loadingService: state.service.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // app DBAL
        onFetchAppDBALData: () => dispatch(actions.fetchAppDBAL()),
        onResetResources: () => {
            dispatch(actions.emptyAffectationsList());
            dispatch(actions.emptyDayOffsList())
        },
        // project
        onFetchProjects: (queryParams) => dispatch(actions.fetchProjects(queryParams)),
        // user
        onFetchUsers: (queryParams) => dispatch(actions.fetchUsers(queryParams)),
        onGetUser: (userId) => dispatch(actions.getUser(userId, true)),
        // affectation
        onFetchAffectations: (queryParams) => dispatch(actions.fetchAffectations(queryParams)),
        onApproveListAffectation: (requestsListBody, calendarApi) =>
            dispatch(actions.approveListAffectation(requestsListBody, calendarApi)),
        // dayoff
        onFetchDayoffs: (queryParams, externalQueryParams) => dispatch(actions.fecthDayoffs(queryParams, externalQueryParams)),
        // service
        onFetchServices: (queryParams) => dispatch(actions.fetchServices(queryParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
