import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    services: [],
    subServices: [],
    affectationsPerWeekAndServices: [],
    workingDaysPerWeekAndServices: [],
    loading: false,
    affectationsLoading: false,
    workingDaysLoading: false,
}

///// default success trigger
const serviceDefaultSuccessTrigger = (defaultState, defaultAction) => {
    return updateObject(defaultState, {
        services: defaultAction.services,
        loading: false,
    });
}

//////////////////////////////  fecth services
const fetchServicesStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const fetchServicesSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const fecthServicesFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  fecth sub-services
const fetchSubServicesStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const fetchSubServicesSuccess = (state, action) => {
    return updateObject(state, {
        subServices: action.subServices,
        loading: false,
    });
}

const fecthSubServicesFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  fetch affectationsPerWeekAndServices
const fetchAffectationsPerWeekAndServicesStart = (state, _action) => {
    return updateObject(state, { affectationsLoading: true });
}

const fetchAffectationsPerWeekAndServicesSuccess = (state, action) => {
    return updateObject(state, {
        affectationsPerWeekAndServices: action.affectationsPerWeekAndServices,
        affectationsLoading: false,
    });
}

const fetchAffectationsPerWeekAndServicesFail = (state, _action) => {
    return updateObject(state, { affectationsLoading: false });
}

//////////////////////////////  fetch workingDaysPerWeekAndServices
const fetchWorkingDaysPerWeekAndServicesStart = (state, _action) => {
    return updateObject(state, { workingDaysLoading: true });
}

const fetchWorkingDaysPerWeekAndServicesSuccess = (state, action) => {
    return updateObject(state, {
        workingDaysPerWeekAndServices: action.workingDaysPerWeekAndServices,
        workingDaysLoading: false,
    });
}

const fetchWorkingDaysPerWeekAndServicesFail = (state, _action) => {
    return updateObject(state, { workingDaysLoading: false });
}

//////////////////////////////  add service
const addServiceStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const addServiceSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const addServiceFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  add sub-service
const addSubServiceStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const addSubServiceSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const addSubServiceFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  edit service
const editServiceStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const editServiceSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const editServiceFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  edit sub-service
const editSubServiceStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const editSubServiceSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const editSubServiceFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  delete service
const deleteServiceStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const deleteServiceSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const deleteServiceFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

//////////////////////////////  delete sub-service
const deleteSubServiceStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const deleteSubServiceSuccess = (state, action) => {
    return serviceDefaultSuccessTrigger(state, action);
}

const deleteSubServiceFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

const serviceReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // fetch services
        case actionTypes.FETCH_SERVICES_START: return fetchServicesStart(state, action);
        case actionTypes.FETCH_SERVICES_SUCCESS: return fetchServicesSuccess(state, action);
        case actionTypes.FETCH_SERVICES_FAIL: return fecthServicesFail(state, action);
        // fetch sub-ervices
        case actionTypes.FETCH_SUB_SERVICES_START: return fetchSubServicesStart(state, action);
        case actionTypes.FETCH_SUB_SERVICES_SUCCESS: return fetchSubServicesSuccess(state, action);
        case actionTypes.FETCH_SUB_SERVICES_FAIL: return fecthSubServicesFail(state, action);
        // fetch affectationsPerWeekAndServices
        case actionTypes.FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_START: 
            return fetchAffectationsPerWeekAndServicesStart(state, action);
        case actionTypes.FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_SUCCESS:
            return fetchAffectationsPerWeekAndServicesSuccess(state, action);
        case actionTypes.FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_FAIL:
            return fetchAffectationsPerWeekAndServicesFail(state, action);
        // fetch workingDaysPerWeekAndServices
        case actionTypes.FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_START:
            return fetchWorkingDaysPerWeekAndServicesStart(state, action);
        case actionTypes.FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_SUCCESS:
            return fetchWorkingDaysPerWeekAndServicesSuccess(state, action);
        case actionTypes.FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_FAIL:
            return fetchWorkingDaysPerWeekAndServicesFail(state, action);
        // add service
        case actionTypes.ADD_SERVICE_START: return addServiceStart(state, action);
        case actionTypes.ADD_SERVICE_SUCCESS: return addServiceSuccess(state, action);
        case actionTypes.ADD_SERVICE_FAIL: return addServiceFail(state, action);
        // add sub-service
        case actionTypes.ADD_SUB_SERVICE_START: return addSubServiceStart(state, action);
        case actionTypes.ADD_SUB_SERVICE_SUCCESS: return addSubServiceSuccess(state, action);
        case actionTypes.ADD_SUB_SERVICE_FAIL: return addSubServiceFail(state, action);
        // edit service
        case actionTypes.EDIT_SERVICE_START: return editServiceStart(state, action);
        case actionTypes.EDIT_SERVICE_SUCCESS: return editServiceSuccess(state, action);
        case actionTypes.EDIT_SERVICE_FAIL: return editServiceFail(state, action);
        // edit sub-service
        case actionTypes.EDIT_SUB_SERVICE_START: return editSubServiceStart(state, action);
        case actionTypes.EDIT_SUB_SERVICE_SUCCESS: return editSubServiceSuccess(state, action);
        case actionTypes.EDIT_SUB_SERVICE_FAIL: return editSubServiceFail(state, action);
        // delete service
        case actionTypes.DELETE_SERVICE_START: return deleteServiceStart(state, action);
        case actionTypes.DELETE_SERVICE_SUCCESS: return deleteServiceSuccess(state, action);
        case actionTypes.DELETE_SERVICE_FAIL: return deleteServiceFail(state, action);
        // delete sub-service
        case actionTypes.DELETE_SUB_SERVICE_START: return deleteSubServiceStart(state, action);
        case actionTypes.DELETE_SUB_SERVICE_SUCCESS: return deleteSubServiceSuccess(state, action);
        case actionTypes.DELETE_SUB_SERVICE_FAIL: return deleteSubServiceFail(state, action);

        default: return state;
    }
}

export default serviceReducer;
