import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from '@material-ui/core'
import { RESOURCE_ACTIONS, TARGET_EVENT } from '../../../../shared/rRule/rrule';

const RecurrenceTargetEventModal = React.forwardRef(({ ...props }, ref) => {
    const [open, setOpen] = React.useState(false);
    const [actionType, setActionType] = React.useState(null)
    const [targetType, setTargetType] = React.useState(TARGET_EVENT.SINGLE);

    // TO Open modal from a parent component
    React.useImperativeHandle(ref, () => ({
        openModal(type = RESOURCE_ACTIONS.ACTION_UPDATE) {
            setOpen(true)
            setActionType(type)
        }
    }));

    const handleClose = () => setOpen(false);

    const handleDone = () => {
        props.onDone(targetType, actionType);
        handleClose();
    }

    const handleCancel = () => {
        props.onCancel && props.onCancel();
        handleClose();
    }

    return (
        <div>
            <Dialog disableEnforceFocus open={open} onClose={handleClose}>
                <DialogTitle>{`${actionType} recurring ${props.resource}`}</DialogTitle>
                <DialogContent style={{ width: '24rem' }}>
                    <FormControl component="fieldset" variant="outlined" >
                        <RadioGroup
                            id="recurrence-target-action"
                            name="recurrence-target-action"
                            value={targetType}
                            onChange={e => setTargetType(e.target.value)} >
                            {[{
                                value: TARGET_EVENT.SINGLE,
                                label: 'This event'
                            }, {
                                value: TARGET_EVENT.THIS_AND_THE_FOLLOWING,
                                label: 'This and following events'
                            }].map(option => <FormControlLabel
                                label={option.label}
                                key={option.value}
                                value={option.value}
                                style={{ display: 'flex', alignItems: 'center' }}
                                control={<Radio color="primary" value={option.value} />}
                            />
                            )}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDone} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
})

export default RecurrenceTargetEventModal
