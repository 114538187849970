import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    // resources per tasks
    reourcesPerTasks: [],
    totalResources: {},
    tasksCurrentWeek: [],
    loadingReosurcesPerTask: false,
}

//////////////////////////////  fetch resources per tasks
const fecthResourcesPerTaskStart = (state, _action) => {
    return updateObject(state, { loadingReosurcesPerTask: true });
}

const fecthResourcesPerTaskSuccess = (state, action) => {
    return updateObject(state, {
        reourcesPerTasks: action.reourcesPerTasks,
        tasksCurrentWeek: action.tasksCurrentWeek,
        totalResources: action.totalResources,
        loadingReosurcesPerTask: false,
    });
}

const fecthResourcesPerTaskFail = (state, _action) => {
    return updateObject(state, { loadingReosurcesPerTask: false });
}

const ganttproReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // fecth gant resources
        case actionTypes.GET_GANTT_PRO_RESOURCES_PER_TASKS_START: return fecthResourcesPerTaskStart(state, action);
        case actionTypes.GET_GANTT_PRO_RESOURCES_PER_TASKS_SUCCESS: return fecthResourcesPerTaskSuccess(state, action);
        case actionTypes.GET_GANTT_PRO_RESOURCES_PER_TASKS_FAIL: return fecthResourcesPerTaskFail(state, action);

        default: return state;
    }
}

export default ganttproReducer;
