import { API_AUTH, axiosInstance } from "./utils/apiResources";

export const authService = {
    signIn,
    refreshToken,
}

function signIn(data) {
    return axiosInstance.post(
        API_AUTH.LOGIN,
        data
    );
}

function refreshToken(data) {
    return axiosInstance.post(
        API_AUTH.REFRESH_TOKEN,
        data
    );
}
