import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import * as actions from "../../store/actions/index";
import {
    SERVICE_FILTERS,
} from "../../services/utils/filters";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";

import classes from "./LoadPlan.module.scss";

function LoadPlanTable(props) {
    const innerCellColor = (percentage) => {
        if (percentage <= 60) {
            return classes.legendColor1;
        } else if (percentage <= 80) {
            return classes.legendColor2;
        } else if (percentage <= 100) {
            return classes.legendColor3;
        }
        return classes.legendColor4;
    };

    const percentage = (service, affectationsOfTheWeek, workingDaysPerWeek) => {
        if (affectationsOfTheWeek[service.code] === undefined) {
            return 0;
        }
        if (workingDaysPerWeek[service.code] === undefined) {
            return 0;
        }
        return (
            (affectationsOfTheWeek[service.code] /
                8 /
                (workingDaysPerWeek[service.code] / 8)) *
            100
        );
    };

    const date = moment();

    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.cell} key={0}>
                        {props.tableData.name}
                    </TableCell>
                    {props.affectationsPerWeek.map((_, index) => (
                        <TableCell className={classes.cell} key={index}>
                            S{index === 0 ? date.isoWeek() : date.add(1, "week").isoWeek()}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.tableData.subServices.map((service, index) => (
                    service.enabled &&
                    <TableRow key={index}>
                        <TableCell className={`${classes.cell} ${classes.sticky}`}>
                            {service.name}
                        </TableCell>
                        {props.affectationsPerWeek.map((affectationsOfTheWeek, index) => (
                            <TableCell className={classes.cell} key={index}>
                                <Tooltip
                                    title={
                                        percentage(
                                            service,
                                            affectationsOfTheWeek,
                                            props.workingDaysPerWeek[index]
                                        ).toFixed(1) + "%"
                                    }
                                    arrow
                                >
                                    <div
                                        className={classes.innerCell}
                                        style={{
                                            backgroundColor: innerCellColor(
                                                percentage(
                                                    service,
                                                    affectationsOfTheWeek,
                                                    props.workingDaysPerWeek[index]
                                                )
                                            ),
                                        }}
                                    >
                                        {affectationsOfTheWeek[service.code]
                                            ? (affectationsOfTheWeek[service.code] / 8).toFixed(1)
                                            : 0}&nbsp;
                                        J
                                    </div>
                                </Tooltip>
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </>
    );
}

function OccupationLegend() {
    return (
        <div className={classes.legend}>
            <div className={classes.legendItem1}>&lt;60%</div>
            <div className={classes.legendItem2}>&lt;80%</div>
            <div className={classes.legendItem3}>80 - 100%</div>
            <div className={classes.legendItem4}>&gt;100%</div>
        </div>
    );
}

function LoadPlan(props) {
    const [dataIsLoaded, setDataIsLoaded] = React.useState(false);

    const [services, setServices] = React.useState([]);

    //destructuring props
    const {
        onFetchServices,
        onFetchAffectationsPerWeekAndServices,
        onFetchWorkingDaysPerWeekAndServices,
    } = props;

    useEffect(() => {
        onFetchServices({ [SERVICE_FILTERS.PAGINATION]: false });
    }, [onFetchServices]);

    useEffect(() => {
        onFetchAffectationsPerWeekAndServices();
    }, [onFetchAffectationsPerWeekAndServices]);

    useEffect(() => {
        onFetchWorkingDaysPerWeekAndServices();
    }, [onFetchWorkingDaysPerWeekAndServices]);

    useEffect(() => {
        setDataIsLoaded(
            !props.servicesLoading &&
            !props.affectationsPerWeekAndServicesLoading &&
            !props.workingDaysPerWeekAndServicesLoading
        );
    }, [props.servicesLoading, props.affectationsPerWeekAndServicesLoading, props.workingDaysPerWeekAndServicesLoading]);

    useEffect(() => {
        if (dataIsLoaded) {
            // remove freelance services
            const services = props.services.map((service) => ({
                ...service,
                subServices: [
                    ...service.subServices.filter(
                        (subService) => !subService.isFreelance
                    ),
                ],
            }));

            setServices(services);
        }
    }, [dataIsLoaded, props.services]);

    return (
        <div className={classes.loadPlan}>
            <div className={classes.header}>
                <div className={classes.headerText}>
                    <h2 style={{ fontWeight: "bold" }}>Load Plan</h2>
                    <h6>Occupation for each team per week</h6>
                </div>

                <OccupationLegend />
            </div>

            {dataIsLoaded ? (
                <Table className={classes.table}>
                    {services.map((row, index) => (
                        <LoadPlanTable
                            key={index}
                            tableData={row}
                            services={services}
                            affectationsPerWeek={props.affectationsPerWeekAndServices}
                            workingDaysPerWeek={props.workingDaysPerWeekAndServices}
                        />
                    ))}
                </Table>
            ) : (
                <div className={classes.loaderDiv}>
                    <div className={classes.loader} />
                    <div className={classes.loaderText}>Building Load Plan ...</div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        // services
        services: state.service.services,
        servicesLoading: state.service.servicesLoading,
        //affectationsPerWeekAndServices
        affectationsPerWeekAndServices: state.service.affectationsPerWeekAndServices,
        affectationsPerWeekAndServicesLoading: state.service.affectationsLoading,
        //workingDaysPerWeekAndServices
        workingDaysPerWeekAndServices: state.service.workingDaysPerWeekAndServices,
        workingDaysPerWeekAndServicesLoading: state.service.workingDaysLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchServices: (queryParams) => dispatch(actions.fetchServices(queryParams)),
        onFetchAffectationsPerWeekAndServices: (queryParams) => dispatch(actions.fetchAffectationsPerWeekAndServices(queryParams)),
        onFetchWorkingDaysPerWeekAndServices: (queryParams) =>  dispatch(actions.fetchWorkingDaysPerWeekAndServices(queryParams)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadPlan);
