import React from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
    FormControl,
} from '@material-ui/core';

import classes from './Toggle.module.scss';

export default function Toggle(props) {

    const children = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0].map((nb) => {
        return (
            <ToggleButton
                className={classes.toggleButton}
                key={nb}
                value={nb}
                disabled={nb === props.value}
            >
                {nb}
            </ToggleButton>
        );
    });

    return (
        <FormControl component="fieldset" variant="outlined" >
            <ToggleButtonGroup
                exclusive
                size="small"
                id={props.id}
                value={props.value}
                onChange={props.onChangeData} >
                {children}
            </ToggleButtonGroup>
        </FormControl>
    )
}
