////////////     App Action-Types
export const FETCH_APP_DBAL = 'FETCH_APP_DBAL';
export const FETCH_APP_ME_START = 'FETCH_APP_ME_START';
export const FETCH_APP_ME_SUCCESS = 'FETCH_APP_ME_SUCCESS';
export const FETCH_APP_ME_FAIL = 'FETCH_APP_ME_FAIL';
export const RESET_APP_DASHBOARD_AFFECTATIONS = 'RESET_APP_DASHBOARD_AFFECTATIONS';
export const RESET_APP_DASHBOARD_DAY_OFFS = 'RESET_APP_DASHBOARD_DAY_OFFS';

////////////     Auth Action-Types
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
// redirection path
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

//////////// users Action-Types
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
// UPDATE USERS ROLES + POSITIONS
export const SET_USER_START = 'SET_USER_START';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAIL = 'SET_USER_FAIL';
// GET USER DETAILS
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
// GET SINGLE USER DETAILS FOR SINGLE SHIFTS
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS';

//////////// projects Action-Types
// fetch all projects
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';

//////////// affectation Action-Types
// fetch all affectations
export const FETCH_AFFECTATIONS_START = 'FETCH_AFFECTATIONS_START';
export const FETCH_AFFECTATIONS_SUCCESS = 'FETCH_AFFECTATIONS_SUCCESS';
export const FETCH_AFFECTATIONS_FAIL = 'FETCH_AFFECTATIONS_FAIL';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
// add affectation
export const ADD_AFFECTATION_START = 'ADD_AFFECTATION_START';
export const ADD_AFFECTATION_SUCCESS = 'ADD_AFFECTATION_SUCCESS';
export const ADD_AFFECTATION_FAIL = 'ADD_AFFECTATION_FAIL';
// update affectation
export const EDIT_AFFECTATION_START = 'EDIT_AFFECTATION_START';
export const EDIT_AFFECTATION_SUCCESS = 'EDIT_AFFECTATION_SUCCESS';
export const EDIT_AFFECTATION_FAIL = 'EDIT_AFFECTATION_FAIL';
// delete affectation
export const DELETE_AFFECTATION_START = 'DELETE_AFFECTATION_START';
export const DELETE_AFFECTATION_SUCCESS = 'DELETE_AFFECTATION_SUCCESS';
export const DELETE_AFFECTATION_FAIL = 'DELETE_AFFECTATION_FAIL';
// prev week affectation
export const PREV_WEEK_AFFECTATION_START = 'PREV_WEEK_AFFECTATION_START';
export const PREV_WEEK_AFFECTATION_SUCCESS = 'PREV_WEEK_AFFECTATION_SUCCESS';
export const PREV_WEEK_AFFECTATION_FAIL = 'PREV_WEEK_AFFECTATION_FAIL';
// approve single/multi affectation request(s)
export const APPROVE_LIST_AFFECTATION_START = 'APPROVE_LIST_AFFECTATION_START';
export const APPROVE_LIST_AFFECTATION_SUCCESS = 'APPROVE_LIST_AFFECTATION_SUCCESS';
export const APPROVE_LIST_AFFECTATION_FAIL = 'APPROVE_LIST_AFFECTATION_FAIL';
// Hub URL for affectation
export const SETUP_MERCURE = 'SETUP_MERCURE';

//////////// day off Action-Types
// fetch all dayoffs
export const FETCH_DAY_OFF_START = 'FETCH_DAY_OFF_START';
export const FETCH_DAY_OFF_SUCCESS = 'FETCH_DAY_OFF_SUCCESS';
export const FETCH_DAY_OFF_FAIL = 'FETCH_DAY_OFF_FAIL';
// add dayoff
export const ADD_DAY_OFF_START = 'ADD_DAY_OFF_START';
export const ADD_DAY_OFF_SUCCESS = 'ADD_DAY_OFF_SUCCESS';
export const ADD_DAY_OFF_FAIL = 'ADD_DAY_OFF_FAIL';
// add recurrent day off Action-Types
export const ADD_RECURRENT_DAY_OFF_SUCCESS = 'ADD_RECURRENT_DAY_OFF_SUCCESS';
// delete dayoff
export const DELETE_DAY_OFF_START = 'DELETE_DAY_OFF_START';
export const DELETE_DAY_OFF_SUCCESS = 'DELETE_DAY_OFF_SUCCESS';
export const DELETE_DAY_OFF_FAIL = 'DELETE_DAY_OFF_FAIL';
// update dayoff
export const EDIT_DAY_OFF_START = 'EDIT_DAY_OFF_START';
export const EDIT_DAY_OFF_SUCCESS = 'EDIT_DAY_OFF_SUCCESS';
export const EDIT_DAY_OFF_FAIL = 'EDIT_DAY_OFF_FAIL';

//////////// reset pwd email Action-Types
// reset pwd email
export const RESET_PWD_MAIL_START = 'RESET_PWD_MAIL_START';
export const RESET_PWD_MAIL_SUCCESS = 'RESET_PWD_MAIL_SUCCESS';
export const RESET_PWD_MAIL_FAIL = 'RESET_PWD_MAIL_FAIL';
// confirm reset pwd
export const RESET_PWD_START = 'RESET_PWD_START';
export const RESET_PWD_SUCCESS = 'RESET_PWD_SUCCESS';
export const RESET_PWD_FAIL = 'RESET_PWD_FAIL';

//////////// Gantt Pro Action-Types
// get all resoucres tasks
export const GET_GANTT_PRO_RESOURCES_PER_TASKS_START = 'GET_GANTT_PRO_RESOURCES_PER_TASKS_START';
export const GET_GANTT_PRO_RESOURCES_PER_TASKS_SUCCESS = 'GET_GANTT_PRO_RESOURCES_PER_TASKS_SUCCESS';
export const GET_GANTT_PRO_RESOURCES_PER_TASKS_FAIL = 'GET_GANTT_PRO_RESOURCES_PER_TASKS_FAIL';

/////////// Service Action-Types
// fetch all services
export const FETCH_SERVICES_START = 'FETCH_SERVICES_START';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAIL = 'FETCH_SERVICES_FAIL';
// add service
export const ADD_SERVICE_START = 'ADD_SERVICE_START';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAIL = 'ADD_SERVICE_FAIL';
// update service
export const EDIT_SERVICE_START = 'EDIT_SERVICE_START';
export const EDIT_SERVICE_SUCCESS = 'EDIT_SERVICE_SUCCESS';
export const EDIT_SERVICE_FAIL = 'EDIT_SERVICE_FAIL';
// delete service
export const DELETE_SERVICE_START = 'DELETE_SERVICE_START';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';

/////////// Sub-Service Action-Types
// fetch all SUB-services
export const FETCH_SUB_SERVICES_START = 'FETCH_SUB_SERVICES_START';
export const FETCH_SUB_SERVICES_SUCCESS = 'FETCH_SUB_SERVICES_SUCCESS';
export const FETCH_SUB_SERVICES_FAIL = 'FETCH_SUB_SERVICES_FAIL';
// add sub-service
export const ADD_SUB_SERVICE_START = 'ADD_SUB_SERVICE_START';
export const ADD_SUB_SERVICE_SUCCESS = 'ADD_SUB_SERVICE_SUCCESS';
export const ADD_SUB_SERVICE_FAIL = 'ADD_SUB_SERVICE_FAIL';
// update sub-service
export const EDIT_SUB_SERVICE_START = 'EDIT_SUB_SERVICE_START';
export const EDIT_SUB_SERVICE_SUCCESS = 'EDIT_SUB_SERVICE_SUCCESS';
export const EDIT_SUB_SERVICE_FAIL = 'EDIT_SUB_SERVICE_FAIL';
// delete sub-service
export const DELETE_SUB_SERVICE_START = 'DELETE_SUB_SERVICE_START';
export const DELETE_SUB_SERVICE_SUCCESS = 'DELETE_SUB_SERVICE_SUCCESS';
export const DELETE_SUB_SERVICE_FAIL = 'DELETE_SUB_SERVICE_FAIL';

/////////// UI-utils Action-Types
// drawer open state action
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE';

/////////// Data Per Week And Services Action-Types
// fetch all affectations per week and services
export const FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_START =
    'FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_START';
export const FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_SUCCESS =
    'FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_SUCCESS';
export const FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_FAIL =
    'FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_FAIL';
// fetch all working days per week and services
export const FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_START =
    'FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_START';
export const FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_SUCCESS =
    'FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_SUCCESS';
export const FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_FAIL =
    'FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_FAIL';

