/**
 * RRule-custom events constants
 * @see https://github.com/jakubroztocil/rrule/blob/master/src/weekday.ts
 */
const FREQUENCY = {
    YEARLY: 'yearly',
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    DAILY: 'daily',
    HOURLY: 'hourly',
    MINUTELY: 'minutely',
    SECONDLY: 'secondly',
    NONE: 'none'
};

/**
 * @see https://github.com/jakubroztocil/rrule/blob/master/src/rrule.ts
 */
const WEEKDAY = {
    MO: 0,
    TU: 1,
    WE: 2,
    TH: 3,
    FR: 4,
    SA: 5,
    SU: 6
}

const API_WEEKDAYS = {
    [WEEKDAY.MO]: 'monday',
    [WEEKDAY.TU]: 'tuesday',
    [WEEKDAY.WE]: 'wednesday',
    [WEEKDAY.TH]: 'thursday',
    [WEEKDAY.FR]: 'friday',
}

const CUSTOM_R_RULE = 'custom-rule'
const CUSTOM_BUILT_R_RULE = 'custom-built';

const RESOURCE_ACTIONS = {
    ACTION_UPDATE: 'update',
    ACTION_DELETE: 'delete',
}

const TARGET_EVENT = {
    SINGLE: 'single',
    THIS_AND_THE_FOLLOWING: 'this_and_the_following_events',
}

// ⚠️⚠️ Too dependent to `RecurrenceSelect.JSX` and RecurrenceRule API props
const buildRrulePayload = (rrule) => {
    if (rrule && Object.keys(rrule).length !== 0) {
        return {
            recurrenceRule: {
                frequency: rrule.frequency,
                recurrenceInterval: rrule.interval || 1,
                ...((rrule.byweekday) ? {
                    daysToRepeatOn: typeof rrule.byweekday[0] === 'object' ?
                        [API_WEEKDAYS[rrule.byweekday[0].weekday]] :
                        rrule.byweekday.map(weekdayNb => API_WEEKDAYS[weekdayNb]),
                    ...(rrule.byweekday[0].nth ? { nth: rrule.byweekday[0].nth } : {})
                } : {}),
                ...(rrule.bymonthday ? { bymonthday: rrule.bymonthday } : {}),
                ...(rrule.occurences ? { nbRecurrence: rrule.occurences } : {}),
                ...(rrule.until ? { until: rrule.until } : {}),
                isCustom: rrule.isCustom || false
            }
        }
    }
    return {};
}

export {
    FREQUENCY,
    WEEKDAY,
    CUSTOM_R_RULE,
    CUSTOM_BUILT_R_RULE,
    API_WEEKDAYS,
    RESOURCE_ACTIONS,
    TARGET_EVENT,
    buildRrulePayload,
};
