import * as actionTypes from './actions-types';
import { dayOffService } from '../../services/dayOffService';
import { DAY_OFF_FILTERS } from '../../services/utils/filters';
import { HYDRA_MEMBER } from '../../services/utils/hydras';
import {
    addBackgroundEventToFullCalendar,
    EVENT_DAY_OFF_PREFIX,
    undo,
    updateBackgroundEventInFullCalendar
} from '../../shared/fullCalendarUtility';
import { API_RESOURCES, API_URL } from '../../services/utils/apiResources';
import SnackbarUtils from '../../shared/SnackbarUtils';

export const emptyDayOffsList = () => {
    return dispatch => dispatch({
        type: actionTypes.RESET_APP_DASHBOARD_DAY_OFFS,
    });
}

//////////////  for dayoff fetching  //////////////
export const fetchDayoffSuccess = (dayoffs, total) => {
    return {
        type: actionTypes.FETCH_DAY_OFF_SUCCESS,
        dayoffs: dayoffs,
        total: total
    };
};

export const fetchDayoffFail = (error) => {
    return {
        type: actionTypes.FETCH_DAY_OFF_FAIL,
        error: error
    };
};

export const fetchDayoffStart = () => {
    return {
        type: actionTypes.FETCH_DAY_OFF_START
    };
};

export const fecthDayoffs = (queryParams = {}, externalDayOffQueryParams = {}) => {
    return dispatch => {
        dispatch(fetchDayoffStart());
        Promise
            .all([
                dayOffService.getDayOffs(queryParams),
                dayOffService.getExternalDayOffs(externalDayOffQueryParams),
            ])
            .then((results = []) => {
                let dayOffs = [];
                let total = 0;

                results.forEach(res => {
                    if (res.status === 200) {
                        const retrievedDayOffs = res.data[HYDRA_MEMBER];
                        dayOffs = dayOffs.concat(retrievedDayOffs);
                        total += retrievedDayOffs.length;
                    }
                })

                dispatch(fetchDayoffSuccess(dayOffs, total));
            })
            .catch(err => dispatch(fetchDayoffFail(err)));
    }
}

//////////////  for dayoff addding   //////////////
export const addDayoffSuccess = () => {
    return {
        type: actionTypes.ADD_DAY_OFF_SUCCESS,
    };
};

export const addDayoffFail = (error) => {
    return {
        type: actionTypes.ADD_DAY_OFF_FAIL,
        error: error
    };
};

export const addDayoffStart = () => {
    return {
        type: actionTypes.ADD_DAY_OFF_START
    };
};

export const addDayoff = (dayOffBody, calendarApi) => {
    return dispatch => {
        dispatch(addDayoffStart());
        dayOffService.createDayOff(dayOffBody)
            .then(res => {
                if (res.status === 201) {
                    // update dayoffs' list with returned dayoff (id + data)
                    addBackgroundEventToFullCalendar(res.data, calendarApi);
                    dispatch(addDayoffSuccess());
                    SnackbarUtils.toast(
                        'Day-off saved',
                        {
                            ...(res.data.userDayOff && {
                                undo: () => undo.addDayOff(res.data.id, calendarApi)
                            })
                        }
                    );
                }
            })
            .then()
            .catch(err => dispatch(addDayoffFail(err)));
    }
}

//////////////  for dayoff addding   //////////////
export const deleteDayoffSuccess = () => {
    return {
        type: actionTypes.DELETE_DAY_OFF_SUCCESS,
    };
};

export const deleteDayoffFail = (error) => {
    return {
        type: actionTypes.DELETE_DAY_OFF_FAIL,
        error: error
    };
};

export const deleteDayoffStart = () => {
    return {
        type: actionTypes.DELETE_DAY_OFF_START
    };
};

export const deleteDayoff = (id, calendarApi) => {
    return async dispatch => {
        dispatch(deleteDayoffStart());
        const { data: oldDayOff } = await dayOffService.getDayOff(id);

        dayOffService.deleteDayOff(id)
            .then(res => {
                // no content success response
                if (res.status === 204) {
                    calendarApi.getEventById(`${EVENT_DAY_OFF_PREFIX}${id}`)?.remove();
                    dispatch(deleteDayoffSuccess());

                    // Undo only with resources day-offs (no holiday day-offs)
                    SnackbarUtils.toast(
                        'Day-off removed',
                        {
                            ...(oldDayOff.userDayOff && {
                                undo: () => undo.deleteDayOff(
                                    {
                                        "typeDayOff": oldDayOff.typeDayOff,
                                        "startDateTime": oldDayOff.startDateTime.split('+')[0],
                                        "endDateTime": oldDayOff.endDateTime.split('+')[0],
                                        "office": null,
                                        "userDayOff": `${API_URL}${API_RESOURCES.USERS}/${oldDayOff.userDayOff.id}`,
                                        "recurrenceRule": oldDayOff.recurrenceRule ? `${API_URL}${API_RESOURCES.RECURRENCE_RULES}/${oldDayOff.recurrenceRule.id}` : null,
                                    },
                                    calendarApi
                                )
                            })
                        }
                    )
                }
            })
            .catch(err => dispatch(deleteDayoffFail(err)));
    }
}

//////////////  for dayoff editing   //////////////
export const editDayOffSuccess = () => {
    return {
        type: actionTypes.EDIT_DAY_OFF_SUCCESS,
    }
}

export const editDayOffFail = (error) => {
    return {
        type: actionTypes.EDIT_DAY_OFF_FAIL,
        error
    }
}

export const editDayOffStart = () => {
    return {
        type: actionTypes.EDIT_DAY_OFF_START
    }
}

export const editDayOff = (id, dayOffBody, calendarApi) => {
    return async dispatch => {
        dispatch(editDayOffStart());
        const { data: oldDayOff } = await dayOffService.getDayOff(id);

        dayOffService.updateDayOff(id, dayOffBody)
            .then(res => {
                if (res.status === 200) {
                    const event = calendarApi.getEventById(`${EVENT_DAY_OFF_PREFIX}${id}`);
                    updateBackgroundEventInFullCalendar(event, {
                        start: res.data.startDateTime.split('+')[0],
                        end: res.data.endDateTime.split('+')[0],
                        resourceId: res.data?.userDayOff?.id || null,
                        office: res.data.office,
                        typeDayOff: res.data.typeDayOff,
                    });
                }

                dispatch(editDayOffSuccess());
                SnackbarUtils.toast(
                    'Day-off saved',
                    {
                        ...(oldDayOff.userDayOff && {
                            undo: () => undo.editDayOff(
                                id,
                                {
                                    "typeDayOff": oldDayOff.typeDayOff,
                                    "startDateTime": oldDayOff.startDateTime.split('+')[0],
                                    "endDateTime": oldDayOff.endDateTime.split('+')[0],
                                    "office": null,
                                    "userDayOff": `${API_URL}${API_RESOURCES.USERS}/${oldDayOff.userDayOff.id}`,
                                    "recurrenceRule": oldDayOff.recurrenceRule ? `${API_URL}${API_RESOURCES.RECURRENCE_RULES}/${oldDayOff.recurrenceRule.id}` : null,
                                },
                                calendarApi
                            )
                        })
                    }
                )
            })
            .catch(err => dispatch(editDayOffFail(err)));
    }
}

// add Recurrent day-off (not valid for offices' holidays) and get linked events
// !! recurrence can't be created with offices(day-off of type holiday)
export const addRecurrentDayOffSuccess = () => {
    return {
        type: actionTypes.ADD_RECURRENT_DAY_OFF_SUCCESS,
    };
}

export const addRecurrentDayOff = (payloadData, calendarApi, queryParams = {}) => {
    return dispatch => {
        dispatch(addDayoffStart())
        dayOffService.createDayOff(payloadData)
            .then(res => {
                if (res.status === 201) {
                    dispatch(getDayOffsByRule(calendarApi, res.data?.recurrenceRule?.id, queryParams));
                }
            })
            .catch(err => dispatch(addDayoffFail(err)))
    }
}

const getDayOffsByRule = (calendarApi, rRuleId = null, queryParams = {}) => {
    return dispatch => {
        rRuleId && dayOffService.getDayOffs({
            ...queryParams,
            [DAY_OFF_FILTERS.RECURRENCE_RULE_ID]: rRuleId,
        })
            .then(res => {
                if (res.status === 200) {
                    // Since we add a resource dayOff (no office holiday) and for optimisation
                    // no need to update dayoffs' list, we add them directly via calendarApi.
                    addDayOffsToCalendar(res.data[HYDRA_MEMBER], calendarApi)
                    dispatch(addRecurrentDayOffSuccess());

                    const userDayOffId = res.data[HYDRA_MEMBER][0]?.userDayOff?.id;
                    SnackbarUtils.toast(
                        'Recurrent day-off saved',
                        {
                            ...(rRuleId && userDayOffId && {
                                undo: () => undo.addRecurrentDayOff(
                                    rRuleId,
                                    userDayOffId,
                                    calendarApi
                                )
                            })
                        }
                    );
                }
            })
            .catch(err => dispatch(addDayoffFail(err)))
    }
}

// for editing recurrent day-off and re-fetch a resource's day-offs
export const editRecurrentDayOff = (id, data, resourcesIds, queryParams, dayOffQueryParams, calendarApi) => {
    return dispatch => {
        dispatch(editDayOffStart())
        dayOffService.updateRecurrentDayOff(id, data, queryParams)
            .then(res => {
                if (res.status === 200) {
                    dispatch(reloadResourceDayOffs(calendarApi, resourcesIds, dayOffQueryParams, addRecurrentDayOffSuccess, editDayOffFail));
                }
            })
            .catch(err => dispatch(editDayOffFail(err)))
    }
}

// for limiting a recurrent day-off (RRULE updated from `{...}` to none `{}`)
export const breakRecurrentdayOff = (id, resourcesIds, dayOffQueryParams, calendarApi) => {
    return dispatch => {
        dispatch(deleteDayoffStart())
        dayOffService.limitRecurrentDayOff(id)
            .then(res => {
                if (res.status === 204) {
                    dispatch(reloadResourceDayOffs(calendarApi, resourcesIds, dayOffQueryParams, addRecurrentDayOffSuccess, deleteDayoffFail));
                }
            })
            .catch(err => dispatch(deleteDayoffFail(err)))
    }
}

// delete recurrent day-off and its following events
export const deleteRecurrentDayOffAndFollowing = (id, resourcesIds, dayOffQueryParams, calendarApi) => {
    return dispatch => {
        dispatch(deleteDayoffStart());
        dayOffService.deleteRecurrentDayOffAndFollowing(id)
            .then(res => {
                if (res.status === 204) {
                    dispatch(reloadResourceDayOffs(calendarApi, resourcesIds, dayOffQueryParams, addRecurrentDayOffSuccess, deleteDayoffFail));
                }
            })
            .catch(err => dispatch(deleteDayoffFail(err)))
    }
}

// common
const reloadResourceDayOffs = (calendarApi, resourcesIds, queryParams, successDispatcher, failDispatcher) => {
    return dispatch => {
        dayOffService.getDayOffs(queryParams)
            .then(res => {
                if (res.status === 200 && Array.isArray(resourcesIds)) {
                    resourcesIds.forEach(resourceId => {
                        const calendarResource = calendarApi.getResourceById(resourceId);
                        if (calendarResource) {
                            // remove resource background events
                            const resourceEvents = calendarResource.getEvents()
                            resourceEvents.forEach(event => {
                                // Alternative: type of day-off through event title `!== 'Holiday'`.
                                event.rendering === 'background' &&
                                    Array.isArray(event?._def?.resourceIds) &&
                                    event._def.resourceIds.length === 1 &&
                                    event.remove();
                            })

                            // reload new events into calendar
                            addDayOffsToCalendar(res.data[HYDRA_MEMBER], calendarApi)
                        }
                    })
                }
                // to re-change, just to stop the loading state
                dispatch(successDispatcher());
                SnackbarUtils.toast('Recurrent day-off saved');
            })
            .catch(err => dispatch(failDispatcher(err)))
    }
}

const addDayOffsToCalendar = (dayOffs, calendarApi) => {
    dayOffs.forEach(dayOff => addBackgroundEventToFullCalendar(dayOff, calendarApi));
}
