import {Dialog, DialogContent} from "@material-ui/core";
import classes from './ModalCalendat.scss'

export default function PreviewCalendar(props) {
  function handleClose() {
    props.handleClose(false);
  }

  return (
    <Dialog open={props.isOpen} onClose={handleClose} disableEnforceFocus className={classes.myclassName}>
        <DialogContent>
          <iframe
            title="Google Calendar Preview"
            src={props.calendarUrl}
            style={{border: 0, width: '65vw', height: '800px'}}
          />
        </DialogContent>
    </Dialog>
  )
}
