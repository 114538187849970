import React, {useState} from 'react';
import {Avatar, Menu, MenuItem} from '@material-ui/core';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {toTitleCase} from '../../../shared/utility';
import classes from './MenuItems.module.scss';
import PreviewCalendar from "../Modal/ModalCalendarPreview/ModalCalendar";
import {CalendarToday} from '@material-ui/icons';

// render resource cell completely
export default function MenuItems(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState('');

    return (
        <div className={classes.div}>
            <Avatar
                key={props.id}
                className={classes.avatar}
            >
                {props.isFictive ?
                    <HelpOutlineIcon /> :
                    toTitleCase(props.title)
                }
            </Avatar>
            <>{props.title}</>
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <React.Fragment>
                        <MoreVertIcon
                            style={{cursor: 'pointer'}}
                            key={props.id}
                            {...bindTrigger(popupState)} />

                        <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                onClick={() => {
                                    props.handlePrevWeekEvents(props.id);
                                    popupState.close();
                                }}>Copy last week events
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    const calendarUrl = `https://calendar.google.com/calendar/embed?src=${encodeURIComponent(props?.email)}&mode=WEEK`;
                                    setUrl(calendarUrl);
                                    setIsOpen(true);
                                    popupState.close();
                                }}>
                                <CalendarToday/> &nbsp; Preview Calendar
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
            <PreviewCalendar isOpen={isOpen} handleClose={setIsOpen} calendarUrl={url}/>
        </div>
    )

}
