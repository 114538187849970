import * as actionTypes from './actions-types';
import { statService } from '../../services/statService';

// for gantt pro resources per tasks fecthing
export const fetchGanttResourcesPerTasksSuccess = (reourcesPerTasks, tasksCurrentWeek, totalResources) => {
    return {
        type: actionTypes.GET_GANTT_PRO_RESOURCES_PER_TASKS_SUCCESS,
        reourcesPerTasks: reourcesPerTasks,
        tasksCurrentWeek: tasksCurrentWeek,
        totalResources: totalResources,
    };
};

export const fetchGanttResourcesPerTasksFail = (error) => {
    return {
        type: actionTypes.GET_GANTT_PRO_RESOURCES_PER_TASKS_FAIL,
        error: error
    };
};

export const fetchGanttResourcesPerTasksStart = () => {
    return {
        type: actionTypes.GET_GANTT_PRO_RESOURCES_PER_TASKS_START
    };
};

export const fetchGanttResourcesPerTasks = () => {
    return dispatch => {
        dispatch(fetchGanttResourcesPerTasksStart());
        statService.getGanrrResourcesPerTasks()
            .then(res => dispatch(fetchGanttResourcesPerTasksSuccess(
                res.data['gantt'],
                res.data['tasksCurrentWeek'],
                res.data['totalResource']
            )))
            .catch(err => dispatch(fetchGanttResourcesPerTasksFail(err)));
    }
}
