import React from 'react';
import {
    Card,
    CardActions,
    CardHeader,
} from '@material-ui/core';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';

import './CardServiceStat.scss';

export default function CardServiceStat(props) {
    const customStyle = {
        borderRadius: '0.5rem',
        borderColor: props.elements.color,
    };

    return (
        <Card className="cardStyle" elevation={0} style={{...customStyle}}>
            <CardHeader
                style={{textTransform: 'capitalize'}}
                titleTypographyProps={{ variant: 'h6' }}
                title={props.elements.name}
            />
            <CardActions>
                <div className="totalResource">
                    <PersonOutlineRoundedIcon fontSize="large" className="serviceIcon" />
                    <span className="allocationSpan">
                        {props.elements.inUse}
                    </span>
                    <span className="totalSpan">
                        /{props.elements.total}
                    </span>
                </div>
            </CardActions>
        </Card>
    )
}
