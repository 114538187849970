import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import BackspaceIcon from '@material-ui/icons/Backspace';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import { dispalyRoles, resetRoles, updateObject } from '../../shared/utility';
import { NO_SERVICE } from '../../shared/userUtility';
import ModalResetPassword from '../../components/UI/Modal/ModalResetPassword/ModalResetPassword';
import * as actions from '../../store/actions/index';
import UserRolesSelect from '../../components/UI/Select/UserRoles/UserRolesSelect';
import ServiceSelect from '../../components/UI/Select/Service/ServiceSelect';
import SubServiceSelect from '../../components/UI/Select/Service/SubServiceSelect';
import { SERVICE_FILTERS, USER_FILTERS } from '../../services/utils/filters';
import { API_RESOURCES, API_URL } from '../../services/utils/apiResources';
import { isAdmin } from '../../shared/authorizationUtility';

import classes from './Users.module.css';

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			itemsPerPage: 50,
			mainServiceId: 0,
			keyFilters: {
				fullName: '',
				email: '',
				jobTitle: '',
				roles: '',
				mainService: '',
				location: [],
			},
		}
		this.roleLimits = isAdmin();
	}

	getUserQueryParams = () => {
		const { page, itemsPerPage } = this.state;
		const { fullName, roles, email, jobTitle, mainService, location } = this.state.keyFilters;

		return {
			[USER_FILTERS.PAGINATION]: true,
			[USER_FILTERS.IS_FICTIVE]: false,
			[USER_FILTERS.PAGE]: page + 1,
			[USER_FILTERS.ITEMS_PER_PAGE]: itemsPerPage,
			[USER_FILTERS.FULL_NAME]: fullName,
			[USER_FILTERS.ROLES]: roles,
			[USER_FILTERS.EMAIL]: email,
			[USER_FILTERS.JOB_TITLE]: jobTitle,
			...(mainService !== '' && parseInt(mainService) === 0 ?
				{ [USER_FILTERS.MAIN_SERVICE_EXIST]: false } :
				{ [USER_FILTERS.MAIN_SERVICE]: mainService }
			),
			...(Array.isArray(location) && location.length > 0 && {
				[USER_FILTERS.LOCATION_EXACT]: location
			}),
		};
	}

	componentDidMount() {
		this.props.onFetchServices({
			[SERVICE_FILTERS.ENABLED]: true,
			[SERVICE_FILTERS.PAGINATION]: false,
		});
		this.props.onFetchUsers(this.getUserQueryParams(this.state.page));
	}

	componentDidUpdate(_prevProps, prevState) {
		if (!isEqual(prevState, this.state)) {
			this.props.onFetchUsers(this.getUserQueryParams())
		}
	}

	// update a user role + team
	handleUpdateUser(idUser, newData) {
		this.props.onSetUser(idUser, {
			"roles": resetRoles(newData.roles),
			"mainService": newData.mainService !== 0 ? `${API_URL}${API_RESOURCES.SERVICES}/${newData.mainService}` : null,
			"subService": newData.subService !== 0 ? `${API_URL}${API_RESOURCES.SUB_SERVICES}/${newData.subService}` : null,
		}, this.props.users);
	}

	openModal(handleClickOpen) {
		this.handleClickOpen = handleClickOpen;
	}


	onPageChangeHandler = (_event, newPage) => {
		this.setState({ page: newPage });
	}

	onRowsPerPageHandler = (pageSize) => {
		this.setState({
			page: 0,
			itemsPerPage: pageSize
		});
	}

	// assign values directly
	onFilterHandler = debounce((data) => {
		let keyFilters = {
			[USER_FILTERS.FULL_NAME]: '',
			[USER_FILTERS.ROLES]: '',
			[USER_FILTERS.EMAIL]: '',
			[USER_FILTERS.JOB_TITLE]: '',
			[USER_FILTERS.MAIN_SERVICE]: '',
			[USER_FILTERS.SUB_SERVICE]: null,
			[USER_FILTERS.LOCATION_EXACT]: [],
		}

		data.forEach(element => {
			keyFilters = updateObject(keyFilters, {
				[element.column.field]: USER_FILTERS.MAIN_SERVICE === element.column.field ? parseInt(element.value) : element.value,
			})
		});
		this.setState({
			keyFilters,
			page: 0
		});
	}, 500)

	render() {
		const { page, itemsPerPage } = this.state;
		const usersTableData = [];
		const columns = [
			{ title: '#', field: 'counter', editable: 'never', filtering: false },
			{ title: 'FullName', field: 'fullName', editable: 'never' },
			{ title: 'Email', field: 'email', editable: 'never' },
			{ title: 'Job Title', field: 'jobTitle', editable: 'never' },
			{
				title: 'Roles',
				field: 'roles',
				editComponent: props => (<UserRolesSelect {...props} />),
				render: rowData => rowData.roles.join(', '),
			},
			{
				title: 'Main Service',
				field: 'mainService',
				render: rowData => rowData.mainServiceName,
				editComponent: props => (<ServiceSelect {...props} services={this.props.services} isFilter={false} />),
				// mainServiceFilterId to force filter value (isn't handled auto by material table unlike the editComponent)
				filterComponent: props => (<ServiceSelect {...props} services={this.props.services} isFilter={true} mainServiceFilterId={this.state.keyFilters.mainService} />),
			},
			{
				title: 'Sub-Service',
				field: 'subService',
				render: rowData => rowData.subServiceName,
				editComponent: props => (<SubServiceSelect {...props} services={this.props.services} isFilter={false} />),
				filtering: false,
			},
			{
				title: 'Office',
				field: 'location',
				editable: 'never',
				lookup: { 'Autre/Exter': 'Autre/Exter', Bordeaux: 'Bordeaux', Londres: 'Londres', Paris: 'Paris', Tunis: 'Tunis', }
			},
		];

		let i = (itemsPerPage * page) + 1;
		for (const key in this.props.users) {
			const singleUser = this.props.users[key]
			usersTableData.push({
				...singleUser,
				counter: i,
				roles: dispalyRoles(singleUser.roles),
				mainService: singleUser?.mainService?.id || 0,
				mainServiceName: singleUser?.mainService?.name || NO_SERVICE,
				subService: singleUser?.subService?.id || 0,
				subServiceName: singleUser?.subService?.name || NO_SERVICE,
			});
			i++;
		}

		return (
			<div className={classes.bigDiv}>
				<div>
					<center><h2>Users List</h2></center>
				</div>
				<MaterialTable
					title=""
					columns={columns}
					data={usersTableData}
					key={this.props.totaC}
					editable={this.roleLimits ? {
						isEditable: (_rowData) => this.roleLimits,
						onRowUpdate: (newData, _oldData) =>
							new Promise((resolve, _reject) => {
								setTimeout(() => {
									this.handleUpdateUser(newData.id, newData);
									resolve()
								})
							}),
					} : {}
					}
					options={{
						actionsColumnIndex: -1,
						pageSizeOptions: [50, 100, 200],
						pageSize: this.state.itemsPerPage,
						headerStyle: {
							position: 'sticky',
							top: 0,
							fontSize: '25px'
						},
						filtering: true,
						search: false,
					}}
					icons={{
						Edit: BorderColorRoundedIcon,
						Search: YoutubeSearchedForIcon,
						ResetSearch: BackspaceIcon,
					}}
					isLoading={this.props.loading}
					onChangeRowsPerPage={this.onRowsPerPageHandler}
					onFilterChange={(e) => this.onFilterHandler(e)}
					actions={this.roleLimits ? [
						{
							icon: 'lock',
							tooltip: 'update password',
							onClick: (_event, rowData) => this.handleClickOpen(rowData),
						}
					] : []}
					components={{
						Pagination: props => (
							<TablePagination
								{...props}
								count={this.props.totaC}
								page={this.state.page}
								onChangePage={this.onPageChangeHandler}
							/>
						),
					}}
				/>
				<ModalResetPassword onRef={this.openModal.bind(this)} />
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		// user
		users: state.user.users,
		loading: state.user.loading,
		updatedUser: state.user.user,
		token: state.auth.token,
		totaC: state.user.totalcount,
		// service
		services: state.service.services,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		// user
		onFetchUsers: (queryParams) => dispatch(actions.fetchUsers(queryParams)),
		onSetUser: (userId, roleData, usersOutsideStore) => dispatch(actions.setUser(userId, roleData, usersOutsideStore)),
		// service
		onFetchServices: (queryParams) => dispatch(actions.fetchServices(queryParams)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
