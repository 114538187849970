import { createMuiTheme } from '@material-ui/core/styles';
import variables from '../Styles/variables.module.scss';

const defaultMUITheme = createMuiTheme({
    palette: {
        primary: {
            main: variables.mainColor,
        },
        secondary: {
            main: variables.secondaryColor,
        },
    },
});

export default defaultMUITheme;
