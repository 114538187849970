import moment from "moment";
import store from "../store/store";
import { ROLE } from "./authUtility";

function canCreateAffectation() {
    return isProjectManager();
}

/**
 * Can Update, Delete affectation
 */
function canUDAffectation(createdById, isConfirmed = true) {
    return isValidator() ||
        (
            ROLE.PROJECT_MANAGER === getLoggedUserRole() &&
            parseInt(createdById) === getLoggedUserId() &&
            !isConfirmed
        );
}

function canApproveAffectation(isConfirmed = true) {
    return isValidator() && !isConfirmed;
}

function canApproveNonDiscordantAffectations() {
    return isValidator();
}

function canDeletePassedAffectation(endDate) {
    return moment(endDate).diff(moment(), 'days') <= 0 &&
        isValidator();
}


/**
 * Can Create, Update, Delete day-off
 */
function canCUDDayOff() {
    return isValidator();
}

const isAdmin = () => ROLE.ADMIN === getLoggedUserRole();
const isValidator = () => [ROLE.ADMIN, ROLE.VALIDATOR].includes(getLoggedUserRole());
// ⚠️ On CUD operations beware of permissions between `ADMIN`|`VALIDATOR` and `PROJECT_MANAGER`
const isProjectManager = () => [ROLE.ADMIN, ROLE.VALIDATOR, ROLE.PROJECT_MANAGER].includes(getLoggedUserRole());
const hasOnlyResourceRole = () => ROLE.RESOURCE === getLoggedUserRole();
const getLoggedUserRole = () => store.getState()?.app?.user?.roles;
const getLoggedUserId = () => store.getState()?.app?.user?.userId;

export {
    canCreateAffectation,
    canUDAffectation,
    canApproveAffectation,
    canApproveNonDiscordantAffectations,
    canDeletePassedAffectation,
    canCUDDayOff,
    isAdmin,
    isProjectManager,
    hasOnlyResourceRole,
    getLoggedUserId
}
