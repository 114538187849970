import React, {
    useState,
    useEffect,
    useRef
} from 'react';

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { connect } from 'react-redux';

import * as actions from '../../../../store/actions/index';
import { checkValidity, updateObject } from '../../../../shared/utility';

function ModalResetPassword(props) {
    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState({});
    const [password, setPassword] = useState({
        value: '',
        validation: {
            required: true,
            minLength: 2
        },
        valid: false,
        touched: false
    });

    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            //componentDidMount logic
            mounted.current = true;
            props.onRef(handleClickOpen.bind(this));
        } else {
            //componentDidUpdate logic
            if (open === true) {
                props.onResetPwdEmail({
                    email: rowData.email,
                }, props.token);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, rowData]);

    // state + onChange handlers
    const handleClickOpen = (userRowData) => {
        setRowData({ ...userRowData });
        setOpen(true);
    };

    const handleClose = () => {
        handleOnChangePassword('');
        setOpen(false);
    };

    const handleOnChangePassword = (value) => {
        setPassword(updateObject('password', {
            value: value,
            valid: checkValidity(value, password.validation),
            touched: true,
        }));
    }

    const handleUpdatePassword = () => {
        props.onResetPassword({
            pass: password.value,
            token: props.resetToken,
        });
        handleClose();
    }
    // values
    const emailValue = rowData ? rowData.email : 'no-email';

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update <u>{rowData ? rowData.fullName : ''}</u> password</DialogTitle>
                <DialogContent>
                    <TextField
                        disabled
                        value={emailValue}
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                    />
                    <TextField
                        value={password.value}
                        onChange={(e) => handleOnChangePassword(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Enter Password"
                        type="password"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        Cancel
                    </Button>
                    <Button
                        disabled={props.loading || !password.valid}
                        onClick={handleUpdatePassword}
                        color="primary">
                        Update Password
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        // auth
        token: state.auth.token,
        // reset password
        loading: state.resetPwd.loading,
        resetToken: state.resetPwd.resetToken
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPwdEmail: (body, token) => dispatch(actions.resetPwdEmail(body, token)),
        onResetPassword: (body) => dispatch(actions.resetPwd(body)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalResetPassword);
