import React from 'react';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core';

const RadioGroupRecurrenceLimit = props => {
    return <FormControl component="fieldset" variant="outlined" >
        <RadioGroup
            id={props.id}
            name={props.name}
            value={props.value}
            onChange={e => props.onChange(e.target.value)} >
            {props.data.map(option => <FormControlLabel
                label=''
                key={option.id}
                value={option.value}
                style={{ display: 'flex', alignItems: 'center' }}
                control={
                    <div className="end-limit_option">
                        <Radio color="primary" value={option.value} />
                        <div className="end-limit_option" style={{ pointerEvents: option.value !== props.value ? 'none' : 'inherit' }}>
                            {option.text}
                            {option.component}
                        </div>
                    </div>
                } />)}
        </RadioGroup>
    </FormControl>
};

export default RadioGroupRecurrenceLimit;
