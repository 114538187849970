import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { NO_SERVICE } from '../../../../shared/userUtility';

export default function DefaultSelect(props) {
    const getMenuItemProp = (element, singlePropName) => {
        const singleProp = props[singlePropName];
        return singleProp ? element[singleProp] : element;
    };

    const generateKey = (key) => `${props.id}-${key}`;

    const undefinedMenuItem = props.isFilter ?
        (
            <MenuItem key={generateKey('undefined')} value={''}>
                <em>
                    {''}
                </em>
            </MenuItem>
        ) : null;

    const noneMenuItem = props.none ?
        (<MenuItem key={generateKey(NO_SERVICE)} value={0}>
            {
                <em>
                    {NO_SERVICE}
                </em>
            }
        </MenuItem>) : null;

    return (
        <div className={props.className || ''}>
            <Select
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                multiple={props.multiple}
                variant={props.variant || "standard"}
            >
                {undefinedMenuItem}
                {noneMenuItem}
                {
                    props.data.map(element => {
                        const chosenKey = props.itemKey ? element[props.itemKey] : element;
                        return (
                            <MenuItem key={generateKey(chosenKey)} value={getMenuItemProp(element, 'itemValue')}>
                                {
                                    getMenuItemProp(element, 'itemName')
                                }
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </div>
    )
}
