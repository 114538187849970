import React from 'react';
import { SnackbarContent, SnackbarProvider, useSnackbar } from 'notistack';
import {
  IconButton,
  Collapse,
  Paper,
  Typography,
  Card,
  CardActions,
} from '@material-ui/core';
import {
  CloseRounded as CloseIcon,
  ExpandMoreRounded as ExpandIcon,
} from '@material-ui/icons';
import { SnackbarUtilsConfigurator } from '../../shared/SnackbarUtils';

import classes from './snackbar.module.scss';

export const SnackbarCalendarEventContent = React.forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = React.useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={classes.customSnackRoot}>
      <Card className={classes.customSnackCard}>
        <CardActions classes={{ root: classes.customSnackActionRoot }}>
          <Typography variant="subtitle2">
            {props.message}
          </Typography>
          <div className={classes.customSnackIcons}>
            <IconButton
              aria-label="Show more"
              className={classes.customSnackExpand}
              style={expanded ? { transform: "rotate(180deg)" } : null}
              onClick={handleExpandClick}
            >
              <ExpandIcon />
            </IconButton>
            <IconButton className={classes.customSnackExpand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.customSnackCollapse}>
            <Typography component="ul">
              <li className={classes.customLineItem}>Created By: {props.event?.createdBy.fullName}</li>
              <li className={classes.customLineItem}>Assigned To: {props.event?.assignedUser.fullName}</li>
              <li className={classes.customLineItem}>Project: {props.event?.assignedProject.name}</li>
            </Typography>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  )
});

const Action = ({ undo, close, displayClose = true }) => {
  return (
    <>
      {typeof undo === 'function' &&
        <button
          name='undo-snackbar'
          className={classes.action}
          onClick={() => {
            undo();
            close();
          }}
        >
          Undo
        </button>
      }
      {displayClose &&
        <IconButton
          style={{
            color: 'white'
          }}
          onClick={() => close()}
        >
          <CloseIcon />
        </IconButton>
      }
    </>
  )
}

const SnackbarProviderSetup = (props) => {
  const snackbarRef = React.useRef();

  return (
    <SnackbarProvider
      preventDuplicate
      ref={snackbarRef}
      maxSnack={3}
      autoHideDuration={3000}
      action={snackbarId => (
        <Action
          displayClose={snackbarRef?.current?.state?.snacks[0]?.displayClose}
          undo={snackbarRef?.current?.state?.snacks[0]?.undo}
          close={() => snackbarRef?.current?.closeSnackbar(snackbarId)}
        />
      )}
      iconVariant={{
        success: '✅',
        error: '✖️',
        warning: '⚠️',
        info: 'ℹ️',
      }}
    >
      <SnackbarUtilsConfigurator />
      {props.children}
    </SnackbarProvider>
  )
}

export default SnackbarProviderSetup
