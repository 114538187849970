import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom';

function NotFound(props) {
    return (
        <div>
            {/* Redirect after a successfull login */}
            {props.isAuthenticated &&
                props.location?.pathname === '/' &&
                <Redirect to="/dashboard" />
            }
            <h3>Page Not Found</h3>
            <Link to="dashboard">Redirect to dashboard</Link>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
    };
};

export default connect(mapStateToProps, null)(withRouter(NotFound))
