import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField } from '@material-ui/core';

import classes from './AutoCompleteGlobal.module.scss';

export default function AutoCompleteGlobal(props) {
    const autoCompleteIDBool = props.id === 'project-select-demo';
    const fecthingProgress = <center><CircularProgress size={20} color="inherit" /></center>;
    const noOptionsText = <>No matchs <span role="img" aria-label="heart" >😕</span></>
    return (
        <Autocomplete
            // asynchronous open props
            open={autoCompleteIDBool ? props.openAuto : undefined}
            onOpen={autoCompleteIDBool ? () => props.handleOpenAuto() : undefined}
            onClose={autoCompleteIDBool ? () => props.handleCloseAuto() : undefined}
            loading={autoCompleteIDBool ? props.loadingProjects : undefined}
            loadingText={autoCompleteIDBool ? fecthingProgress : undefined}
            noOptionsText={noOptionsText}
            multiple={props.multiple}
            // other standard props
            onChange={props.onChange}
            value={props.value}
            disableClearable={true}
            id={props.id}
            options={props.options}
            className={classes.autoComplete}
            autoHighlight
            groupBy={props.groupBy || function(){}}
            getOptionSelected={props.getOptionSelected}
            getOptionLabel={props.getOptionLabel}
            renderOption={props.renderOption}
            renderInput={(params) => (
                <TextField
                    // asynchronous open props
                    onChange={autoCompleteIDBool ? (e) => props.handleKeyUp(e) : undefined}
                    // other standard props
                    autoComplete="false"
                    {...params}
                    label={props.label}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        'aria-autocomplete': 'none',
                        style: { width: '150px', height: '10px' },
                        // disable autocomplete and autofill
                    }}
                />
            )}
        />
    )
}
