import moment from "moment";
import { DATE_TIME_FORMAT, transformEndDate } from "./utility";

export const setDayOffStartEnd = (actionType, selectedDateRange, halfDayStart, halfDayEnd) => {
    // button click **From outside the calendar
    const startEndRange = Array(2)
    if (actionType === 'standard') {
        // same date case
        if (moment(selectedDateRange[0]).diff(selectedDateRange[1], 'days') === 0) {
            if (halfDayStart === 'All Day') {
                startEndRange[0] = moment(selectedDateRange[0]).format(DATE_TIME_FORMAT);
                startEndRange[1] = transformEndDate(startEndRange[0], 1);
            } else {
                startEndRange[0] = moment(selectedDateRange[0]).format(DATE_TIME_FORMAT);
                startEndRange[1] = transformEndDate(startEndRange[0], 0.5);
            }
        } else {
            // !== different dates case
            if (halfDayStart === 'Half Day' && halfDayEnd === 'Half Day') {
                startEndRange[0] = transformEndDate(selectedDateRange[0], 0.5);
                startEndRange[1] = transformEndDate(selectedDateRange[1], 0.5);
            } else if (halfDayStart === 'All Day' && halfDayEnd === 'Half Day') {
                startEndRange[0] = moment(selectedDateRange[0]).format(DATE_TIME_FORMAT);
                startEndRange[1] = transformEndDate(selectedDateRange[1], 0.5);
            } else if (halfDayStart === 'Half Day' && halfDayEnd === 'All Day') {
                startEndRange[0] = transformEndDate(selectedDateRange[0], 0.5);
                startEndRange[1] = transformEndDate(selectedDateRange[1], 1);
            } else {
                startEndRange[0] = moment(selectedDateRange[0]).format(DATE_TIME_FORMAT);
                startEndRange[1] = transformEndDate(selectedDateRange[1], 1);
            }
        }
    } else if (actionType !== 'standard') {
        // for non touched range multiselect and date click
        startEndRange[0] = moment(selectedDateRange[0]).format(DATE_TIME_FORMAT);
        startEndRange[1] = moment(selectedDateRange[1]).format(DATE_TIME_FORMAT);
    }

    return startEndRange;
}


export const halfDayType = {
    HALF_DAY: 'Half Day',
    ALL_DAY: 'All Day',
}

export const halfDayData = [
    {
        value: halfDayType.HALF_DAY,
        label: halfDayType.HALF_DAY,
    },
    {
        value: halfDayType.ALL_DAY,
        label: halfDayType.ALL_DAY,
    },
]

export const dayoffDataTypes =
{
    CLASS: 'Class',
    HOLIDAY: 'Holiday',
    OFF: 'OFF',
};
