import React from 'react';
import { isEmpty } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@material-ui/core';
import moment from 'moment';

import {
  CUSTOM_BUILT_R_RULE,
  CUSTOM_R_RULE,
  FREQUENCY,
  WEEKDAY,
  API_WEEKDAYS,
} from '../../../../shared/rRule/rrule';
import NumericInput from '../../Input/NumericInput';
import DefaultSelect from '../Default/DefaultSelect';
import WeekDayToggle from '../../Toggle/WeekDayToggle';
import RadioGroupRecurrenceLimit from '../../RadioGroups/RadioGroupRecurrenceLimit';
import DatePickerForm from '../../DatePicker/DatePickerForm';

import './recurrence.scss'

const RECURRENCE_DATE_FORMAT = 'YYYY-MM-DD';

const END_TYPE_NEVER = 'never';
const END_TYPE_DATE = 'date';
const END_TYPE_OCCURENCES = 'occurence';

const MONTH_TYPE_DAY = 'by_month_day';
const MONTH_TYPE_WEEKDAY = 'by_month_weekday';

const getDefaultRecurrenceOptions = (startDateMoment, selectedWeekDay) => [
  {
    id: FREQUENCY.NONE,
    text: 'Does not repeat',
    rrule: {}
  },
  {
    id: FREQUENCY.DAILY,
    text: 'Daily',
    rrule: {
      frequency: FREQUENCY.DAILY,
      byweekday: [WEEKDAY.MO, WEEKDAY.TU, WEEKDAY.WE, WEEKDAY.TH, WEEKDAY.FR],
      interval: 1
    }
  },
  {
    id: FREQUENCY.WEEKLY,
    text: 'Weekly',
    rrule: {
      frequency: FREQUENCY.WEEKLY,
      byweekday: [selectedWeekDay],
      interval: 1
    }
  },
  {
    id: FREQUENCY.MONTHLY,
    text: `Monthly on day ${startDateMoment.date()}`,
    rrule: {
      frequency: FREQUENCY.MONTHLY,
      bymonthday: [startDateMoment.date()],
      interval: 1,
    }
  }
];

const getCustomFrequencyOptions = (recurrenceInterval) => [
  {
    id: FREQUENCY.DAILY,
    text: `Day${recurrenceInterval > 1 ? 's' : ''}`,
  },
  {
    id: FREQUENCY.WEEKLY,
    text: `Week${recurrenceInterval > 1 ? 's' : ''}`,
  },
  {
    id: FREQUENCY.MONTHLY,
    text: `Month${recurrenceInterval > 1 ? 's' : ''}`
  }
]

const getMonthsOptions = (startMoment) => [
  {
    id: MONTH_TYPE_DAY,
    text: `Monthly on day ${startMoment.date()}`,
    subText: `day ${startMoment.date()}`,
    rrule: {
      frequency: FREQUENCY.MONTHLY,
      bymonthday: [startMoment.date()],
      interval: 1
    }// every month on 2
  },
]

const weekDaysOptions = [
  {
    id: WEEKDAY.MO,
    text: 'Monday',
    apiId: API_WEEKDAYS[WEEKDAY.MO]
  },
  {
    id: WEEKDAY.TU,
    text: 'Tuesday',
    apiId: API_WEEKDAYS[WEEKDAY.TU]
  },
  {
    id: WEEKDAY.WE,
    text: 'Wednesday',
    apiId: API_WEEKDAYS[WEEKDAY.WE]
  },
  {
    id: WEEKDAY.TH,
    text: 'Thursday',
    apiId: API_WEEKDAYS[WEEKDAY.TH]
  },
  {
    id: WEEKDAY.FR,
    text: 'Friday',
    apiId: API_WEEKDAYS[WEEKDAY.FR]
  }
]

const RecurrenceSelect = ({ defaultRcurrenceOption, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const [startDateMoment, setStartDateMoment] = React.useState(moment().add(7, 'days'));
  // moment weekdays start by default on `sunday ---> 0`
  const [selectedWeekDay, setSelectedWeekDay] = React.useState(startDateMoment.weekday() - 1);

  const [recurrenceOptions, setRecurrenceOptions] = React.useState(getDefaultRecurrenceOptions(startDateMoment, selectedWeekDay))
  const [recurrenceType, setRecurrenceType] = React.useState(FREQUENCY.NONE)
  const [interval, setInterval] = React.useState(1);
  const [frequency, setFrequency] = React.useState(FREQUENCY.WEEKLY);
  // Conditional `Repeat on` data
  const [weekDays, setWeekDays] = React.useState([selectedWeekDay]);
  const [monthType, setMonthType] = React.useState(MONTH_TYPE_DAY);
  // Conditional `end` data (limit)
  const defaultEndDate = startDateMoment.clone().add(4, 'months').format(RECURRENCE_DATE_FORMAT);

  const [endType, setEndType] = React.useState(END_TYPE_NEVER)
  const [endDate, setEndDate] = React.useState(defaultEndDate);
  const [occurences, setOccurences] = React.useState(1);

  React.useEffect(() => {
    const newStartDate = props.startDate ? moment(props.startDate) : moment().add(7, 'days');
    const newSelectedWeekDayNb = newStartDate.weekday() - 1;

    setStartDateMoment(newStartDate);
    setSelectedWeekDay(newSelectedWeekDayNb);
    setRecurrenceType(FREQUENCY.NONE);
    setWeekDays([newSelectedWeekDayNb]);
    setRecurrenceOptions(getDefaultRecurrenceOptions(newStartDate, newSelectedWeekDayNb));
  }, [props.startDate]);

  React.useEffect(() => {
    if (props.toEdit && !isEmpty(defaultRcurrenceOption)) {
      // props.defaultRcurrenceOption.isCustom
      const selectedRecurreneType = defaultRcurrenceOption.isCustom ? CUSTOM_BUILT_R_RULE : defaultRcurrenceOption.frequency;
      if (selectedRecurreneType === CUSTOM_BUILT_R_RULE) {
        const defaultStartMoment = moment(props.startDate);

        const frequencyObj = getCustomFrequencyOptions(defaultRcurrenceOption.recurrenceInterval || 1).find(x => x.id === defaultRcurrenceOption.frequency);
        let text = `Every ${defaultRcurrenceOption.recurrenceInterval} ${frequencyObj.text}`;

        switch (defaultRcurrenceOption.frequency) {
          case FREQUENCY.WEEKLY:
            let index = 0;
            text += weekDaysOptions.reduce((prevVal, currentObj) => {
              if (defaultRcurrenceOption.daysToRepeatOn.includes(currentObj.apiId)) {
                prevVal += `${currentObj.text} ${index < defaultRcurrenceOption.daysToRepeatOn.length - 1 ? ',' : ''}`;
                index++;
              }
              return prevVal;
            }, ', on ')
            break;
          case FREQUENCY.MONTHLY:
            const selectedMonthOpt = getMonthsOptions(defaultStartMoment)
              .find(item => item.id === (defaultRcurrenceOption.nth ? MONTH_TYPE_WEEKDAY : MONTH_TYPE_DAY));
            text += `, on ${selectedMonthOpt.subText}`;
            break;
          default:
        }

        if (defaultRcurrenceOption.nbRecurrence > 1) {
          text += `, ${defaultRcurrenceOption.nbRecurrence} times`
        } else {
          text += `, until ${moment(defaultRcurrenceOption.until).format(RECURRENCE_DATE_FORMAT)}`
        }

        // No need to check if CUSTOM_BUILT_R_RULE exist
        setRecurrenceOptions([
          ...getDefaultRecurrenceOptions(defaultStartMoment, defaultStartMoment.weekday() - 1),
          {
            id: CUSTOM_BUILT_R_RULE,
            text: text,
            // unnecessary to re-built
            // if updated it will be removed from recurrence-type-options
            rrule: {}
          }
        ]);
      }
      setRecurrenceType(selectedRecurreneType);
    }
  }, [props.toEdit, defaultRcurrenceOption, props.startDate])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomDone = () => {
    const frequencyObj = getCustomFrequencyOptions(interval).find(x => x.id === frequency);
    let text = `Every ${interval} ${frequencyObj.text}`;
    let rrule = {
      frequency: frequency,
      interval: interval,
      isCustom: true
    }

    switch (frequency) {
      case FREQUENCY.DAILY:
        rrule = { ...rrule, byweekday: [WEEKDAY.MO, WEEKDAY.TU, WEEKDAY.WE, WEEKDAY.TH, WEEKDAY.FR] }
        break;
      case FREQUENCY.WEEKLY:
        let i = 0;
        const weekdaysText = weekDaysOptions.reduce((prevVal, currentObj) => {
          if (weekDays.includes(currentObj.id)) {
            prevVal += `${currentObj.text} ${i < weekDays.length - 1 ? ',' : ''}`;
            i++;
          }
          return prevVal;
        }, ', on ')

        text += `${weekdaysText}`;
        rrule = { ...rrule, byweekday: weekDays }
        break;
      case FREQUENCY.MONTHLY:
        const selectedMonthOpt = getMonthsOptions(startDateMoment)
          .find(item => item.id === monthType);
        text += `, on ${selectedMonthOpt.subText}`;
        rrule = { ...rrule, ...selectedMonthOpt.rrule }
        break;
      default:
    }

    switch (endType) {
      case END_TYPE_DATE:
        rrule = { ...rrule, until: moment(endDate).format(RECURRENCE_DATE_FORMAT) }
        text += `, until ${endDate}`
        break;
      case END_TYPE_OCCURENCES:
        rrule = { ...rrule, occurences }
        text += `, ${occurences} time${occurences > 1 ? 's' : ''}`
        break;
      default:
    }

    let newRecurrenceOptions = [...recurrenceOptions];
    const index = newRecurrenceOptions.findIndex(el => el.id === CUSTOM_BUILT_R_RULE);
    if (index !== -1) {
      newRecurrenceOptions[index] = {
        id: CUSTOM_BUILT_R_RULE,
        text,
        rrule
      }
    } else {
      newRecurrenceOptions.push({
        id: CUSTOM_BUILT_R_RULE,
        text,
        rrule
      })
    }
    setRecurrenceOptions(newRecurrenceOptions);
    setRecurrenceType(CUSTOM_BUILT_R_RULE);
    props.setRrule(rrule);
    setOpen(false);
  }

  const handleOnRecurrenceTypeChange = (e) => {
    setRecurrenceType(e.target.value);
    // ![CUSTOM_BUILT_R_RULE].includes(e.target.value) &&
    props.setRrule(JSON.parse(e.currentTarget.dataset.rrule));
    if (e.target.value !== CUSTOM_BUILT_R_RULE) {
      let newRecurrenceOptions = [...recurrenceOptions];
      const customBuiltIndex = newRecurrenceOptions.findIndex(x => x.id === CUSTOM_BUILT_R_RULE);
      if(customBuiltIndex !== -1) {
        newRecurrenceOptions.splice(customBuiltIndex, 1);
      }
      setRecurrenceOptions(newRecurrenceOptions);
    }
  }

  return <div>
    <Select
      labelId="recurrence-select"
      id="recurrence-select"
      name="recurrence-select"
      variant="outlined"
      value={recurrenceType}
      onChange={e => e.target.value !== CUSTOM_R_RULE && handleOnRecurrenceTypeChange(e)}
      label=""
      style={{ maxWidth: '18.75rem' }}
    >
      {
        recurrenceOptions.map(recurrenceOption =>
          <MenuItem
            key={recurrenceOption.id}
            value={recurrenceOption.id}
            data-rrule={JSON.stringify(recurrenceOption.rrule)}>{recurrenceOption.text}</MenuItem>
        )
      }
      <MenuItem
        key={CUSTOM_R_RULE}
        value={CUSTOM_R_RULE}
        data-rrule='{}'
        onClick={handleClickOpen}
      >Custom...</MenuItem>
    </Select>
    <Dialog disableEnforceFocus open={open} onClose={handleClose}>
      <DialogTitle>Custom recurrence</DialogTitle>
      <DialogContent style={{ width: '24rem' }}>
        <div className="custom-recurrence-frequency__container">
          <span className="custom-recurrence__label">
            Repeat every
          </span>
          <NumericInput
            id="interval"
            name="interval"
            variant="outlined"
            className="interval"
            min={1}
            value={interval}
            onChange={val => setInterval(parseInt(val))}
            style={{ width: '3.75rem' }}
          />
          <DefaultSelect
            id="frequencey"
            name="frequencey"
            itemKey="id"
            itemValue="id"
            itemName="text"
            variant="outlined"
            className="frequency_type"
            value={frequency}
            onChange={e => setFrequency(e.target.value)}
            data={getCustomFrequencyOptions(interval)}
          />
        </div>
        <div className="custom-recurrence-weekday__container">
          {
            frequency === FREQUENCY.WEEKLY &&
            <>
              <span className="custom-recurrence__label">Repeat on</span>
              <WeekDayToggle
                id="weekday"
                name="weekday"
                className="weekday"
                values={weekDays}
                data={weekDaysOptions}
                onChange={setWeekDays}
              />
            </>
          }
          {
            frequency === FREQUENCY.MONTHLY &&
            <>
              <DefaultSelect
                id="frequencey"
                name="frequencey"
                itemKey="id"
                itemValue="id"
                itemName="text"
                variant="outlined"
                className="frequency_type"
                value={monthType}
                onChange={e => setMonthType(e.target.value)}
                data={getMonthsOptions(startDateMoment)}
              />
            </>
          }
        </div>
        <div className="custom-recurrence-end-limit__container">
          <span className="custom-recurrence__label">Ends</span>
          <RadioGroupRecurrenceLimit
            id="recurrence-limit"
            name="recurrence-limit"
            value={endType}
            onChange={setEndType}
            data={[{
              id: END_TYPE_NEVER,
              value: END_TYPE_NEVER,
              text: 'Never',
              component: null
            },
            {
              id: END_TYPE_DATE,
              value: END_TYPE_DATE,
              text: 'On ',
              component: <DatePickerForm
                label=""
                id="endDate"
                name="endDate"
                className="radio-content"
                disabled={false}
                defaultValue={endDate}
                onChangeData={(date) => setEndDate(date)}
              />
            },
            {
              id: END_TYPE_OCCURENCES,
              value: END_TYPE_OCCURENCES,
              text: 'After',
              component: <>
                <NumericInput
                  id="occurences"
                  name="occurences"
                  variant="outlined"
                  className="radio-content"
                  min={1}
                  value={occurences}
                  onChange={val => setOccurences(parseInt(val))}
                  style={{ width: '3.75rem' }}
                />
                occurences
              </>
            }
            ]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCustomDone} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  </div>;
};

export default RecurrenceSelect;
