import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import authReducer from './reducers/authReducer';
import appReducer from './reducers/appReducer';
import userReducer from './reducers/userReducer';
import projectReducer from './reducers/projectReducer';
import affectationReducer from './reducers/affectationReducer';
import dayoffReducer from './reducers/dayoffReducer';
import resetPwdReducer from './reducers/resetPwdReducer';
import ganttproReducer from './reducers/ganttproReducer';
import serviceReducer from './reducers/serviceReducer';
import uiUtilsReducer from './reducers/uiUtilsReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Working with a v > 5.x.x might cause browser history issues.
 * @see https://github.com/ReactTraining/history/issues/804
 */
const history = createBrowserHistory();

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    user: userReducer,
    project: projectReducer,
    affecta: affectationReducer,
    dayoff: dayoffReducer,
    resetPwd: resetPwdReducer,
    service: serviceReducer,
    uiUtils: uiUtilsReducer,
    // gantt pro reducer
    ganttpro: ganttproReducer,
    // react-router
    router: connectRouter(history),
});

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(routerMiddleware(history), thunk)
    )
);

export default store;
export { history };
