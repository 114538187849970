
import React from 'react';
import { HashLoader } from 'react-spinners';

import './HashLoader.scss';

export default function AppHashLoader() {
  return (
    <div className="spinner">
      <center>
        <HashLoader size="300px" />
      </center>
    </div>
  );
}
