import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, FormControlLabel, Checkbox } from '@material-ui/core';

import { updateObject, checkValidity } from '../../shared/utility';
import AppHashLoader from '../../components/UI/Loader/HashLoader/AppHashLoader.jsx';
import * as actions from '../../store/actions/index';

import './Auth.scss';

class Auth extends Component {
    state = {
        controls: {
            username: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            },
            rememberMe: false,
        }
    }

    inputChangeHandler = (event) => {
        const { name, value } = event.target;
        const controls = updateObject(this.state.controls, {
            [name]: updateObject(this.state.controls[name], {
                value: value,
                valid: checkValidity(value, this.state.controls[name].validation),
                touched: true
            })
        });

        this.setState({ controls });
    }

    handleRemeberMeOnChange = () => {
        this.setState({
            controls: updateObject(this.state.controls, {
                rememberMe: !this.state.controls.rememberMe,
            }),
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        const { username, password, rememberMe } = this.state.controls;
        this.props.onAuth(
            {
                "username": username.value,
                "password": password.value
            },
            rememberMe
        );
    }

    render() {
        const isSubmitDisabled = !(
            this.state.controls.username.valid &&
            this.state.controls.password.valid
        ) || this.props.loading;

        return (
            <div className="col-sm-4 container" style={{ justifyContent: 'center', marginTop: '80px' }}>
                {this.props.loading ?
                    <center>
                        <h2>
                            Loading ....
                        </h2>
                    </center> :
                    <>
                        <h1>
                            <center>Welcome To CosaVostra Planning Tool</center>
                        </h1>
                        <h5 style={{ textAlign: 'center' }}>
                            Planning Tool is an internal software to schedule shifts between different resources in CosaVostra
                        </h5>
                        <div className="welcome">
                            <center>
                                <span>
                                    Made with <span role="img" aria-label="heart">❤️</span> by CosaVostra
                                </span>
                            </center>
                        </div>
                    </>
                }
                <br />
                <Divider />
                <br />
                {this.props.loading ?
                    <AppHashLoader />:
                    <form onSubmit={this.submitHandler}>
                        <div>
                            <h3>Sign In</h3>

                            <div className="form-group">
                                <label>Email address</label>
                                <input
                                    name="username"
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter email"
                                    onChange={(event) => this.inputChangeHandler(event)}
                                />
                                <div style={{ fontSize: 12, color: "red" }}>
                                    {!this.state.controls.username.valid &&
                                        this.state.controls.username.touched &&
                                        "invalid Email"
                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    onChange={this.inputChangeHandler}
                                />
                                <div style={{ fontSize: 12, color: "red" }}>
                                    {!this.state.controls.password.valid &&
                                        this.state.controls.password.touched &&
                                        "invalid Password"
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div  >
                                <FormControlLabel
                                    label="Remember Me"
                                    control={
                                        <Checkbox
                                            name="remember-me"
                                            color="primary"
                                            onChange={this.handleRemeberMeOnChange}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <center>
                            <button
                                type="submit"
                                className="siginButton"
                                disabled={isSubmitDisabled}
                                style={{ cursor: isSubmitDisabled ? 'not-allowed' : 'pointer' }}
                            >
                                Login
                            </button>
                        </center>
                        <p className="forgot-password text-right">
                            <Link to="/resetting/request">Forgot Password?</Link>
                        </p>
                    </form>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (data, rememberMe) => dispatch(actions.auth(data, rememberMe)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
