import * as actionTypes from './actions-types';
import { projectService } from '../../services/projectService';
import {
    HYDRA_MEMBER,
    HYDRA_TOTAL_ITEMS
} from '../../services/utils/hydras';

///  for projects fetching
export const fetchProjectSuccess = (projects, total) => {
    return {
        type: actionTypes.FETCH_PROJECTS_SUCCESS,
        projects: projects,
        total: total
    };
};

export const fetchProjectFail = (error) => {
    return {
        type: actionTypes.FETCH_PROJECTS_FAIL,
        error: error
    };
};

export const fetchProjectStart = () => {
    return {
        type: actionTypes.FETCH_PROJECTS_START
    };
};

export const fetchProjects = (queryParams) => {
    return dispatch => {
        dispatch(fetchProjectStart());
        projectService.getProjects(queryParams)
            .then(res => dispatch(fetchProjectSuccess(res.data[HYDRA_MEMBER], res.data[HYDRA_TOTAL_ITEMS])))
            .catch(err => dispatch(fetchProjectFail(err)));
    }
}
