import * as actionTypes from './actions-types';

import { userService } from '../../services/userService';
import SnackbarUtils from '../../shared/SnackbarUtils';

export const resetPwdEmailSuccess = (resetToken = null) => {
    return {
        type: actionTypes.RESET_PWD_MAIL_SUCCESS,
        resetToken: resetToken,
    };
};

export const resetPwdEmailFail = (error) => {
    return {
        type: actionTypes.RESET_PWD_MAIL_FAIL,
        error: error
    };
};

export const resetPwdEmailStart = () => {
    return {
        type: actionTypes.RESET_PWD_MAIL_START
    };
};

export const resetPwdEmail = (data, token = null) => {
    return dispatch => {
        dispatch(resetPwdEmailStart());
        userService.resetPasswordEmail(data)
            .then(res => {
                if (res.status === 200) {
                    const resetToken = token !== null ? res.data.token : null;
                    dispatch(resetPwdEmailSuccess(resetToken));
                    if (token === null) {
                        SnackbarUtils.info('To change your password, Check your email box');
                    }
                }
            })
            .catch(err => dispatch(resetPwdEmailFail(err)));
    }
}

export const resetPwd = (data) => {
    return dispatch => {
        dispatch(resetPwdEmailStart());
        userService.resetPassword(data)
            .then(res => {
                if (res.status === 200) {
                    dispatch(resetPwdEmailSuccess());
                    SnackbarUtils.info('Password updated successfully');
                }
            })
            .catch(err => dispatch(resetPwdEmailFail(err)));
    }
}
