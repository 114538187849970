import { API_RESOURCES, axiosInstance } from './utils/apiResources';
import { buildFilters } from './utils/filters';

export const affectationService = {
    getAffectations,
    getAffectation,
    createAffectation,
    updateAffectation,
    deleteAffectation,
    copyLastWeekAffectations,
    bulkDeleteAffectations,
    reviewRequests,
    updateRecurrentAffectation,
    limitRecurrentAffectation,
    deleteRecurrentAffectationAndFollowing,
    getTrafficStats,
}

function getAffectations(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.AFFECTATIONS}${buildFilters(queryParams)}`
    );
}

function getAffectation(id) {
    return axiosInstance.get(
        `${API_RESOURCES.AFFECTATIONS}/${id}`,
    );
}

function createAffectation(data) {
    return axiosInstance.post(
      `${API_RESOURCES.AFFECTATIONS}/assign_to_resources`,
        data
    );
}

function updateAffectation(id, data) {
    return axiosInstance.put(
        `${API_RESOURCES.AFFECTATIONS}/${id}`,
        data
    );
}

function deleteAffectation(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.AFFECTATIONS}/${id}`
    );
}

function copyLastWeekAffectations(data) {
    return axiosInstance.post(
        `${API_RESOURCES.AFFECTATIONS}/previous_week`,
        data
    );
}

function bulkDeleteAffectations(data) {
    return axiosInstance.post(
        `${API_RESOURCES.AFFECTATIONS}/bulk_delete`,
        data
    );
}

function reviewRequests(data, toApprove = true) {
    return axiosInstance.put(
        `${API_RESOURCES.AFFECTATIONS}/review_requests?toApprove=${toApprove}`,
        data
    );
}

function updateRecurrentAffectation(id, data, queryParams = {}) {
    return axiosInstance.put(
        `${API_RESOURCES.AFFECTATIONS}/${id}/update_rrule${buildFilters(queryParams)}`,
        data
    );
}

function limitRecurrentAffectation(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.AFFECTATIONS}/${id}/does_not_repeat`
    );
}

function deleteRecurrentAffectationAndFollowing(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.AFFECTATIONS}/${id}/this_and_following_affectations`
    );
}

function getTrafficStats() {
    return axiosInstance.get('/affectations_stats');
}
