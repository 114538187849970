import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const WeekDayToggle = props => {
    return <ToggleButtonGroup
        size="small"
        id={props.id}
        name={props.name}
        value={props.values}
        onChange={(_e, values) => props.onChange(values)}>
        {props.data.map(weekday => <ToggleButton
            style={{
                backgroundColor: props.values.includes(weekday.id) ? '#1a73e8' : '#e0e0e0',
                color: props.values.includes(weekday.id) ? '#ffffff' : 'inherit'
            }}
            className='week_span'
            key={weekday.id}
            value={weekday.id}
            size='small'
            color='primary'
            disabled={props.values.length === 1 && props.values[0] === weekday.id}
        >
            {weekday.text.substring(0, 2).toUpperCase()}
        </ToggleButton>)}
    </ToggleButtonGroup>
};

export default WeekDayToggle
