import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
    makeStyles,
    useTheme,
    Avatar,
    Drawer,
    AppBar,
    Toolbar,
    List,
    CssBaseline,
    Divider,
    IconButton,
} from '@material-ui/core';
import {
    ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
    ArrowBackIosOutlined as ArrowBackIosOutlinedIcon,
    PeopleOutlineRounded as PeopleOutlineRoundedIcon,
    ExitToAppRounded as ExitToAppRoundedIcon,
    FolderOpenRounded as FolderOpenRoundedIcon,
    Dashboard as DashboardIcon,
    EventAvailable as EventAvailableIcon,
    TimelineRounded as TimelineRoundedIcon,
    TuneRounded as TuneRoundedIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import NavigationItemDrawer from './NavigationItem/NavigationItemDrawer';
import { toTitleCase } from '../../../shared/utility';
import { ROLE } from '../../../shared/authUtility';
import * as actions from '../../../store/actions/index';

// scss global colors
import variables from '../../../assets/Styles/variables.module.scss';

const drawerWidth = 240;

const iconStyling = {
    width: '35px',
    height: '35px',
    color: '#dad4d4'
}

const avatarStyle = {
    height: '40px',
    width: '40px',
    backgroundColor: '#dad4d4',
    color: '#0a0606',
    marginLeft: '-5%',
};

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        "@media (max-width: 600px)": {
            width: 12,
        },
    },
    appBar: {
        top: 0,
        left: -6,
        width: 78,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

        "@media (max-width: 600px)": {
            width: 64,
        },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: 0,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        backgroundColor: variables.mainColor,
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        backgroundColor: variables.mainColor,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
        "@media (max-width: 600px)": {
            visibility: 'hidden',
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    toolbarText: {
        color: '#dad4d4',
        top: '8%',
        position: 'relative',
        right: '5%',
    }
}));

// set the active tab when refreshing the page
const getDefaultActiveItemVal = () => {
    switch (window.location.pathname) {
        case '/profile': return 1;
        case '/dashboard': return 2;
        case '/users': return 3;
        case '/projects': return 4;
        case '/reports': return 5;
        case '/settings': return 6;
        case '/loadPlan': return 7;

        default: return 2;
    }
}

function DrawerMaterialUi(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [activeItem, setActiveItem] = React.useState(getDefaultActiveItemVal);

    const handleDrawerOpen = () => {
        setOpen(true);
        props.onDrawerChangeState(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        props.onDrawerChangeState(false);
    };

    const handleActiveItem = (val) => {
        setActiveItem(val);
    };

    // Drawer divider
    const dividerLine = (
        <Divider
            variant="middle"
            style={{ backgroundColor: '#dad4d4' }}
        />
    );

    return (
        <div className={classes.drawerContainer}>
            <CssBaseline />
            <AppBar
                style={{ backgroundColor: variables.mainColor }}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <ArrowForwardIosRoundedIcon style={iconStyling} />
                    </IconButton>

                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <h5 className={classes.toolbarText} >Planning Tool</h5>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ArrowForwardIosRoundedIcon style={iconStyling} /> : <ArrowBackIosOutlinedIcon style={iconStyling} />}
                    </IconButton>
                </div>
                <Divider style={{ backgroundColor: '#dad4d4' }} />
                <List className={classes.itemList}>
                    <NavigationItemDrawer
                        activeVal={2}
                        activeItem={activeItem}
                        handleActive={() => handleActiveItem(2)}
                        to="/dashboard"
                        primary="Dashboard"
                        icon={<DashboardIcon style={iconStyling} />}
                    />
                    <NavigationItemDrawer
                        activeVal={3}
                        activeItem={activeItem}
                        handleActive={() => handleActiveItem(3)}
                        to="/users"
                        primary="Users"
                        icon={<PeopleOutlineRoundedIcon style={iconStyling} />}
                    />
                    <NavigationItemDrawer
                        activeVal={4}
                        activeItem={activeItem}
                        handleActive={() => handleActiveItem(4)}
                        to="/projects"
                        primary="Projects"
                        icon={<FolderOpenRoundedIcon style={iconStyling} />}
                    />
                    <NavigationItemDrawer
                        activeVal={7}
                        activeItem={activeItem}
                        handleActive={() => handleActiveItem(7)}
                        to="/loadPlan"
                        primary="Load Plan"
                        icon={<EventAvailableIcon style={iconStyling} />}
                    />
                    {props.loggedUserRole !== ROLE.RESOURCE &&
                        <NavigationItemDrawer
                            activeVal={5}
                            activeItem={activeItem}
                            drawerOpen={open}
                            handleActive={() => handleActiveItem(5)}
                            to="/reports"
                            primary="Reports"
                            icon={<TimelineRoundedIcon style={iconStyling} />}
                        />
                    }
                    <br />
                    {dividerLine}
                    <br />
                    {props.loggedUserRole === ROLE.ADMIN &&
                        <>
                            <br />
                            <>
                                <NavigationItemDrawer
                                    activeVal={6}
                                    activeItem={activeItem}
                                    drawerOpen={open}
                                    handleActive={() => handleActiveItem(6)}
                                    to="/settings"
                                    primary="Settings"
                                    icon={<TuneRoundedIcon style={iconStyling} />}
                                />
                            </>
                        </>
                    }
                    <NavigationItemDrawer
                        activeVal={1}
                        activeItem={activeItem}
                        handleActive={() => handleActiveItem(1)}
                        to="/profile"
                        primary={!props.loading ? props.loggedUserFullName : ''}
                        icon={!props.loading ?
                            <Avatar style={avatarStyle}>
                                {toTitleCase(props.loggedUserFullName)}
                            </Avatar> :
                            <Skeleton
                                variant="circle"
                                style={avatarStyle}
                            />
                        }
                    />
                    <br />
                    {dividerLine}
                    <br />
                    <NavigationItemDrawer
                        activeVal={10}
                        activeItem={activeItem}
                        handleActive={() => handleActiveItem(10)}
                        to="/logout"
                        primary="Logout"
                        icon={<ExitToAppRoundedIcon style={iconStyling} />}
                    />
                </List>
            </Drawer>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onDrawerChangeState: (open) => dispatch(actions.setDrawerState(open)),
    };
};

const mapStateToProps = state => {
    return {
        roles: state.auth.role,
        loggedUserFullName: state.app.user?.fullName,
        loggedUserRole: state.app.user.roles,
        loading: state.app.loading,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DrawerMaterialUi);
