import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

// loading is for the spinner loading ==> beautify the app a little
const initialState = {
    token: localStorage.getItem('token'),
    error: null,
    loading: false,
    authRedirectPath: '/',
    sideDrawerOpen: false,
}

// redirection action not introduced yet in some component
const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path });
}
// authentication actions {start, success, fail, logout}
const authStart = (state, _action) => {
    return updateObject(state, {error: null, loading: true});
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state, _action) => {
    return updateObject(state, {
        token: null,
    });
}

// with return ~~~~ implmentation + break
const authReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // login
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        // logout
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        // auth redirection
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);

        default: return state;
    }
}

export default authReducer;
