import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";
import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ResourceGrid from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';

import * as actions from '../../store/actions/index';
import {
    updateObject,
    transformDaySlot,
    checkedOfficesData,
    transformEndDate,
    hasDigits,
    removeDigitsFromString,
    DATE_TIME_FORMAT,
} from '../../shared/utility';
import ModalUIAdd from '../../components/UI/Modal/ModalUIAdd/ModalUIAdd';
import ModalUIViewAffectation from '../../components/UI/Modal/ModalUIViewAffectation/ModalUIViewAffectation';
import MenuItems from '../../components/UI/MenuItems/MenuItems';
import ModalUIViewDayOff from '../../components/UI/Modal/ModalUIViewDayOff/ModalUIViewDayOff';
import RecurrenceTargetEventModal from '../../components/UI/Select/Recurrence/RecurrenceTargetEventModal';
import { API_RESOURCES, API_URL } from '../../services/utils/apiResources';
import { AFFECTATION_FILTERS, RECURRENT_RESOURCES_FILTERS } from '../../services/utils/filters';
import { TARGET_EVENT } from '../../shared/rRule/rrule';
import {
    EVENT_DAY_OFF_PREFIX,
    generateEventDurationText,
    getEndWorkHour,
    getRenderingEventColor,
    getStartWorkHour,
    isHalfDaySlot,
    updateEventInFullCalendar
} from '../../shared/fullCalendarUtility';
import { halfDayType } from '../../shared/dayOffUtility';
import { canCreateAffectation,
    canCUDDayOff,
    isAdmin,
    getLoggedUserId
} from '../../shared/authorizationUtility';

// fullCalendar styles properties
import colors from "../../containers/Dashboard/dashboard.module.scss";
import './FullCalendarResource.scss';

const checkOfficeName = (officesToLoop, officename) => officesToLoop.map(office => ({
    ...office,
    checked: office.label === officename,
}));

const UPDATE_TYPE_DROP = 'drop';
const UPDATE_TYPE_RESIZE = 'resize';

const ExternalDayOffSpan = ReactDOMServer.renderToString(
    <span className="external-day-off">
        <strong>🔗 PayFit / <br /> &nbsp;&nbsp; Tunis-RH</strong>
    </span>
);

const holidayDayOffSpan = ReactDOMServer.renderToString(
    <span className="external-day-off">
        <strong>🔗 Holiday</strong>
    </span>
);

/**
 * @see https://github.com/jakubroztocil/rrule
 * @see https://fullcalendar.io/docs/v4/recurring-events
 */
class FullCalendarResource extends Component {
    constructor(props) {
        super(props);
        this.calendarApi = null;
        this.dayOffModalRef = React.createRef();
        this.eventTargetModalRef = React.createRef();
        this.state = {
            addAffectInitialData: {
                project: null,
                user: null,
                daySlot: 0.5,
                date: moment((new Date()).setHours(0, 0, 0, 0)).format(DATE_TIME_FORMAT),
                priority: 'Low',
                notes: '',
                startTime: '',
                endTime: '',
                daySlotOrHourly: 0
            },
            viewAffectationData: {
                idAffectation: '',
                project: null,
                isBidProject: false,
                user: null,
                title: '',
                isConfirmed: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                daySlotOrHourly: 0,
                priority: '',
                notes: '',
                projectManagerId: null,
                projectManagerName: '',
                createdById: null,
                createdByName: '',
                rRule: {},
            },
            addDayOffInitialData: {
                selectedDateRange: [null, null],
                halfDayStart: halfDayType.HALF_DAY,
                halfDayEnd: halfDayType.HALF_DAY,
                userdayoff: null,
                offices: null
            },
            viewDayOffData: {
                id: '',
                startDateTime: '',
                endDateTime: '',
                typeDayOff: '',
                office: [],
                userDayOff: null,
                rRule: {},
            },
            editType: null,
            eventToDragOrResize: {
                event: null,
                start: null,
                end: null,
            },
            eventBeforeDragOrResize: {
                id: null,
                resourceId: null,
                start: null,
                end: null,
            }
        }
    }

    componentDidMount() {
        this.calendarApi = this.props.calendarRef.current.getApi();
    }

    setInitialFormDataViaCellClick = (resourceId, clickedDate) => {
        const dateMoment = moment(clickedDate);
        const startHour = getStartWorkHour(dateMoment);
        const endHour = startHour + 1;

        const updatedElements = updateObject(this.state.addAffectInitialData, {
            user: resourceId,
            date: dateMoment.format(DATE_TIME_FORMAT),
            daySlot: 0.5,
            startTime: dateMoment.set('hours', startHour).format(DATE_TIME_FORMAT),
            endTime: dateMoment.set('hours', endHour).format(DATE_TIME_FORMAT),
            // Cell has slot is set by hour
            daySlotOrHourly: 1
        });

        this.setState({ addAffectInitialData: updatedElements });
    }

    setDayOffsFormDataViaCellClick = (resource, date) => {
        const offices = [...checkedOfficesData];
        let offices1 = [];

        if (resource.extendedProps.isFictive) {
            offices1 = offices.map(office => ({
                ...office,
                checked: false,
            }));
        } else {
            const officename = resource.extendedProps.office;
            offices1 = checkOfficeName(offices, officename);
        }

        const updatedElements = updateObject(this.state.addDayOffInitialData, {
            selectedDateRange: [moment(date).format(DATE_TIME_FORMAT),
            transformEndDate(moment(date).format(DATE_TIME_FORMAT), 0.5)],
            halfDayStart: halfDayType.HALF_DAY,
            halfDayEnd: halfDayType.HALF_DAY,
            userdayoff: resource.id,
            offices: [...offices1],
            actionType: 'cellClick'
        });

        this.setState({ addDayOffInitialData: updatedElements })
    }

    setInitialFormDataSelectMul = (resourceId, startSelectDate, endSelectDate) => {
        const startDateMoment = moment(startSelectDate);
        const endDateMoment = moment(endSelectDate);
        const startHour = getStartWorkHour(startDateMoment);
        const endHour = getEndWorkHour(endDateMoment);

        const updatedElements = updateObject(this.state.addAffectInitialData, {
            user: resourceId,
            date: startDateMoment.format(DATE_TIME_FORMAT),
            daySlot: transformDaySlot(startSelectDate, endSelectDate),
            startTime: startDateMoment.set('hour', startHour).format(DATE_TIME_FORMAT),
            endTime: endDateMoment.set('hour', endHour).format(DATE_TIME_FORMAT),
            daySlotOrHourly: isHalfDaySlot(startHour, endHour) ? 0 : 1,
        });

        this.setState({ addAffectInitialData: updatedElements });
    }

    setDayOffsFormDataSelectMul = (resource, startSelectDate, endSelectDate) => {
        const offices = [...checkedOfficesData];
        let offices1 = [];

        if (resource.extendedProps.isFictive) {
            offices1 = offices.map(office => ({
                ...office,
                checked: false,
            }));
        } else {
            const officename = resource.extendedProps.office;
            offices1 = checkOfficeName(offices, officename);
        }

        const updatedElements = updateObject(this.state.addDayOffInitialData, {
            selectedDateRange: [moment(startSelectDate).format(DATE_TIME_FORMAT),
            moment(endSelectDate).format(DATE_TIME_FORMAT)],
            halfDayStart: moment(startSelectDate).get('hour') === 12 ? halfDayType.HALF_DAY : halfDayType.ALL_DAY,
            halfDayEnd: moment(endSelectDate).get('hour') === 12 ? halfDayType.HALF_DAY : halfDayType.ALL_DAY,
            userdayoff: resource.id,
            offices: [...offices1],
            actionType: 'multiSelect'
        });

        this.setState({ addDayOffInitialData: updatedElements })
    }

    setViewAffectationData = (event, startDate, endDate) => {
        const startDateMoment = moment(startDate);
        const endDateMoment = moment(endDate);
        const startHour = getStartWorkHour(startDateMoment);
        const endHour = getEndWorkHour(endDateMoment);

        const updatedElements = updateObject(this.state.viewAffectationData, {
            idAffectation: event.publicId,
            project: event.extendedProps.projectId,
            isBidProject: event.extendedProps.isBidProject,
            user: event.resourceIds[0],
            title: event.title,
            isConfirmed: event.extendedProps.isConfirmed,
            startDate: startDateMoment.format(DATE_TIME_FORMAT),
            endDate: endDateMoment.format(DATE_TIME_FORMAT),
            startTime: startDateMoment.set('hour', startHour).format(DATE_TIME_FORMAT),
            endTime: endDateMoment.set('hour', endHour).format(DATE_TIME_FORMAT),
            daySlotOrHourly: isHalfDaySlot(startHour, endHour) ? 0 : 1,
            priority: event.extendedProps.exigencyLevel,
            notes: event.extendedProps.notes,
            projectManagerId: event.extendedProps.projectManagerId,
            projectManagerName: event.extendedProps.projectManagerName,
            createdById: event.extendedProps.createdById,
            createdByName: event.extendedProps.createdByName,
            rRule: event.extendedProps.recurrenceRule || {},
        });

        this.setState({ viewAffectationData: updatedElements });
    }

    setViewDayOffData = (event, start, end) => {
        const offices = event.extendedProps.office ? checkedOfficesData.map(officeObj => ({
            ...officeObj,
            checked: event.extendedProps.office.includes(officeObj.label)
        })) : []

        const updatedElements = updateObject(this.state.viewDayOffData, {
            // 1st char has a `d` prefix
            id: event.publicId.replace(EVENT_DAY_OFF_PREFIX, ''),
            startDateTime: start,
            endDateTime: end,
            typeDayOff: event.title,
            office: offices,
            userDayOff: event.title === 'Holiday' ? null : parseInt(event.resourceIds[0]),
            rRule: event.extendedProps.recurrenceRule || {},
        });
        this.setState({ viewDayOffData: updatedElements })
    }

    // for affectation add
    onClick(handleClickOpen) {
        this.handleClickOpen = handleClickOpen;
    }

    onClickForView(handleClickOpenView) {
        this.handleClickOpenView = handleClickOpenView;
    }

    // drag + drop + resize handlers
    openRecurrenceTargetModal = (eventToDragOrResize, editType = null) => {
        this.setState({
            editType,
            eventToDragOrResize
        });
        this.eventTargetModalRef.current.openModal();
    }

    handleResizeAndDropEvents = (event, start, end, editType = UPDATE_TYPE_RESIZE, recurrenceTargetType = null) => {
        const prevUserId = parseInt(this.state.eventBeforeDragOrResize.resourceId);
        const userId = parseInt(event.resourceIds[0]);
        let affectBody = {
            "startDateTime": moment(start).format(DATE_TIME_FORMAT),
            "endDateTime": moment(end).format(DATE_TIME_FORMAT),
        }

        if (editType === UPDATE_TYPE_DROP) {
            affectBody = {
                ...affectBody,
                "assignedUser": `${API_URL}${API_RESOURCES.USERS}/${userId}`,
            }
        }

        // hanle recurrence endpoints
        if (!isEmpty(event.extendedProps.recurrenceRule)) {
            const targetResourcesIds = [
                userId,
                ...(editType === UPDATE_TYPE_DROP && userId !== prevUserId) ?
                    [prevUserId] : []
            ]
            this.props.onEditRecurrentAffectation(
                event.publicId,
                affectBody,
                targetResourcesIds,
                {
                    [RECURRENT_RESOURCES_FILTERS.UPDATE_R_RULE]: false,
                    [RECURRENT_RESOURCES_FILTERS.THIS_AND_THE_FOLLOWING_EVENTS]: recurrenceTargetType?.toString() === TARGET_EVENT.THIS_AND_THE_FOLLOWING
                },
                {
                    ...this.props.affectQueryParams,
                    [AFFECTATION_FILTERS.ASSIGNED_USER_ID_EXACT]: targetResourcesIds
                },
                this.calendarApi
            )
        } else {
            this.props.onEditAffectation(
                event.publicId,
                affectBody,
                this.calendarApi
            );
        }
    }

    setEventDataBeforeDragResize = (event = null) => {
        event && !isEmpty(event?.extendedProps?.recurrenceRule) &&
            this.setState({
                eventBeforeDragOrResize: {
                    id: event?._def?.publicId,
                    resourceId: event?._def?.resourceIds[0],
                    start: moment(event?.start).format(DATE_TIME_FORMAT),
                    end: moment(event?.end).format(DATE_TIME_FORMAT)
                }
            });
    }

    cancelDropResize = () => {
        const { id, resourceId, start, end } = this.state.eventBeforeDragOrResize;
        const event = this.calendarApi.getEventById(id);
        event && updateEventInFullCalendar(
            event,
            {
                start,
                end,
                resourceId
            }
        )
    }

    handlePrevWeekEvents = (id) => {
        const moment1 = moment(this.calendarApi.view.activeStart).subtract(7, "days").format('YYYY-MM-DD');
        const moment2 = moment(this.calendarApi.view.activeEnd).subtract(7, "days").format('YYYY-MM-DD');

        const prevWeekBody = {
            startDateTime: moment1,
            endDateTime: moment2,
            assignedUser: parseInt(id),
        }

        this.props.onPrevWeekAffectation(prevWeekBody, this.calendarApi);
    }

    handleEventMouseEnter = arg => {
        const isBackgroundEvent = arg.event.rendering === 'background';
        const tooltipTag = document.createElement('span');
        const mainTag = isBackgroundEvent ? arg.el : arg.el.querySelector('.fc-title-wrap');

        if (!mainTag) {
            return;
        }

        tooltipTag.classList.add(['tooltipCustomtext']);
        tooltipTag.innerHTML = `
            &nbsp;&nbsp;
            ${isBackgroundEvent ?
                generateEventDurationText(arg.event.id, this.calendarApi) :
                arg.event.title
            }
            &nbsp;&nbsp;
        `;

        // tooltip inherit parent event `EL` z-index
        arg.el.style.zIndex = parseInt(arg.el.style.zIndex) + 3;

        mainTag.classList.add(['tooltipCustom']);
        mainTag.prepend(tooltipTag);
    }

    handleEventMouseLeave = arg => {
        arg.el.style.zIndex = parseInt(arg.el.style.zIndex) - 3;
        arg.el.querySelector('.tooltipCustomtext')?.remove();
        (
            arg.event.rendering === 'background' ?
                arg.el :
                arg.el.querySelector('.fc-title-wrap')
        )?.classList.remove('tooltipCustom');
    }

    handleExigencyLevel = (arg) => {
        if (!arg.event?.extendedProps?.exigencyLevel || arg.event.extendedProps.exigencyLevel.toLowerCase() !== 'high') {
            return;
        }

        arg.el.querySelector('.fc-title').innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">\n' +
          '  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />\n' +
          '</svg>\n &nbsp;' +
          arg.event.title;
   }

    handleEventRender = arg => {
        const { filteredProjects } = this.props;
        const isBackgroundEvent = arg.event.rendering === 'background';

        if (isBackgroundEvent) {
            if (arg.event.extendedProps.isExternal) {
                arg.el.style.zIndex = 3;
                arg.el.innerHTML = ExternalDayOffSpan;
            } else if (arg.event.extendedProps.isOfficeHoliday) {
                arg.el.style.zIndex = 3;
                arg.el.innerHTML = holidayDayOffSpan;
            }
        } else {
            arg.el.style.zIndex = isBackgroundEvent ? 4 : 5;
        }

        if (!isBackgroundEvent) {
            const eventStyle = arg.el.style;
            const { isConfirmed, isBidProject, projectName } = arg.event.extendedProps;

            // If projects filter contains options we rely on re-render to update events color
            const color = (filteredProjects.length === 0 || filteredProjects.includes(projectName)) ?
                getRenderingEventColor(isConfirmed, isBidProject) :
                colors.lightGrey;

            eventStyle.backgroundColor = color;
            eventStyle.borderTopColor = color;
            eventStyle.borderBottomColor = color;

            if (arg.event.durationEditable) {
                const borderStyle = color === colors.lightGrey ? colors.lightGrey : '0.2rem solid #676e7a';
                eventStyle.borderRight = borderStyle;
                eventStyle.borderLeft = borderStyle;
            }
        }

        this.handleExigencyLevel(arg);
    }

    isEventDraggable(calendarEvent) {
        if (isAdmin() || calendarEvent.projectManagerId === getLoggedUserId()) {
            return false;
        }

        return calendarEvent.exigencyLevel.toLowerCase() === 'high';
    }

    handleRecurrences (arg) {
        if (this.isEventDraggable(arg.event.extendedProps)) {
            this.cancelDropResize();
            window.alert('This event is not flexible and can only be edited by its manager or an administrator.');

            return;
        }

        !isEmpty(arg.event.extendedProps.recurrenceRule) ?
          this.openRecurrenceTargetModal(
            {
                event: arg.event._def,
                start: arg.event.start,
                end: arg.event.end,
            },
            UPDATE_TYPE_DROP
          ) : this.handleResizeAndDropEvents(arg.event._def, arg.event.start, arg.event.end, UPDATE_TYPE_DROP)
    }

    render() {
        const { rolesLimits, resources, events } = this.props;
        const { eventToDragOrResize } = this.state;

        return (
            <div>
                <FullCalendar
                    ref={this.props.calendarRef}
                    defaultDate={this.props.dateNav}
                    //  plugin + views (license key)
                    defaultView={window.innerWidth < 600 ? "resourceTimeline" : "resourceTimelineWeek"}
                    plugins={[dayGridPlugin, ResourceGrid, interactionPlugin, rrulePlugin]}
                    schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                    themeSystem="bootstrap"
                    // header + styles + labelTexts
                    header={{
                        left: '',
                        right: ''
                    }}
                    height="auto"
                    resourceLabelText="Resources"
                    columnHeaderText="Day"
                    columnHeaderFormat={{
                        weekday: 'short',
                        month: 'numeric',
                        day: 'numeric',
                        omitCommas: true
                    }}
                    contentHeight="100px"
                    resourceAreaWidth='18%'
                    eventLimit={2}
                    slotLabelInterval='12:00:00'
                    slotLabelFormat={
                        [
                            { weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true },
                            { hour: 'numeric' }
                        ]
                    }
                    slotWidth='2.5%'

                    // Data: resources + events[...affectations. ...dayoffs]
                    events={events}
                    resources={resources}
                    /**
                     * there might be a bug when using resourceGroupField and resourceOrder combined
                     * @see https://github.com/fullcalendar/fullcalendar/issues/5482
                     */
                    resourceOrder='subServiceName,-isFictive,title'
                    // grouping field
                    resourceGroupField="subServiceName"
                    resourceGroupText={(groupField) => hasDigits(groupField) ?
                        removeDigitsFromString(groupField) :
                        groupField
                    }

                    // refecth on every prev/next click ~ disable fullcalendar cache
                    lazyFetching={false}

                    // time display controls
                    hiddenDays={[0, 6]}
                    slotDuration='3:00:00'
                    nowIndicator={true}
                    allDaySlot={true}

                    // depends on the connected user roles
                    selectable={rolesLimits}
                    editable={false}
                    dragScroll={true}
                    // cells possible actions
                    dateClick={(arg) => {
                        if (
                            canCreateAffectation() &&
                            moment(arg.date).isAfter(moment(), 'hour') &&
                            !arg.jsEvent.target.classList.contains(['stripes'])
                        ) {
                            this.setInitialFormDataViaCellClick(arg.resource._resource.id, arg.dateStr);
                            this.setDayOffsFormDataViaCellClick(arg.resource._resource, arg.dateStr);
                            this.handleClickOpen();
                        }
                    }}

                    selectMinDistance={2}
                    select={(arg) => {
                        if (rolesLimits) {
                            this.setInitialFormDataSelectMul(arg.resource._resource.id, arg.startStr, arg.endStr);
                            this.setDayOffsFormDataSelectMul(arg.resource._resource, arg.startStr, arg.endStr);
                            this.handleClickOpen();
                        }
                    }}

                    eventClick={(arg) => {
                        if (arg.event.rendering !== 'background') {
                            this.setViewAffectationData(arg.event._def, arg.event.start, arg.event.end);
                            this.handleClickOpenView();
                        } else if (
                            canCUDDayOff() &&
                            !arg.event.extendedProps.isExternal &&
                            arg.event._def.rendering === 'background'
                        ) {
                            this.setViewDayOffData(arg.event._def, arg.event.start, arg.event.end)
                            this.dayOffModalRef.current.openModal()
                        }
                    }}

                    eventResizeStop={(arg) => this.setEventDataBeforeDragResize(arg?.event)}
                    eventDragStop={(arg) => this.setEventDataBeforeDragResize(arg?.event)}

                    eventDrop={(arg) => this.handleRecurrences(arg)}
                    eventResize={(arg) => !isEmpty(arg.event.extendedProps.recurrenceRule) ?
                        this.openRecurrenceTargetModal(
                            {
                                event: arg.event._def,
                                start: arg.event.start,
                                end: arg.event.end,
                            },
                            UPDATE_TYPE_RESIZE
                        ) : this.handleResizeAndDropEvents(arg.event._def, arg.event.start, arg.event.end, UPDATE_TYPE_RESIZE)
                    }

                    selectOverlap={(arg) => !arg.classNames.includes('stripes')}
                    selectAllow={arg => moment().diff(arg.start) < 0}

                    resourceRender={(arg) => {
                        if (moment(arg.view.activeEnd).isAfter(moment(), 'days') && rolesLimits) {
                            const { id, title, extendedProps } = arg.resource._resource;
                            const menuItems = <MenuItems
                                id={id}
                                title={title}
                                email={extendedProps.email ?? null}
                                isFictive={extendedProps?.isFictive || false}
                                handlePrevWeekEvents={() => this.handlePrevWeekEvents(id)}
                            />;

                            const renderIn = arg.el.querySelector(".fc-cell-text");
                            const divResou = arg.el.querySelector(".fc-cell-content");

                            const activeStartParam = {
                                start: arg.view.activeStart,
                                end: transformEndDate(arg.view.activeStart, 0.5)
                            };
                            // adjust classname for availabilty
                            if (
                                !this.props.loadingAffect &&
                                moment(arg.view.activeStart).diff(moment().startOf('week'), 'days') >= 0
                                && !arg.resource.extendedProps?.isFictive
                            ) {
                                const resourceId = arg.resource._resource.id;
                                const myEvents = arg.resource._calendar.getResourceById(resourceId).getEvents();

                                const availblebeBool = checkAvailability(myEvents, activeStartParam);
                                divResou.classList.add([availblebeBool ? 'not_free_resource' : 'free_resource']);
                            }

                            ReactDOM.render(menuItems, renderIn);
                        }
                    }}

                    eventMouseEnter={this.handleEventMouseEnter}
                    eventMouseLeave={this.handleEventMouseLeave}
                    eventRender={this.handleEventRender}
                />

                <ModalUIAdd
                    currentTeam={this.props.currentTeam}
                    calendarRef={this.props.calendarRef}
                    formData1={this.state.addAffectInitialData}
                    dayOffData1={this.state.addDayOffInitialData}
                    onRef={this.onClick.bind(this)}
                    affectQueryParams={this.props.affectQueryParams}
                    dayOffQueryParams={this.props.dayOffQueryParams}
                />
                <ModalUIViewAffectation
                    currentTeam={this.props.currentTeam}
                    calendarRef={this.props.calendarRef}
                    affectationData={this.state.viewAffectationData}
                    onRef={this.onClickForView.bind(this)}
                    affectQueryParams={this.props.affectQueryParams}
                />
                <ModalUIViewDayOff
                    ref={this.dayOffModalRef}
                    calendarRef={this.props.calendarRef}
                    dayOffObj={this.state.viewDayOffData}
                    dayOffQueryParams={this.props.dayOffQueryParams}
                />
                <RecurrenceTargetEventModal
                    resource="affectation"
                    ref={this.eventTargetModalRef}
                    onDone={recurrenceTargetType => this.handleResizeAndDropEvents(
                        eventToDragOrResize.event,
                        eventToDragOrResize.start,
                        eventToDragOrResize.end,
                        this.state.editType,
                        recurrenceTargetType
                    )}
                    onCancel={() => this.cancelDropResize()}
                />
            </div>
        )
    }
}

// On an hourly basis the calculation isn't accurate, think of an optimised refactor
const checkAvailability = (events, activeStart) => {
    const events1 = [...events];
    let index = 0
    for (index; index < 10; index++) {
        const item = events1.filter(x => (formatter(x.start) <= formatter(activeStart.start)) && (formatter(x.end) >= formatter(activeStart.end)));
        if (item.length === 0) {
            break;
        }
        activeStart.start = transformEndDate(activeStart.start, 0.5);
        activeStart.end = transformEndDate(activeStart.end, 0.5);
    }
    return index === 10;
}

const formatter = date => {
    return moment(date).format(DATE_TIME_FORMAT)
}

const mapStateToProps = state => {
    return {
        // auth redcuer
        token: state.auth.token,
        // projects + users reducers
        users: state.user.users,
        projects: state.project.projects,
        loading: state.user.loading,
        // affectation reducer
        affectations: state.affecta.affectations,
        loadingAffect: state.affecta.loading,
        totalC: state.affecta.totalcount,
        // dayoff reducer
        dayoffLoading: state.dayoff.loading,
        dayoffs: state.dayoff.dayoffs,
        // service reducer,
        services: state.service.services,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditAffectation: (id, affectBody, calendarApi) => dispatch(actions.editAffectation(id, affectBody, calendarApi)),
        onPrevWeekAffectation: (prevWeekBody, calendarApi) => dispatch(actions.prevWeekAffectation(prevWeekBody, calendarApi)),
        onEditRecurrentAffectation: (id, data, resourcesIds, queryParams, affectationQueryParams, calendarApi) =>
            dispatch(actions.editRecurrentAffectation(id, data, resourcesIds, queryParams, affectationQueryParams, calendarApi)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullCalendarResource);
