import * as actionTypes from './actions-types';
import { affectationService } from '../../services/affectationService';
import { AFFECTATION_FILTERS } from '../../services/utils/filters';
import {
    API_RESOURCES,
    API_URL,
    MERCURE_PATTERN,
    MERCURE_TOKEN_KEY
} from '../../services/utils/apiResources';
import { HYDRA_MEMBER } from '../../services/utils/hydras';
import { addEventToFullCalendar, undo, updateEventInFullCalendar } from '../../shared/fullCalendarUtility';
import SnackbarUtils from '../../shared/SnackbarUtils.tsx';

export const emptyAffectationsList = () => {
    return dispatch => dispatch({
        type: actionTypes.RESET_APP_DASHBOARD_AFFECTATIONS,
    });
}

/////////////// for set hub url for affectations
export const setMercureData = (hubUrl, token) => {
    return {
        type: actionTypes.SETUP_MERCURE,
        mercureHubUrl: hubUrl,
        mercureToken: token
    }
}

//////////////  for affectations fetching  //////////////
export const fetchAffectationSuccess = (affectations, total) => {
    return {
        type: actionTypes.FETCH_AFFECTATIONS_SUCCESS,
        affectations: affectations,
        total: total
    };
};

export const fetchAffectationFail = (error) => {
    return {
        type: actionTypes.FETCH_AFFECTATIONS_FAIL,
        error: error
    };
};

export const fetchAffectationStart = () => {
    return {
        type: actionTypes.FETCH_AFFECTATIONS_START
    };
};

export const fetchAffectations = (queryParams = {}) => {
    return dispatch => {
        dispatch(fetchAffectationStart());
        affectationService.getAffectations(queryParams)
            .then(res => {
                if (res.status === 200) {
                    const arrayMercurePattern = MERCURE_PATTERN.exec(res.headers.link);
                    const mercureHubUrl = Array.isArray(arrayMercurePattern) ? arrayMercurePattern[1] : null;

                    dispatch(setMercureData(
                        mercureHubUrl,
                        res.headers[MERCURE_TOKEN_KEY] || ''
                    ));

                    const fecthedAffectations = res.data[HYDRA_MEMBER];
                    dispatch(fetchAffectationSuccess(fecthedAffectations, fecthedAffectations.length));
                }
            })
            .catch(err => dispatch(addAffectationFail(err)));
    }
}

//////////////  for affectation addding   //////////////
export const addAffectationSuccess = () => {
    return {
        type: actionTypes.ADD_AFFECTATION_SUCCESS,
    };
};

export const addAffectationFail = (error) => {
    return {
        type: actionTypes.ADD_AFFECTATION_FAIL,
        error: error
    };
};

export const addAffectationStart = () => {
    return {
        type: actionTypes.ADD_AFFECTATION_START
    };
};

export const addAffectation = (affectationBody, calendarApi) => {
    return dispatch => {
        dispatch(addAffectationStart());
        affectationService.createAffectation(affectationBody)
            .then(res => {
                if (res.status === 201) {
                    // update affectations list with returned affectation (id + data)
                    res.data?.map((affectation) => {
                        if (calendarApi.getEventById(affectation.id) === null) {
                            addEventToFullCalendar(affectation, calendarApi);
                        }
                    });

                    dispatch(addAffectationSuccess());
                    SnackbarUtils.toast(
                        'Event saved',
                        {
                            undo: () => undo.addAffectation(res.data.id, calendarApi),
                        }
                    )
                }
            })
            .catch(err => dispatch(addAffectationFail(err)))
    }
}

////////////// for editing affectation //////////////
export const editAffectationSuccess = () => {
    return {
        type: actionTypes.EDIT_AFFECTATION_SUCCESS,
    };
}

export const editAffectationFail = (error) => {
    return {
        type: actionTypes.EDIT_AFFECTATION_FAIL,
        error: error
    };
}

export const editAffectationStart = () => {
    return {
        type: actionTypes.EDIT_AFFECTATION_START
    };
}

/**
 * Before updating an affectation we await the `GET` request response
 * to prepare the old data for the UNDO data.
 */
export const editAffectation = (id, affectationBody, calendarApi) => {
    return async dispatch => {
        dispatch(editAffectationStart());
        const { data: oldAffectation } = await affectationService.getAffectation(id);

        affectationService.updateAffectation(id, affectationBody)
            .then(res => {
                if (res.status === 200) {
                    // update inside calendar API the only way
                    const updatedAffectation = res.data;
                    const event = calendarApi.getEventById(id);
                    event.remove();
                    addEventToFullCalendar(res.data, calendarApi);

                    dispatch(editAffectationSuccess());
                    SnackbarUtils.toast(
                        'Event updated',
                        {
                            undo: () => undo.editAffectation(
                                id,
                                {
                                    "startDateTime": oldAffectation.startDateTime.split('+')[0],
                                    "endDateTime": oldAffectation.endDateTime.split('+')[0],
                                    "assignedUser": `${API_URL}${API_RESOURCES.USERS}/${oldAffectation.assignedUser.id}`,
                                    "exigencyLevel": oldAffectation.exigencyLevel,
                                    "notes": oldAffectation.notes
                                },
                                calendarApi
                            ),
                        }
                    )
                }
            })
            .catch(err => dispatch(editAffectationFail(err)));
    }
}

////////////// for deleting affectation //////////////
export const deleteAffectationSuccess = () => {
    return {
        type: actionTypes.DELETE_AFFECTATION_SUCCESS,
    };
}

export const deleteAffectationFail = (error) => {
    return {
        type: actionTypes.DELETE_AFFECTATION_FAIL,
        error: error
    };
}

export const deleteAffectationStart = () => {
    return {
        type: actionTypes.DELETE_AFFECTATION_START
    }
}

export const deleteAffectation = (id, calendarApi) => {
    return async dispatch => {
        dispatch(deleteAffectationStart());
        const { data: oldAffectation } = await affectationService.getAffectation(id);

        affectationService.deleteAffectation(id)
            .then(res => {
                // no content success response
                if (res.status === 204) {
                    calendarApi.getEventById(id)?.remove();
                }

                dispatch(deleteAffectationSuccess());
                SnackbarUtils.toast(
                    'Event removed',
                    {
                        undo: () => undo.deleteAffectation(
                            {
                                "isConfirmed": false,
                                "exigencyLevel": oldAffectation.exigencyLevel,
                                "startDateTime": oldAffectation.startDateTime.split('+')[0],
                                "endDateTime": oldAffectation.endDateTime.split('+')[0],
                                "notes": oldAffectation.notes,
                                "assignedUser": `${API_URL}${API_RESOURCES.USERS}/${oldAffectation.assignedUser.id}`,
                                "assignedProject": `${API_URL}${API_RESOURCES.PROJECTS}/${oldAffectation.assignedProject.id}`,
                                "recurrenceRule": oldAffectation.recurrenceRule ? `${API_URL}${API_RESOURCES.RECURRENCE_RULES}/${oldAffectation.recurrenceRule.id}` : null,
                            },
                            calendarApi
                        ),
                    }
                )
            })
            .catch(err => dispatch(deleteAffectationFail(err)));
    }
}

////////////// for prev week affectation //////////////
export const prevWeekAffectationSuccess = () => {
    return {
        type: actionTypes.PREV_WEEK_AFFECTATION_SUCCESS,
    };
}

export const prevWeekAffectationFail = (error) => {
    return {
        type: actionTypes.PREV_WEEK_AFFECTATION_FAIL,
        error: error
    };
}

export const prevWeekAffectationStart = () => {
    return {
        type: actionTypes.PREV_WEEK_AFFECTATION_START
    };
}

export const prevWeekAffectation = (prevWeekBody, calendarApi) => {
    return dispatch => {
        dispatch(prevWeekAffectationStart());
        affectationService.copyLastWeekAffectations(prevWeekBody)
            .then(res => {
                if (res.status === 201) {
                    // update inside affectations list in store
                    const eventsCloned = res.data[HYDRA_MEMBER].length !== 0;
                    const clonedIds = [];
                    if (eventsCloned) {
                        for (const key in res.data[HYDRA_MEMBER]) {
                            const singleAffect = res.data[HYDRA_MEMBER][key];
                            addEventToFullCalendar(singleAffect, calendarApi);

                            clonedIds.push(singleAffect.id)
                        }
                    }

                    dispatch(prevWeekAffectationSuccess());
                    SnackbarUtils.toast(
                        eventsCloned ? 'Event(s) saved' : 'No events to save',
                        {
                            ...(eventsCloned && {
                                undo: () => undo.copyPrevWeekAffectations(clonedIds, calendarApi)
                            })
                        }
                    )
                }
            })
            .catch(err => dispatch(prevWeekAffectationFail(err)));
    }
}

////////////// for affectation list approvment //////////////
export const approveListAffectationSuccess = () => {
    return {
        type: actionTypes.APPROVE_LIST_AFFECTATION_SUCCESS,
    };
}

export const approveListAffectationFail = (error) => {
    return {
        type: actionTypes.APPROVE_LIST_AFFECTATION_FAIL,
        error: error
    };
}

export const approveListAffectationStart = () => {
    return {
        type: actionTypes.APPROVE_LIST_AFFECTATION_START
    };
}

export const approveListAffectation = (requestsIds, calendarApi) => {
    return dispatch => {
        dispatch(approveListAffectationStart());
        affectationService.reviewRequests(requestsIds)
            .then(res => {
                if (res.status === 200) {
                    res.data[HYDRA_MEMBER]?.forEach(affection => {
                        const event = calendarApi.getEventById(affection.id);
                        updateEventInFullCalendar(event, {
                            isConfirmed: true
                        });
                    });

                    dispatch(approveListAffectationSuccess());
                    SnackbarUtils.toast(
                        "Event(s) approved",
                        {
                            undo: () => undo.approveRequests(requestsIds, calendarApi)
                        }
                    )
                }
            })
            .catch(err => dispatch(approveListAffectationFail(err)));
    }
}

// add Recurrent affectation and get linked events
export const addRecurrentAffectation = (payloadData, calendarApi, queryParams = {}) => {
    return dispatch => {
        dispatch(addAffectationStart());
        affectationService.createAffectation(payloadData)
            .then(res => {
                if (res.status === 201) {
                    res.data?.map((affectation) => {
                        dispatch(getAffectationsByRule(calendarApi, affectation?.recurrenceRule?.id, queryParams));
                    })
                }
            })
            .catch(() => dispatch(addAffectationFail()));
    }
}

const getAffectationsByRule = (calendarApi, rRuleId = null, queryParams = {}) => {
    return dispatch => {
        rRuleId && affectationService.getAffectations({
            ...queryParams,
            [AFFECTATION_FILTERS.RECURRENCE_RULE_ID]: rRuleId,
        })
            .then(res => {
                if (res.status === 200) {
                    addAffectationsToCalendar(res.data[HYDRA_MEMBER], calendarApi);
                    dispatch(addAffectationSuccess());

                    const assignedUserId = res.data[HYDRA_MEMBER][0]?.assignedUser?.id;
                    SnackbarUtils.toast(
                        'Recurrent event saved',
                        {
                            ...(rRuleId && assignedUserId && {
                                undo: () => undo.addRecurrentAffectation(
                                    rRuleId,
                                    assignedUserId,
                                    calendarApi
                                ),
                            }),
                        }
                    )
                }
            })
            .catch(err => dispatch(addAffectationFail(err)))
    }
}

/**
 * ⚠️ addAffectationSuccess is a temporary successs handler in the below funtcions, replace later.
 * for editing recurrent affectations and re-fetch resource affectations.
 * No undo funcionality(too complicated to handle)
 */
export const editRecurrentAffectation = (id, data, resourcesIds, queryParams, affectationQueryParams, calendarApi) => {
    return dispatch => {
        dispatch(editAffectationStart())
        affectationService.updateRecurrentAffectation(id, data, queryParams)
            .then(res => {
                if (res.status === 200) {
                    dispatch(reloadResourceAffectations(calendarApi, resourcesIds, affectationQueryParams, addAffectationSuccess, editAffectationFail));
                }
            })
            .catch(err => dispatch(editAffectationFail(err)));
    }
}

/**
 * for limiting a recurrent affectation (RRULE updated from `{...}` to none `{}`).
 * No undo funcionality for the moment(it requires complicated API refactor)
 */
export const breakRecurrentAffectation = (id, resourcesIds, affectationQueryParams, calendarApi) => {
    return dispatch => {
        dispatch(deleteAffectationStart());
        affectationService.limitRecurrentAffectation(id)
            .then(res => {
                if (res.status === 204) {
                    dispatch(reloadResourceAffectations(calendarApi, resourcesIds, affectationQueryParams, addAffectationSuccess, deleteAffectationFail));
                }
            })
            .catch(err => dispatch(deleteAffectationFail(err)))
    }
}

/**
 * delete recurrent affectation and its following events.
 * No undo funcionality for the moment(We can implment a feat like GoogleCalendar, it uses a Calendar Bin system when deleting a recurrent event and its' following)
 */
export const deleteRecurrentAffectaionAndFollowing = (id, resourcesIds, affectationQueryParams, calendarApi) => {
    return dispatch => {
        dispatch(deleteAffectationStart());
        affectationService.deleteRecurrentAffectationAndFollowing(id)
            .then(res => {
                if (res.status === 204) {
                    dispatch(reloadResourceAffectations(calendarApi, resourcesIds, affectationQueryParams, addAffectationSuccess, deleteAffectationFail));
                }
            })
            .catch(err => dispatch(deleteAffectationFail(err)))
    }
}

// common
// ⚠️ Open assigns per service reload aren't handled yet (id of `0` in front, `null` value in the back)
const reloadResourceAffectations = (calendarApi, resourcesIds, queryParams, successDispatcher, failDispatcher) => {
    return dispatch => {
        affectationService.getAffectations(queryParams)
            .then(res => {
                if (res.status === 200 && Array.isArray(resourcesIds)) {
                    resourcesIds.forEach(resourceId => {
                        const calendarResource = calendarApi.getResourceById(resourceId);
                        if (calendarResource) {
                            // remove resource events
                            const resourceEvents = calendarResource.getEvents()
                            resourceEvents.forEach(event => {
                                event.rendering !== 'background' && event.remove();
                            })

                            // reload new events into calendar
                            addAffectationsToCalendar(res.data[HYDRA_MEMBER], calendarApi)
                        }
                    })
                }

                SnackbarUtils.toast('Recurrent event saved');
                // to re-change, just to stop the loading state
                dispatch(successDispatcher());
            })
            .catch(err => dispatch(failDispatcher(err)));
    }
}

const addAffectationsToCalendar = (affectations, calendarApi) => {
    affectations.forEach(affectation => {
        if (calendarApi.getEventById(affectation.id) === null) {
            addEventToFullCalendar(affectation, calendarApi)
        }
    });
}

export const fetchTrafficStats = () => {
    return dispatch => {
        affectationService.getTrafficStats()
          .then(res => {
              dispatch(fetchTrafficStatsSuccess(res));
          })
    }
}

const fetchTrafficStatsSuccess = (res) => {
    return {
        type:actionTypes.FETCH_STATS_SUCCESS,
        data: res.data
    }
}
