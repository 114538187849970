import * as actionTypes from './actions-types';
import { teamService } from '../../services/teamService';
import { HYDRA_MEMBER } from '../../services/utils/hydras';
import SnackbarUtils from '../../shared/SnackbarUtils';

///  for services fetching
export const fetchServicesSuccess = (services) => {
    return {
        type: actionTypes.FETCH_SERVICES_SUCCESS,
        services: services,
    };
};

export const fetchServicesFail = (error) => {
    return {
        type: actionTypes.FETCH_SERVICES_FAIL,
        error: error,
    };
};

export const fetchServicesStart = () => {
    return {
        type: actionTypes.FETCH_SERVICES_START,
    };
};

export const fetchServices = (queryParams) => {
    return dispatch => {
        dispatch(fetchServicesStart());
        teamService.getServices(queryParams)
            .then(res => dispatch(fetchServicesSuccess(res.data[HYDRA_MEMBER])))
            .catch(err => dispatch(fetchServicesFail(err)));
    }
}

///  for fecthing a sub-services
export const fecthSubServicesSuccess = (subServices) => {
    return {
        type: actionTypes.FETCH_SUB_SERVICES_SUCCESS,
        subServices: subServices,
    };
};

export const fetchSubServicesFail = (error) => {
    return {
        type: actionTypes.FETCH_SUB_SERVICES_FAIL,
        error: error,
    };
};

export const fetchSubServicesStart = () => {
    return {
        type: actionTypes.FETCH_SUB_SERVICES_START,
    };
};

export const fetchSubServices = (queryParams) => {
    return dispatch => {
        dispatch(fetchSubServicesStart());
        teamService.getSubServices(queryParams)
            .then(res => dispatch(fecthSubServicesSuccess(res.data[HYDRA_MEMBER])))
            .catch(err => dispatch(fetchSubServicesFail(err)));
    }
}

///  for fetching affectations per week and services
export const fetchAffectationsPerWeekAndServicesSuccess = (affectationsPerWeekAndServices) => {
    return {
        type: actionTypes.FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_SUCCESS,
        affectationsPerWeekAndServices: affectationsPerWeekAndServices,
    };
};

export const fetchAffectationsPerWeekAndServicesFail = (error) => {
    return {
        type: actionTypes.FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_FAIL,
        error: error,
    };
};

export const fetchAffectationsPerWeekAndServicesStart = () => {
    return {
        type: actionTypes.FETCH_AFFECTATIONS_PER_WEEK_AND_SERVICES_START,
    };
};

export const fetchAffectationsPerWeekAndServices = (queryParams) => {
    return dispatch => {
        dispatch(fetchAffectationsPerWeekAndServicesStart());
        teamService.getAffectationsPerWeekAndServices(queryParams)
            .then(res => dispatch(fetchAffectationsPerWeekAndServicesSuccess(res.data)))
            .catch(err => dispatch(fetchAffectationsPerWeekAndServicesFail(err)));
    }
}

///  for fetching working days per week and services
export const fetchWorkingDaysPerWeekAndServicesSuccess = (workingDaysPerWeekAndServices) => {
    return {
        type: actionTypes.FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_SUCCESS,
        workingDaysPerWeekAndServices: workingDaysPerWeekAndServices,
    };
}

export const fetchWorkingDaysPerWeekAndServicesFail = (error) => {
    return {
        type: actionTypes.FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_FAIL,
        error: error,
    };
}

export const fetchWorkingDaysPerWeekAndServicesStart = () => {
    return {
        type: actionTypes.FETCH_WORKING_DAYS_PER_WEEK_AND_SERVICES_START,
    };
}

export const fetchWorkingDaysPerWeekAndServices = (queryParams) => {
    return dispatch => {
        dispatch(fetchWorkingDaysPerWeekAndServicesStart());
        teamService.getWorkingDaysPerWeekAndServices(queryParams)
            .then(res => dispatch(fetchWorkingDaysPerWeekAndServicesSuccess(res.data)))
            .catch(err => dispatch(fetchWorkingDaysPerWeekAndServicesFail(err)));
    }
}

///  for adding a service
export const addServiceSuccess = (services) => {
    return {
        type: actionTypes.ADD_SERVICE_SUCCESS,
        services: services,
    };
};

export const addServiceFail = (error) => {
    return {
        type: actionTypes.ADD_SERVICE_FAIL,
        error: error
    };
};

export const addServiceStart = () => {
    return {
        type: actionTypes.ADD_SERVICE_START,
    };
};

export const addService = (data, services) => {
    return dispatch => {
        dispatch(addServiceStart());
        teamService.addService(data)
            .then(res => {
                if (res.status === 201) {
                    services.push({ ...res.data });
                    dispatch(addServiceSuccess(services));
                    SnackbarUtils.success('Service created successfully');
                }
            })
            .catch(err => dispatch(addServiceFail(err)))
    }
}

///  for adding a sub-service
export const addSubServiceSuccess = (services) => {
    return {
        type: actionTypes.ADD_SUB_SERVICE_SUCCESS,
        services: services,
    };
};

export const addSubServiceFail = (error) => {
    return {
        type: actionTypes.ADD_SUB_SERVICE_FAIL,
        error: error
    };
};

export const addSubServiceStart = () => {
    return {
        type: actionTypes.ADD_SUB_SERVICE_START,
    };
};

export const addSubService = (data, serviceId, services) => {
    return dispatch => {
        dispatch(addSubServiceStart());
        teamService.addSubService(data)
            .then(res => {
                if (res.status === 201) {
                    const serviceIndex = services.findIndex(service => service.id === serviceId);
                    const serviceToUpdate = services[serviceIndex];
                    serviceToUpdate.subServices.push({
                        ...res.data,
                    });
                    services[serviceIndex] = serviceToUpdate;
                    dispatch(addSubServiceSuccess(services));
                    SnackbarUtils.success('Sub-service created successfully');
                }
            })
            .catch(err => dispatch(addSubServiceFail(err)));
    }
}

///  for editing a service
export const editServiceSuccess = (services) => {
    return {
        type: actionTypes.EDIT_SERVICE_SUCCESS,
        services: services,
    };
};

export const editServiceFail = (error) => {
    return {
        type: actionTypes.EDIT_SERVICE_FAIL,
        error: error
    };
};

export const editServiceStart = () => {
    return {
        type: actionTypes.EDIT_SERVICE_START,
    };
};

export const editService = (id, data, servicesOutsideStore) => {
    return dispatch => {
        dispatch(editServiceStart());
        teamService.updateService(id, data)
            .then(res => {
                if (res.status === 200) {
                    const serviceIndex = servicesOutsideStore.findIndex(x => x.id === id);
                    servicesOutsideStore[serviceIndex] = { ...res.data };
                    dispatch(editServiceSuccess(servicesOutsideStore));
                    SnackbarUtils.success('Service updated successfully');
                }
            })
            .catch(err => dispatch(editServiceFail(err)));
    }
}

///  for editing a sub-service
export const editSubServiceSuccess = (services) => {
    return {
        type: actionTypes.EDIT_SUB_SERVICE_SUCCESS,
        services: services,
    };
};

export const editSubServiceFail = (error) => {
    return {
        type: actionTypes.EDIT_SUB_SERVICE_FAIL,
        error: error
    };
};

export const editSubServiceStart = () => {
    return {
        type: actionTypes.EDIT_SUB_SERVICE_START,
    };
};

export const editSubService = (id, data, parentId, servicesOutsideStore) => {
    return dispatch => {
        dispatch(editSubServiceStart());
        teamService.updateSubService(id, data)
            .then(res => {
                if (res.status === 200) {
                    const mainServiceIndex = servicesOutsideStore.findIndex(x => x.id === parentId);
                    const mainService = servicesOutsideStore[mainServiceIndex];
                    const subServiceIndex = mainService.subServices.findIndex(x => x.id === id);

                    mainService.subServices[subServiceIndex] = { ...res.data };
                    servicesOutsideStore[mainServiceIndex] = { ...mainService };

                    dispatch(editSubServiceSuccess(servicesOutsideStore));
                    SnackbarUtils.success('Sub-service updated successfully');
                }
            })
            .catch(err => dispatch(editSubServiceFail(err)));
    }
}

///  for deleting a service
export const deleteServiceSuccess = (services) => {
    return {
        type: actionTypes.DELETE_SERVICE_SUCCESS,
        services: services,
    };
};

export const deleteServiceFail = (error) => {
    return {
        type: actionTypes.DELETE_SERVICE_FAIL,
        error: error
    };
};

export const deleteServiceStart = () => {
    return {
        type: actionTypes.DELETE_SERVICE_START,
    };
};

export const deleteService = (id, servicesOutsideStore) => {
    return dispatch => {
        dispatch(deleteServiceStart());
        teamService.deleteService(id)
            .then(res => {
                if (res.status === 204) {
                    const serviceIndex = servicesOutsideStore.findIndex(x => x.id === id);
                    if (serviceIndex > -1) {
                        servicesOutsideStore.splice(serviceIndex, 1);
                        dispatch(deleteServiceSuccess(servicesOutsideStore));
                    }
                    SnackbarUtils.info('Service removed successfully');
                }
            })
            .catch(err => dispatch(deleteServiceFail(err)));
    }
}

///  for deleting a sub-service
export const deleteSubServiceSuccess = (services) => {
    return {
        type: actionTypes.DELETE_SUB_SERVICE_SUCCESS,
        services: services,
    };
};

export const deleteSubServiceFail = (error) => {
    return {
        type: actionTypes.DELETE_SUB_SERVICE_FAIL,
        error: error
    };
};

export const deleteSubServiceStart = () => {
    return {
        type: actionTypes.DELETE_SUB_SERVICE_START,
    };
};

export const deletSubService = (id, parentId, servicesOutsideStore) => {
    return dispatch => {
        dispatch(deleteSubServiceStart());
        teamService.deleteSubService(id)
            .then(res => {
                if (res.status === 204) {
                    const serviceIndex = servicesOutsideStore.findIndex(x => x.id === parentId);
                    if (serviceIndex > -1) {
                        const mainService = servicesOutsideStore[serviceIndex];
                        const subServiceIndex = mainService.subServices.findIndex(x => x.id === id);

                        mainService.subServices.splice(subServiceIndex, 1);
                        servicesOutsideStore[serviceIndex] = mainService;

                        dispatch(deleteServiceSuccess(servicesOutsideStore));
                    }
                    SnackbarUtils.info('Sub-service removed successfully');
                }
            })
            .catch(err => dispatch(deleteSubServiceFail(err)));
    }
}
