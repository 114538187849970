import React from 'react';

import { DesktopDatePicker, LocalizationProvider } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import moment from "moment"
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

export default function DateNavigator(props) {

    function disableWeekends(date) {
        return moment(date).get('day') === 0 || moment(date).get('day') === 6;
    }

    return (
        <div>
        <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} dateFormats="yyyy-MM-dd" >
        <DesktopDatePicker
                autoOk
                allowKeyboardControl={true}
                open={props.open}
                onClose={props.onClose}
                label={props.label}
                value={props.defaultValue}
                onChange={props.handleDateChange}
                openPickerIcon={<></>}
                renderInput={inputProps =>
                    <TextField
                        helperText="mlk"
                        id="date-picker-inline"
                        {...inputProps}
                        hiddenLabel={true}
                        disabled={true}
                        name={inputProps.name}
                        variant="outlined"
                        style={{ width: '0px', height: '0px', backgroundColor: 'white', visibility: 'hidden' }} />}
                disabled={props.disabled}
                shouldDisableDate={disableWeekends}
                PopoverProps={{
                    anchorReference: 'anchorPosition',
                    anchorPosition:{ top: 160, left: 700 }
                }}
            />
            </LocalizationProvider>
        </div>
    )
}
