import React from 'react';

import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from "@material-ui/pickers";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { TextField } from '@material-ui/core';
import moment from 'moment';


export default function DateRange1(props) {

    function disableWeekends(date) {
        return moment(date).get('day') === 0 || moment(date).get('day') === 6;
    }

    // disable the same date range error flag
    const [error, setError] = React.useState([null, null])

    return (
        <div>
            <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} >
                <DateRangePicker
                    open={props.open}
                    onError={([startReason, endReason], [start, end]) => {
                        if (startReason === 'invalidRange' && moment(start).diff(end, 'd') === 0) {
                            setError([null, null])
                            return;
                        }

                        setError([startReason, endReason])
                    }}
                    ignoreInvalidInputs={true}

                    disablePast
                    shouldDisableDate={disableWeekends}
                    calendars={2}

                    startText="Dayoff Start Date"
                    endText="Dayoff End Date"
                    value={props.value}
                    onChange={props.onChangeRange}
                    renderInput={(startProps, endProps) => (
                    <div style={{ display: 'flex', width: '100%'  }}>
                        <TextField
                            onClickCapture={props.onOpen}
                            required {...startProps}
                            error={Boolean(error[0])}
                            style={{ width: 180 }}
                            />

                        <DateRangeDelimiter> 
                            <ArrowForwardIcon style={{width: "40px", marginBottom: "-32px"}} />    
                        </DateRangeDelimiter>

                        <TextField 
                            onClickCapture={props.onOpen} 
                            required {...endProps}
                            error={Boolean(error[1])}
                            style={{ width: 180 }}
                        />
                    </div>
                )}
                />
            </LocalizationProvider>
        </div>
    )
}
