import React from 'react';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import moment from "moment"
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

export default function DatePickerForm(props) {
    const disableWeekends = (date) =>
        moment(date).get('day') === 0 || moment(date).get('day') === 6;

    return (
        <div className={props.className || ''}>
            <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} dateFormats="yyyy-MM-dd" >
                <DesktopDatePicker
                    disabledPortal
                    disableEnforceFocus
                    disablePast
                    autoOk={false}
                    allowKeyboardControl={true}
                    label={props.label}
                    value={props.defaultValue}
                    onChange={props.onChangeData}
                    renderInput={inputProps =>
                        <TextField
                            {...inputProps}
                            name={inputProps.name}
                            variant="outlined"
                            style={{ width: '160px', backgroundColor: 'white' }} />}
                    disabled={props.disabled}
                    shouldDisableDate={disableWeekends}
                />
            </LocalizationProvider>
        </div>
    )
}
