import React from 'react';

import TextField from '@material-ui/core/TextField';

export default function BigTextField(props) {
    return (
        <TextField
            name={props.name}
            id="outlined-multiline-static"
            label={props.label}
            multiline
            rows={2}
            style={{ width: '100%' }}
            variant="outlined"
            defaultValue={props.value}
            onChange={props.onChangeData}
            disabled={props.disabled}
        />
    )
}
