import { ROLE } from './authUtility';

const moment = require('moment');
const momentBusinessDays = require('moment-business-days');

const PROJECT_MANAGER_STRING = 'Project Manager';
// const formats
const DATE_MERIDIAN_FORMAT = 'YYYY-MM-DD a';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FORMAT = "MM-DD-YYYY";

// API services codes
export const SERVICE_DEV_CODE = 'development';
export const SERVICE_DESIGN_CODE = 'design';
export const SERVICE_CONTENT_CODE = 'content_market';
export const SERVICE_OTHERS_CODE = 'others';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    // if rules is null
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const checkContent = (attribute, value) => {
    if (attribute === 'resourceTream') {
        if (value.length > 0) {
            return value;
        } else {
            return [];
        }
    } else if (value) {
        return value;
    }
    return '';
}

export const getMainRole = (rolesArray) => {
    if (rolesArray.includes(ROLE.ADMIN)) {
        return ROLE.ADMIN;
    } else if (rolesArray.includes(ROLE.VALIDATOR)) {
        return ROLE.VALIDATOR;
    } else if (rolesArray.includes(ROLE.PROJECT_MANAGER)) {
        return ROLE.PROJECT_MANAGER;
    } else {
        return ROLE.RESOURCE;
    }
}

export const dispalyRoles = (rolesText) => {
    const rolesTextArray = [];
    rolesText.forEach(x => {
        switch (x) {
            case ROLE.ADMIN: rolesTextArray.push('Admin');
                break;
            case ROLE.PROJECT_MANAGER: rolesTextArray.push(PROJECT_MANAGER_STRING);
                break
            case ROLE.VALIDATOR: rolesTextArray.push('Validator');
                break;
            case ROLE.RESOURCE: rolesTextArray.push('Resource');
                break;
            case 'Admin': rolesTextArray.push('Admin');
                break;
            case PROJECT_MANAGER_STRING: rolesTextArray.push(PROJECT_MANAGER_STRING);
                break;
            case 'Validator': rolesTextArray.push('Validator');
                break;
            case 'Resource': rolesTextArray.push('Resource');
                break;
            default: break;
        }
    });

    return rolesTextArray;
}

export const resetRoles = (displayedRoles) => {
    let rolesString = '';
    displayedRoles.forEach(x => {
        switch (x) {
            case 'Admin': rolesString = rolesString.concat(`${ROLE.ADMIN},`);
                break;
            case PROJECT_MANAGER_STRING: rolesString = rolesString.concat(`${ROLE.PROJECT_MANAGER},`);
                break;
            case 'Validator': rolesString = rolesString.concat(`${ROLE.VALIDATOR},`);
                break;
            case 'Resource': rolesString = rolesString.concat(`${ROLE.RESOURCE},`);
                break;
            default: break;
        }
    });

    return rolesString.slice(0, -1);
}

export const checkedOfficesData = [
    { key: 0, label: 'Bordeaux', checked: false },
    { key: 1, label: 'Londres', checked: false },
    { key: 2, label: 'Paris', checked: false },
    { key: 3, label: 'Tunis', checked: false },
]

export const servicesData = [
    'developer', 'designer', 'editor', 'other'
]

export const hasDigits = (str) => /\d/.test(str);

export const removeDigitsFromString = (str) => str.replace(/\d/g, '');

export const hasOnlyDigits = (value) => /^-?\d+$/.test(value);

export const parseStrToInt = (value) => hasOnlyDigits(value) ? parseInt(value) : 0;

export const dateInRange = (date1, dateStart, dateEnd) => {
    return momentBusinessDays(date1, DATE_TIME_FORMAT).isBetween(dateStart, dateEnd, 'hours', '[]');
}

export const differenceTwoDates = (date1, date2) => {
    return momentBusinessDays(date1, DATE_TIME_FORMAT).diff(date2);
}

export const transformEndDate = (startDate, daySlot) => {
    let transformedDate = momentBusinessDays(startDate, DATE_TIME_FORMAT).businessAdd(24 * daySlot, 'h').format(DATE_TIME_FORMAT);
    if (moment(transformedDate).day() === 1 && moment(transformedDate).hour() === 0) {
        transformedDate = moment(transformedDate).subtract(2, 'days').format(DATE_TIME_FORMAT);
    }
    return transformedDate;
}

export const transformEndDateDisplay = (startDate, daySlot) => {
    const endDatTransformed = momentBusinessDays(startDate, DATE_TIME_FORMAT).businessAdd(24 * daySlot, 'h').format(DATE_TIME_FORMAT);
    if (momentBusinessDays(endDatTransformed, DATE_TIME_FORMAT).get('hour') === 0) {
        return momentBusinessDays(endDatTransformed, DATE_TIME_FORMAT).businessSubtract(1, 's').format(DATE_TIME_FORMAT);
    }
    return endDatTransformed;
}

export const transformDaySlot = (startDate, endDate) => {
    let day1 = momentBusinessDays(startDate, DATE_TIME_FORMAT);
    let day2 = momentBusinessDays(endDate, DATE_TIME_FORMAT);

    if ((day1.format('HH:mm') === '12:00' && day2.format('HH:mm') === '00:00') ||
        (day1.format('HH:mm') === '00:00' && day2.format('HH:mm') === '12:00')) {
        if (day1.format('YYYY-MM-DD') === day2.format('YYYY-MM-DD')) {
            return 0.5;
        }
        else { return day2.businessDiff(day1) - 0.5; }
    }
    else {
        return day2.businessDiff(day1);
    }


}

export const findObjInArray = (array, id) => {
    return array.find((obj) => parseInt(obj.id) === parseInt(id));
}

export const transformId = (id) => {
    if (id === "None") {
        return null;
    } else {
        return parseInt(id, 10);
    }
}

export const toTitleCase = (phrase) => {
    return phrase?.toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase())
        .join('') ||
        'N-A';
};

// for Gantt pro
export const checkWeekRangeFotStart = (dateToCheck, weeksNumber) => {
    const startRange = moment().add(weeksNumber, 'weeks').startOf('week');
    const endRange = moment(startRange).add(7, 'days');
    return moment(dateToCheck).isBetween(startRange, endRange, undefined, '[]');
};

export const checkWeekRange = (dateToCheck, weeksNumber) => {
    const startRange = moment().add(weeksNumber, 'weeks').startOf('week').add(33, 'hours');
    const endRange = moment(startRange).add(7, 'days');
    return moment(dateToCheck).isBetween(startRange, endRange, undefined, '(]');
};

export const transformGanttDate = (dateToDisplay) => {
    const dateToTransform = moment(dateToDisplay);
    if (dateToTransform.hour() === 9) {
        if (dateToTransform.day() === 1) {
            return dateToTransform.subtract(63, 'hours').format(DATE_MERIDIAN_FORMAT);
        } else {
            return dateToTransform.subtract(1, 'days').format(DATE_MERIDIAN_FORMAT);
        }
    } else if (dateToTransform.hour() === 14) {
        return dateToTransform.subtract(2, 'hours').format(DATE_MERIDIAN_FORMAT);
    }
    return dateToTransform.format(DATE_MERIDIAN_FORMAT);
};

export const transformGanttStartDate = (dateToDisplay) => {
    return moment(dateToDisplay).format(DATE_MERIDIAN_FORMAT);
};

export const ganttProDataTypes = [
    { id: 'Projects', value: 'Projects' },
    { id: 'Resources', value: 'Resources' },
];

// gantt utils functions
export const dateRangesIntersect = (startDate, endDate, wantedStart, wantedEnd) => {
    return !(moment(startDate).isSameOrAfter(wantedEnd, 'hour') || moment(endDate).isSameOrBefore(wantedStart, 'hour'));
}
