import React from 'react';
import { connect } from 'react-redux';
import DrawerMaterialUi from '../../components/Navigation/NavigationItems/DrawerMaterialUi';
import AppHashLoader from '../../components/UI/Loader/HashLoader/AppHashLoader';
import * as actions from '../../store/actions/index';

import classes from './Layout.module.scss';

function Layout({ onFetchMe, ...props }) {
    React.useEffect(() => {
        onFetchMe();
    }, [onFetchMe]);

    return (
        <div className={classes.root1}>
            <DrawerMaterialUi isAuth={props.isAuthen} />
            {props.loggedUser?.userId &&
                <main className={classes.content}>
                    {props.children}
                </main>
            }
            {props.loading &&
                <div className={classes.hashLoader}>
                    <AppHashLoader />
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isAuthen: state.auth.token !== null,
        loggedUser: state.app.user,
        loading: state.app.loading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchMe: () => dispatch(actions.fetchMe()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
