import React from 'react';
import Switch from '@material-ui/core/Switch';

export default function SwitchToggle(props) {
    return (
        <>
            <Switch
                id={props.id || 'enable-switch'}
                name={props.name || 'enable-switch'}
                color="primary"
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
            />
        </>
    )
}
