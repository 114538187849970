import { API_RESOURCES, axiosInstance } from './utils/apiResources';
import { buildFilters } from './utils/filters';

export const dayOffService = {
    getDayOffs,
    getDayOff,
    createDayOff,
    updateDayOff,
    deleteDayOff,
    updateRecurrentDayOff,
    limitRecurrentDayOff,
    deleteRecurrentDayOffAndFollowing,
    getExternalDayOffs,
};

function getDayOffs(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.DAY_OFFS}${buildFilters(queryParams)}`
    );
}

function getDayOff(id) {
    return axiosInstance.get(
        `${API_RESOURCES.DAY_OFFS}/${id}`,
    );
}

function createDayOff(data) {
    return axiosInstance.post(
        `${API_RESOURCES.DAY_OFFS}`,
        data
    );
}

function updateDayOff(id, data) {
    return axiosInstance.put(
        `${API_RESOURCES.DAY_OFFS}/${id}`,
        data
    );
}

function deleteDayOff(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.DAY_OFFS}/${id}`
    );
}

function updateRecurrentDayOff(id, data, queryParams = {}) {
    return axiosInstance.put(
        `${API_RESOURCES.DAY_OFFS}/${id}/update_rrule${buildFilters(queryParams)}`,
        data
    );
}

function limitRecurrentDayOff(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.DAY_OFFS}/${id}/does_not_repeat`
    );
}

function deleteRecurrentDayOffAndFollowing(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.DAY_OFFS}/${id}/this_and_following_daysoff`
    );
}

function getExternalDayOffs(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.EXTERNAL_DAY_OFF}${buildFilters(queryParams)}`
    );
}
