import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function UserRolesSelect(props) {
    return (
        <DefaultSelect
            id="roles-select"
            none={false}
            multiple={true}
            isFilter={false}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            data={['Admin', 'Validator', 'Project Manager', 'Resource']}
        />
    )
}
