import { ROLE } from '../../shared/authUtility';
import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actions-types';

const initialState = {
    exigencyLevelTypes: [],
    dayOffTypes: [],
    user: {
        userId: null,
        fullName: null,
        roles: ROLE.RESOURCE,
        service: null,
    },
    loading: false,
}

const fectchDBALData = (state, action) => {
    return updateObject(state, {
        exigencyLevelTypes: action.exigencyLevelTypes,
        dayOffTypes: action.dayOffTypes,
    });
}

const fetchMeStart = (state, _action) => {
    return updateObject(state, { loading: true })
}

const fetchMeSuccess = (state, action) => {
    return updateObject(
        state,
        {
            loading: false,
            user: {
                userId: action.userId,
                fullName: action.fullName,
                roles: action.roles,
                service: action.service,
            }
        }
    )
}

const fetchMeFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

const appReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.FETCH_APP_DBAL: return fectchDBALData(state, action);
        case actionTypes.FETCH_APP_ME_START: return fetchMeStart(state, action);
        case actionTypes.FETCH_APP_ME_SUCCESS: return fetchMeSuccess(state, action);
        case actionTypes.FETCH_APP_ME_FAIL: return fetchMeFail(state, action);

        default: return state;
    }
}

export default appReducer;
