import * as actionTypes from './actions-types';
import { authService } from '../../services/authService';
import { setLocalStorageValues } from '../../shared/authUtility';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.clear();
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

export const auth = (data, rememberMe = false) => {
    return dispatch => {
        dispatch(authStart());
        authService.signIn(data)
            .then(res => {
                if (res.status === 200) {
                    setLocalStorageValues(res.data, rememberMe);
                    dispatch(authSuccess(res.data.token));
                }
            })
            .catch(err => dispatch(authFail(err.response?.data || err)));
    };
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));

            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeOut(expirationDate.getTime() - (new Date()).getTime()));
            }
        }
    }
}
