import React from 'react';
import CardServiceStat from '../../UI/Card/CardServiceStat/CardServiceStat';

import './ServiceCards.scss';

export default function ServiceCards(props) {
    console.log(props.totalResources);
    const colors = {
        dev: '#45276A',
        crea: '#A6CEE3',
        autre: '#6587FF',
        marketing: '#FFE4E4',
    }
    let globalStats = [];
    if (props.totalResources) {
        globalStats = Object.keys(props.totalResources)?.map(key => ({
            name: key,
            total: props.totalResources[key].total,
            inUse: props.totalResources[key].inUse,
            color: colors[key],
        }));
    }

    let servicesCards = null;
    servicesCards = globalStats?.map((elements, index) =>
        <div className={'cardItem'} key={index}>
            <CardServiceStat elements={elements} />
        </div>
    );

    return (
        <div className="services-container">
            {servicesCards}
        </div>
    )
}
