import moment from "moment";
import momentBusinessDays from 'moment-business-days';

import colors from '../containers/Dashboard/dashboard.module.scss';
import { affectationService } from "../services/affectationService";
import { dayOffService } from "../services/dayOffService";
import { recurrenceRuleService } from "../services/recurrenceRuleService";
import { HYDRA_MEMBER } from "../services/utils/hydras";
import { canCUDDayOff, canUDAffectation } from "./authorizationUtility";
import SnackbarUtils from "./SnackbarUtils";

export const EVENT_DAY_OFF_PREFIX = 'd-';
export const EVENT_EXTERNAL_DAY_OFF_PREFIX = 'd-external-';

function addEventToFullCalendar(data = {}, calendarApi = null) {
    const assignedProject = data.assignedProject;
    const projectManager = assignedProject?.projectManager || {
        id: null,
        fullName: ''
    }

    const isEventEditable = canUDAffectation(data.createdBy.id, data.isConfirmed)

    calendarApi?.addEvent({
        id: data.id,
        title: assignedProject.name,
        start: data.startDateTime.split('+')[0],
        end: data.endDateTime.split('+')[0],
        resourceId: data.assignedUser?.id || 0,
        projectId: assignedProject.id,
        projectName: assignedProject.name,
        isBidProject: assignedProject.isBid,
        exigencyLevel: data.exigencyLevel,
        isConfirmed: data.isConfirmed,
        notes: data.notes,
        // Event maintainers
        projectManagerId: projectManager.id,
        projectManagerName: projectManager.fullName,
        createdById: data.createdBy.id,
        createdByName: data.createdBy.fullName,
        // Interaction limits
        editable: isEventEditable,
        startEditable: isEventEditable,
        resourceEditable: isEventEditable,
        durationEditable: isEventEditable,
        ...(data.recurrenceRule && {
            recurrenceRule: data.recurrenceRule
        }),
        // Styles
        color: getRenderingEventColor(data.isConfirmed, data.isBid),
        textColor: 'white',
        classNames: ['myEvents', 'addEventAction'],
    });
}

function addBackgroundEventToFullCalendar(data = {}, calendarApi = null) {
    const resourceIds = [];
    const office = data.office;
    const isOfficeDayOff = Array.isArray(office) && office.length > 0;

    if (isOfficeDayOff) {
        calendarApi?.getResources().forEach(resource => {
            if (office.includes(resource.extendedProps.office)) {
                resourceIds.push(resource.id);
            }
        })
    }

    calendarApi?.addEvent({
        id: `${EVENT_DAY_OFF_PREFIX}${data.id}`,
        title: data.typeDayOff,
        start: data.startDateTime.split('+')[0],
        end: data.endDateTime.split('+')[0],
        office,
        // Interaction limits
        overlap: false,
        editable: false,
        clickable: false,
        ...(isOfficeDayOff ? {
            resourceIds
        } : {
            resourceId: data.userDayOff.id,
        }
        ),
        // TO not confuse with the defined keywrod RRule and it's not valid for offices' holidays
        ...(data.recurrenceRule && {
            recurrenceRule: data.recurrenceRule
        }),
        // Styles
        rendering: 'background',
        classNames: ["stripes"]
    })
}

/**
 * To update an event in Full-calendar, it should inlclude at least one of these props:
 * { start, end, resourceId, exigencyLevel, notes, recurrenceRule }
 * in case we have `isConfirmed` prop, it means that we have an approve request action.
 */
function updateEventInFullCalendar(event, data = {}) {
    // To update a calendar event, the `editable` props have be set `true` and re-assigned later to event.
    event.setProp('editable', true);
    event.setProp('durationEditable', true);
    event.setProp('resourceEditable', true);
    event.setProp('startEditable', true);

    data?.start && data?.end && event.setDates(data.start, data.end);
    data?.resourceId && event.setResources([data.resourceId]);

    data?.exigencyLevel && event.setExtendedProp('exigencyLevel', data.exigencyLevel);
    data?.notes && event.setExtendedProp('notes', data.notes);
    data?.recurrenceRule && event.setExtendedProp('recurrenceRule', data.recurrenceRule);

    if (typeof data?.isConfirmed === 'boolean') {
        event.setExtendedProp('isConfirmed', data.isConfirmed);
        event.setProp(
            'color',
            getRenderingEventColor(
                data.isConfirmed,
                data.isBid || event.extendedProps.isBidProject
            )
        );
    }

    const isEventEditable = canUDAffectation(event.extendedProps.createdById, event.extendedProps.isConfirmed);

    event.setProp('editable', isEventEditable);
    event.setProp('durationEditable', isEventEditable);
    event.setProp('resourceEditable', isEventEditable);
    event.setProp('startEditable', isEventEditable);
    event.setProp('startEditable', isEventEditable);
}

/**
 * To update a background-event in Full-calendar, it should inlclude at least one of these props:
 * { start, end, resourceId, typeDayOff, office }
 */
function updateBackgroundEventInFullCalendar(event, data = {}) {
    event.setProp('editable', true);
    event.setProp('durationEditable', true);
    event.setProp('resourceEditable', true);
    event.setProp('startEditable', true);

    data?.start && data?.end && event.setDates(data.start, data.end);
    data?.resourceId && event.setResources([data.resourceId]);
    data?.office && event?.setExtendedProp('office', event.office);
    if (data?.typeDayOff) {
        event?.setProp('title', data.typeDayOff);
        event?.setExtendedProp('typeDayOff', data.typeDayOff);
    }

    const isEditable = canCUDDayOff();

    event.setProp('editable', isEditable);
    event.setProp('durationEditable', isEditable);
    event.setProp('resourceEditable', isEditable);
}

function getStartWorkHour(dateTime = moment(), reverse = true) {
    if (reverse) {
        switch (dateTime.get('hours')) {
            case 0:
                return 9;
            case 3:
                return 10;
            case 6:
                return 11;
            case 9:
                return 12;
            case 12:
                return 14;
            case 15:
                return 15;
            case 18:
                return 16;
            case 21:
                return 17;
            default:
        }
    } else {
        switch (dateTime.get('hours')) {
            case 9:
                return 0;
            case 10:
                return 3;
            case 11:
                return 6;
            case 12:
                return 9;
            case 14:
                return 12;
            case 15:
                return 15;
            case 16:
                return 18;
            case 17:
                return 21;
            default:
        }
    }
}

function getEndWorkHour(dateTime = new Date(), reverse = true) {
    const hours = dateTime.get('hours');
    if (reverse) {
        switch (hours) {
            case 3:
                return 10;
            case 6:
                return 11;
            case 9:
                return 12;
            case 12:
                return 13;
            case 15:
                return 15;
            case 18:
                return 16;
            case 21:
                return 17;
            case 0:
                return 18;
            default:
        }
    } else {
        switch (hours) {
            case 10:
                return 3;
            case 11:
                return 6;
            case 12:
                return 9;
            case 13:
                return 12;
            case 15:
                return 15;
            case 16:
                return 18;
            case 17:
                return 21;
            case 18:
                return 0;
            default:
        }
    }
    // In case `hours` was badly set we return the closest multiple of 3
    return Math.ceil(hours / 3) * 3
}

const isHalfDaySlot = (start = 0, end = 0) =>
    [9, 14].includes(start) &&
    [13, 18].includes(end);

/**
 * Only applicable to event object that are normally rendering(`rendering` prop)
 * @see https://fullcalendar.io/docs/v4/event-object
 */
const getRenderingEventColor = (isConfirmed, isBid) => {
    if (isBid) {
        return isConfirmed ? colors.affectConfirmedBidColor : colors.affectNotConfirmedBidColor;
    }
    return isConfirmed ? colors.affectConfirmedColor : colors.affectNotConfirmedColor;
}

const generateEventDurationText = (id, calendarApi = null) => {
    const event = calendarApi?.getEventById(id);
    if (!event) {
        return `Not defined.`
    }

    const { start, end } = event;
    let hoursDiff = momentBusinessDays(end).diff(start, 'hours');
    if (moment(start).endOf('week').diff(end) < 0) hoursDiff -= 48;
    const [quotient, reminder] = [Math.floor(hoursDiff / 24), Math.round((hoursDiff % 24) / 3)];
    const daysAmountText = quotient === 0 ? '' : `${quotient} day(s)`;
    const hoursAmountText = reminder === 0 ? '' : `${reminder} hour(s)`;

    return `${daysAmountText}${(quotient === 0 || reminder === 0) ? '' : ', '}${hoursAmountText}`;
}

export {
    addEventToFullCalendar,
    addBackgroundEventToFullCalendar,
    updateEventInFullCalendar,
    updateBackgroundEventInFullCalendar,
    getStartWorkHour,
    getEndWorkHour,
    isHalfDaySlot,
    getRenderingEventColor,
    generateEventDurationText,
}

function toastUndone() {
    SnackbarUtils.toast('Undone');
}

export const undo = {
    // affectation undos
    addAffectation: function (id, calendarApi = null) {
        affectationService
            .deleteAffectation(id)
            .then(() => {
                calendarApi?.getEventById(id)?.remove()
                toastUndone();
            });
    },
    editAffectation: function (id, data, calendarApi = null) {
        affectationService
            .updateAffectation(id, data)
            .then(res => {
                const oldData = res.data;
                updateEventInFullCalendar(
                    calendarApi?.getEventById(id),
                    {
                        start: oldData.startDateTime.split('+')[0],
                        end: oldData.endDateTime.split('+')[0],
                        resourceId: oldData.assignedUser.id,
                        exigencyLevel: oldData.exigencyLevel,
                        notes: oldData.notes,
                    }
                );
                toastUndone();
            })
    },
    deleteAffectation: function (data, calendarApi = null) {
        affectationService
            .createAffectation(data)
            .then(res => {
                addEventToFullCalendar(res.data, calendarApi)
                toastUndone();
            });
    },
    copyPrevWeekAffectations: function (ids = [], calendarApi = null) {
        affectationService
            .bulkDeleteAffectations({
                ids
            })
            .then(() => {
                ids.forEach(
                    id => calendarApi?.getEventById(id)?.remove()
                );
                toastUndone();
            });
    },
    approveRequests: function (ids = [], calendarApi = null) {
        affectationService
            .reviewRequests(ids, false)
            .then(res => {
                res.data[HYDRA_MEMBER].forEach(affectation => {
                    const event = calendarApi?.getEventById(affectation.id);
                    updateEventInFullCalendar(
                        event,
                        {
                            isConfirmed: false
                        }
                    )
                });
                toastUndone();
            })
    },
    addRecurrentAffectation: function (rRuleId, resourceId = null, calendarApi = null) {
        recurrenceRuleService
            .deleteRecurrenceRule(rRuleId)
            .then(() => {
                const resource = calendarApi?.getResourceById(resourceId);
                if (null !== resource) {
                    resource.getEvents()?.forEach(event =>
                        event.rendering !== 'background' &&
                        rRuleId === event.extendedProps?.recurrenceRule?.id &&
                        event.remove()
                    )
                }
                toastUndone();
            })
    },
    // Day-off undos
    addDayOff: function (id, calendarApi = null) {
        dayOffService
            .deleteDayOff(id)
            .then(() => {
                calendarApi?.getEventById(`${EVENT_DAY_OFF_PREFIX}${id}`)?.remove()
                toastUndone();
            });
    },
    deleteDayOff: function (data, calendarApi = null) {
        dayOffService
            .createDayOff(data)
            .then(res => {
                addBackgroundEventToFullCalendar(res.data, calendarApi);
                toastUndone();
            })
    },
    editDayOff: function (id, data, calendarApi = null) {
        dayOffService
            .updateDayOff(id, data)
            .then(res => {
                const oldData = res.data;
                updateBackgroundEventInFullCalendar(
                    calendarApi?.getEventById(`${EVENT_DAY_OFF_PREFIX}${id}`),
                    {
                        start: oldData.startDateTime.split('+')[0],
                        end: oldData.endDateTime.split('+')[0],
                        resourceId: oldData?.userDayOff?.id,
                        office: oldData.office,
                        typeDayOff: oldData.typeDayOff
                    }
                );
                toastUndone();
            })
    },
    addRecurrentDayOff: function (rRuleId, resourceId = null, calendarApi = null) {
        recurrenceRuleService
            .deleteRecurrenceRule(rRuleId)
            .then(() => {
                const resource = calendarApi?.getResourceById(resourceId);
                if (null !== resourceId) {
                    resource.getEvents()?.forEach(event =>
                        event.rendering === 'background' &&
                        rRuleId === event.extendedProps?.recurrenceRule?.id &&
                        event.remove()
                    );
                }
                toastUndone();
            })
    }
}
