import React from 'react';
import { Select, InputLabel, FormControl } from '@material-ui/core';

export default function SelectBox(props) {
    return (
        <FormControl key={props.name} variant="outlined" >
            <InputLabel htmlFor={props.name} >{props.label}</InputLabel>
            <Select
                native
                name={props.name}
                label={props.label}
                disabled={props.disabled}
                required={props.required}
                defaultValue={props.defaultValue}
                onChange={props.onChangeData}
                style={{
                    height: '50px',
                    width: props.name === 'userdayoff' ? '250px' : '180px',
                }}
                inputProps={{
                    name: props.name,
                    id: props.name,
                }}
            >
                {props.none &&
                    <option
                        key={0}
                        value={0}
                    >
                        {' '}
                    </option>
                }
                {props.dataArray.map((opt, index) =>
                    <option
                        key={index}
                        value={opt[props.optValueKey]}
                    >
                        {opt[props.optLabelKey]}
                    </option>
                )}
            </Select>
        </FormControl>
    )
}
