import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';

import variables from '../../../../assets/Styles/variables.module.scss';

function NavigationItemDrawer(props) {
  const { icon, primary, to, activeItem, activeVal } = props;
  const activeStyle = { backgroundColor: '#372b4f', borderLeft: '3px solid #676e7a' };
  const noActiveStyle = { backgroundColor: variables.mainColor, borderLeft: `3px solid ${variables.mainColor}` };
  const itemStyle = activeItem === activeVal ? activeStyle : noActiveStyle;

  const renderLink = React.useMemo(() =>
    React.forwardRef((itemProps, ref) =>
      <NavLink
        {...itemProps}
        ref={ref}
        to={to}
      />
    ), [to],
  );

  const navItem = (
    <ListItem
      button
      component={renderLink}
      style={itemStyle}
    >
      {icon &&
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      }
      <ListItemText
        primary={primary}
        style={{ color: '#dad4d4' }}
      />
    </ListItem>
  );

  return (
    <li onClick={() => props.handleActive()}>
      {props.openDrawerState ?
        navItem :
        <Tooltip
          placement="right"
          title={primary || ''}
        >
          {navItem}
        </Tooltip>
      }
    </li>
  )
}

const mapStateToProps = state => {
  return {
    // auth
    openDrawerState: state.uiUtils.sideDrawerOpen,
  };
};

export default connect(mapStateToProps, null)(NavigationItemDrawer)
