import { appService } from "../../services/appService"
import { DBAL_TYPES } from "../../services/utils/apiResources"
import { getMainRole } from "../../shared/utility";
import * as actionTypes from '../actions/actions-types';

const fetchDBALData = (exigencyLevelTypes, dayOffTypes) => {
    return {
        type: actionTypes.FETCH_APP_DBAL,
        exigencyLevelTypes,
        dayOffTypes,
    }
}

export const fetchAppDBAL = () => {
    return dispatch => {
        Promise
            .all([
                appService.getDBAL(DBAL_TYPES.EXIGENCY_LEVEL),
                appService.getDBAL(DBAL_TYPES.DAY_OFF),
            ])
            .then((results = []) => dispatch(
                fetchDBALData(
                    results[0]?.data || [],
                    results[1]?.data || []
                )
            ));
    }
}

const fetchMeSuccess = (loggedUser) => {
    return {
        type: actionTypes.FETCH_APP_ME_SUCCESS,
        userId: loggedUser.userId,
        fullName: loggedUser.fullName,
        roles: getMainRole(loggedUser.roles),
        service: loggedUser.service,
    }
}

const fetchMeFail = (error) => {
    return {
        type: actionTypes.FETCH_APP_ME_FAIL,
        error,
    }
}

const fetchMeStart = () => {
    return {
        type: actionTypes.FETCH_APP_ME_START,
    }
}

export const fetchMe = () => {
    return dispatch => {
        dispatch(fetchMeStart());
        appService
            .getMe()
            .then(res => res.status === 200 && dispatch(fetchMeSuccess(res.data)))
            .catch(err => dispatch(fetchMeFail(err)));
    }
}
