import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    Paper,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

import RepeatRoundedIcon from '@material-ui/icons/RepeatRounded';
import * as actions from '../../../../store/actions/index';
import SelectBox from '../../SelectBox/SelectBox';
import DateRange1 from '../../DateRange/DateRange1';
import RadioGroups from '../../RadioGroups/RadioGroups';
import AutoCompleteGlobal from '../../AutoComplete/AutoCompleteGlobal';
import RecurrenceSelect from '../../Select/Recurrence/RecurrenceSelect';
import {
    DATE_TIME_FORMAT,
    toTitleCase,
    updateObject,
    DATE_FORMAT,
} from '../../../../shared/utility';
import RecurrenceTargetEventModal from '../../Select/Recurrence/RecurrenceTargetEventModal';
import { buildRrulePayload, RESOURCE_ACTIONS, TARGET_EVENT } from '../../../../shared/rRule/rrule';
import { API_RESOURCES, API_URL } from '../../../../services/utils/apiResources';
import { DAY_OFF_FILTERS, RECURRENT_RESOURCES_FILTERS } from '../../../../services/utils/filters';
import { dayoffDataTypes, halfDayData, halfDayType, setDayOffStartEnd } from '../../../../shared/dayOffUtility'
import { EVENT_DAY_OFF_PREFIX, generateEventDurationText } from '../../../../shared/fullCalendarUtility';
import { canCUDDayOff } from '../../../../shared/authorizationUtility';

import classes from '../ModalUIAdd/ModalUI.module.scss';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-view-day-off-modal" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const DeleteDayOffModal = props => <> <Dialog
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">
        Delete Day Off
    </DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Are you sure , you want to delete this Day off ?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={props.onClose} color="primary">
            Cancel
        </Button>
        <Button onClick={props.onDelete} color="primary" autoFocus>
            Confirm
        </Button>
    </DialogActions>
</Dialog>
</>

const ModalUIViewDayOff = React.forwardRef(({ dayOffObj, ...props }, ref) => {
    const [open, setOpen] = React.useState(false)
    const [toEdit, setToEdit] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [openRange, setOpenRange] = React.useState(false);
    const [dayOff, setDayOff] = React.useState({
        idDayOff: null,
        actionType: 'standard',
        dayoffType: null,
        selectedDateRange: [],
        halfDayStart: halfDayType.HALF_DAY,
        halfDayEnd: halfDayType.HALF_DAY,
        userSelectDayoff: null,
        userOrOffice: dayOffObj.office ? 1 : 0,
        offices: dayOffObj.office || [],
    });
    const [rrule, setRrule] = React.useState({});
    const [touched, setTouched] = React.useState(false);
    const eventTargetModalRef = React.useRef();
    const [calendarApi, setCalendarApi] = React.useState(null);

    // TO Open modal from a parent component
    React.useImperativeHandle(ref, () => ({
        openModal() {
            setOpen(true)
        }
    }));

    React.useEffect(() => {
        if (props.calendarRef) {
            setCalendarApi(props.calendarRef?.current?.getApi())
        }
    }, [props.calendarRef]);

    React.useEffect(() => {
        if (dayOffObj) {
            const startMoment = moment(dayOffObj.startDateTime)
            const endMoment = moment(dayOffObj.endDateTime)
            const user = props.users.find(userItem => userItem.id === dayOffObj.userDayOff && !userItem.isFictive)

            setRrule(dayOffObj?.rRule || {})
            setDayOff({
                idDayOff: dayOffObj.id,
                actionType: 'init',
                dayoffType: dayOffObj?.typeDayOff || null,
                selectedDateRange: [
                    startMoment.format(DATE_TIME_FORMAT),
                    endMoment.format(DATE_TIME_FORMAT)
                ],
                halfDayStart: startMoment.hour() === 12 ? halfDayType.HALF_DAY : halfDayType.ALL_DAY,
                halfDayEnd: endMoment.hour() === 12 ? halfDayType.HALF_DAY : halfDayType.ALL_DAY,
                userSelectDayoff: user ? {
                    id: user.id,
                    fullname: user.fullName
                } : null,
                userOrOffice: dayOffObj.typeDayOff === 'Holiday' ? 1 : 0,
                offices: dayOffObj.office || []
            })
        }
    }, [dayOffObj, props.users])

    const closeModals = () => {
        setTouched(false);
        setToEdit(false);
        setOpenRange(false);
        setOpen(false);
        setIsDeleteModalOpen(false);
    }

    const onChangeDayOffInputs = (inputName, e) => {
        let updatedElements = null;
        switch (inputName) {
            case 'standard':
            case 'switchHalfDayToggle':
                updatedElements = updateObject(dayOff, {
                    [e.target.name]: e.target.value
                });
                setDayOff(updatedElements);
                break;

            case 'rangeDate':
                updatedElements = updateObject(dayOff, {
                    selectedDateRange: e,
                    actionType: 'standard',
                });
                setDayOff(updatedElements);
                setOpenRange(e[1] ? false : true)
                break;

            case 'offices':
                const offices1 = [...dayOff.offices];
                const index = offices1.indexOf(e);
                e.checked = !e.checked;
                offices1[index] = { ...e };

                updatedElements = updateObject(dayOff, {
                    offices: [...offices1]
                });
                setDayOff(updatedElements);
                setRrule({});
                break;

            case 'userdayoffauto':
                updatedElements = updateObject(dayOff, {
                    userSelectDayoff: e
                });
                setDayOff(updatedElements);
                break;

            default:
        }
        setTouched(true);
    }

    const handleEdit = (recurrenceTargetType = null) => {
        // Fullcalendar Interaction actionTypes are [`multiSelect`, `cellClick`, `standard`].
        const {
            idDayOff,
            actionType,
            selectedDateRange,
            halfDayStart,
            halfDayEnd,
            userOrOffice,
            userSelectDayoff,
            dayoffType,
            offices,
        } = dayOff;
        const prevUserId = parseInt(dayOffObj.userDayOff);
        const user = userSelectDayoff?.id;

        const dayOffRange = setDayOffStartEnd(actionType, selectedDateRange, halfDayStart, halfDayEnd);
        const startDayBody = dayOffRange[0];
        const endDayBody = dayOffRange[1];

        let checkedOffices = [];
        if (parseInt(userOrOffice) === 1) {
            offices.forEach(x => {
                if (x.checked) {
                    checkedOffices.push(x.label)
                }
            });
        } else {
            checkedOffices = null;
        }

        let dayOffBody = {
            "typeDayOff": dayoffType,
            "startDateTime": startDayBody,
            "endDateTime": endDayBody,
            "office": checkedOffices,
        }

        if (parseInt(userOrOffice) === 0 && user) {
            dayOffBody = {
                ...dayOffBody,
                "userDayOff": `${API_URL}${API_RESOURCES.USERS}/${user}`
            };
        }

        /**
         * We can distinguich 5 scenarios:
         * 1st: default rRule exist `{...}` and is updated to none `{}` (if case --> `/doesNotRepeat`);
         * 2nd: default rRule exist `{...}` and is updated to another `{...}` (else case -->`/updateRRule?updateRRule=true`);
         * 3rd: default rRule exist `{...}` and isn't updated (else case -->`/updateRRule?updateRRule=false`);
         * 4th: no defaultRrule `{}` and we define a rRule `{...}` (simpleUpdate);
         * 5th: no defaultRrule `{}` and isn't updated (simpleUpdate);
         */
        const isRRuleStateEmpty = isEmpty(rrule);
        const isDefaultRruleEmpty = isEmpty(dayOffObj.rRule);
        if (!isDefaultRruleEmpty && isRRuleStateEmpty) {
            const targetResourcesIds = [
                user,
                ...(prevUserId !== 0 && parseInt(user) !== prevUserId) ? [prevUserId] : []
            ]
            props.onBreakRecurrentDayOff(
                idDayOff,
                targetResourcesIds,
                {
                    ...props.dayOffQueryParams,
                    [DAY_OFF_FILTERS.USER_DAY_OFF_ID_EXACT]: targetResourcesIds
                },
                calendarApi
            );
        } else {
            const isSameRRule = rrule === dayOffObj.rRule;
            const isRRuleRemained = (!isRRuleStateEmpty && !isDefaultRruleEmpty);

            if (isRRuleRemained) {
                const targetResourcesIds = [
                    user,
                    ...(prevUserId !== 0 && parseInt(user) !== prevUserId) ? [prevUserId] : []
                ]
                props.onEditRecurrentDayOff(
                    idDayOff,
                    {
                        ...dayOffBody,
                        ...(!isSameRRule && buildRrulePayload(rrule))
                    },
                    targetResourcesIds,
                    {
                        [RECURRENT_RESOURCES_FILTERS.UPDATE_R_RULE]: !isSameRRule,
                        [RECURRENT_RESOURCES_FILTERS.THIS_AND_THE_FOLLOWING_EVENTS]: recurrenceTargetType?.toString() === TARGET_EVENT.THIS_AND_THE_FOLLOWING
                    },
                    {
                        ...props.dayOffQueryParams,
                        [DAY_OFF_FILTERS.USER_DAY_OFF_ID_EXACT]: targetResourcesIds
                    },
                    calendarApi
                )
            } else {
                props.onEditDayOff(
                    idDayOff,
                    dayOffBody,
                    calendarApi
                );
            }
        }

        closeModals();
    }

    const handleDelete = (target = null) => {
        const { idDayOff, userSelectDayoff } = dayOff;
        const user = userSelectDayoff?.id || 0;
        /**
         * We distinguish 3 use case:
         * 1st: recurrent day-off to delete and its following (`/this_and_following_dayoffs`);
         * 2nd: only this recurrent day-off to delete (simple delete);
         * 3rd: non-recurrent day-off to delete (simple delete);
         */
        target?.toString() === TARGET_EVENT.THIS_AND_THE_FOLLOWING ?
            props.onDeleteRecurrentDayOffAndFollowing(
                idDayOff,
                [user],
                {
                    ...props.dayOffQueryParams,
                    [DAY_OFF_FILTERS.USER_DAY_OFF_ID]: [user]
                },
                calendarApi
            ) : props.onDeleteDayOff(
                idDayOff,
                calendarApi
            )

        closeModals();
    }

    const isRecurrentDayOff = !isEmpty(rrule);
    const openRecurrenceTargetToEditModal = () => eventTargetModalRef.current.openModal(RESOURCE_ACTIONS.ACTION_UPDATE);
    const openRecurrenceTargetToDeleteModal = () => eventTargetModalRef.current.openModal(RESOURCE_ACTIONS.ACTION_DELETE);

    const isSingleResourceDayOff = parseInt(dayOff.userOrOffice) === 0;
    const getResourceDayOffText = () => {
        if (isSingleResourceDayOff) {
            return dayOff.userSelectDayoff && dayOff.userSelectDayoff.fullname;
        }

        return dayOff.offices.reduce((officeText, office, index, { length }) => {
            if (office.checked) {
                officeText += `${office.label}${index < length - 1 ? ', ' : ''}`;
            }
            return officeText;
        }, '');
    }

    return <div>
        <Dialog
            disableEnforceFocus
            open={open}
            onClose={closeModals}
            PaperComponent={PaperComponent}
            scroll="body"
            aria-labelledby="draggable-view-day-off-modal"
        >
            {open && <>
                <DialogTitle disableTypography id="draggable-view-day-off-modal">
                    <>
                        Day Off Details
                        {!toEdit && canCUDDayOff() &&
                            <>
                                <Button onClick={() => setToEdit(true)} >
                                    Edit
                                </Button>
                                <Button onClick={() => setIsDeleteModalOpen(true)}>
                                    Delete
                                </Button>
                                <DeleteDayOffModal
                                    open={isDeleteModalOpen}
                                    onClose={closeModals}
                                    onDelete={() => isRecurrentDayOff ? openRecurrenceTargetToDeleteModal() : handleDelete()}
                                />
                            </>
                        }
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </>
                </DialogTitle>
                <DialogContent>
                    {
                        toEdit ?
                            <React.Fragment>
                                <DialogContent dividers key="dayoff">
                                    <Grid item>
                                        <SelectBox
                                            name="dayoffType"
                                            label="Day Off type"
                                            defaultValue={dayOff.dayoffType}
                                            dataArray={props.dayOffTypes}
                                            none={true}
                                            required={true}
                                            // for options
                                            optValueKey="value"
                                            optLabelKey="label"
                                            onChangeData={event => onChangeDayOffInputs('standard', event)}
                                        />
                                    </Grid>
                                    <br />

                                    <Grid container direction="row" style={{ minWidth: 445 }}>
                                        <DateRange1
                                            onOpen={() => setOpenRange(true)}
                                            open={openRange}
                                            value={dayOff.selectedDateRange}
                                            onChangeRange={(rangeDates) => onChangeDayOffInputs('rangeDate', rangeDates)}
                                        />

                                        <div className={classes.divitem}>
                                            <div className={classes.divStartHalfDay}>
                                                <RadioGroups
                                                    name="halfDayStart"
                                                    label="Start Half/All Day"
                                                    defaultValue={dayOff.halfDayStart}
                                                    dataArray={halfDayData}
                                                    required={false}
                                                    // for options
                                                    optValueKey="value"
                                                    optLabelKey="label"
                                                    onChangeData={(e) => onChangeDayOffInputs('switchHalfDayToggle', e)}
                                                />
                                            </div>

                                            <div>
                                                <RadioGroups
                                                    name="halfDayEnd"
                                                    label="End Half/All Day"
                                                    defaultValue={dayOff.halfDayEnd}
                                                    dataArray={halfDayData}
                                                    required={false}
                                                    // for options
                                                    optValueKey="value"
                                                    optLabelKey="label"
                                                    onChangeData={(e) => onChangeDayOffInputs('switchHalfDayToggle', e)}
                                                />
                                            </div>
                                        </div>
                                    </Grid>
                                    <br />

                                    <Grid container direction="row">
                                        <Grid item>
                                            {
                                                dayOff.userOrOffice === 0 && <>
                                                    <FormLabel component="legend">Resource:</FormLabel>
                                                    <AutoCompleteGlobal
                                                        onChange={(_e, v) => onChangeDayOffInputs('userdayoffauto', v)}
                                                        value={dayOff.userSelectDayoff}
                                                        id="country-select-demo"
                                                        options={props.users.reduce((usersArr, user) => {
                                                            if (!user.isFictive) {
                                                                usersArr.push({
                                                                    id: user.id,
                                                                    fullname: user.fullName,
                                                                });
                                                            }
                                                            return usersArr;
                                                        }, [])}
                                                        getOptionSelected={(option1, option2) => parseInt(option1.id) === parseInt(option2.id)}
                                                        getOptionLabel={(option) => option.fullname}
                                                        renderOption={(option) => (
                                                            <React.Fragment>
                                                                <Avatar
                                                                    style={{
                                                                        borderColor: 'black',
                                                                        backgroundColor: 'black',
                                                                        color: 'white',
                                                                        borderWidth: 'thin',
                                                                        width: '25px',
                                                                        height: '25px',
                                                                        fontSize: '10px'
                                                                    }}
                                                                    key={option.id}
                                                                >{toTitleCase(option.fullname)}
                                                                </Avatar>
                                                                {option.fullname}
                                                            </React.Fragment>
                                                        )}
                                                        label="Choose a resource"
                                                    />
                                                    <br />
                                                </>
                                            }

                                            {dayOff.userOrOffice === 1 && <>
                                                <FormLabel component="legend">Office:</FormLabel>
                                                {
                                                    dayOff.offices.map((data) => {
                                                        return (
                                                            <Chip
                                                                disabled={parseInt(dayOff.userOrOffice) === 0}
                                                                key={data.key}
                                                                color={data.checked === true ? 'primary' : 'default'}
                                                                icon={<DoneIcon />}
                                                                label={data.label}
                                                                onClick={() => onChangeDayOffInputs('offices', data)}
                                                            />
                                                        )
                                                    })
                                                }
                                            </>}
                                        </Grid>
                                    </Grid>
                                    <br />

                                    {
                                        parseInt(dayOff.userOrOffice) === 0 &&
                                        dayOff.dayoffType !== dayoffDataTypes.HOLIDAY &&
                                        <Grid container direction="column" alignItems="baseline">
                                            <FormLabel style={{ color: 'black' }}>
                                                <strong>
                                                    Other options
                                                </strong>
                                            </FormLabel>
                                            <Box style={{ borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'baseline' }}>
                                                <span><RepeatRoundedIcon /> Frequency :</span>
                                                <RecurrenceSelect
                                                    toEdit
                                                    defaultRcurrenceOption={dayOffObj.rRule}
                                                    startDate={dayOff.selectedDateRange[0]}
                                                    setRrule={rRule => {
                                                        setRrule(rRule)
                                                        setTouched(true)
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    }
                                </DialogContent>
                            </React.Fragment> :
                            <React.Fragment>
                                <div className={classes.divitem}>
                                    <p style={{ paddingTop: 15 }}>
                                        <strong>{isSingleResourceDayOff ? 'Resource' : 'Office(s)'} :</strong>
                                        {getResourceDayOffText()}
                                    </p>
                                </div>
                                <Divider variant="middle" />

                                <div className={classes.divitem}>
                                    <p style={{ paddingTop: '15px' }}><strong>Type :</strong>
                                        {dayOff.dayoffType || 'N/A'}
                                    </p>
                                </div>
                                <Divider variant="middle" />

                                <div className={classes.divitem}>
                                    <p style={{ paddingTop: '15px' }}><strong>Duration :</strong>
                                        {generateEventDurationText(`${EVENT_DAY_OFF_PREFIX}${dayOff.idDayOff}`, calendarApi)}
                                    </p>
                                </div>
                                <Divider variant="middle" />

                                <div className={classes.divitem}>
                                    <p style={{ paddingTop: '15px' }}><strong>Date Range :</strong>
                                        {` ${moment(dayOff.selectedDateRange[0]).format(DATE_FORMAT)} - ${moment(dayOff.selectedDateRange[1]).format(DATE_FORMAT)}`}
                                    </p>
                                </div>
                                <Divider variant="middle" />

                                <div className={classes.divitem}>
                                    <p style={{ paddingTop: '15px' }}><strong>Frequency: </strong>
                                        {`${rrule?.frequency || 'Does not repeat'}`}
                                    </p>
                                </div>
                            </React.Fragment>
                    }
                </DialogContent>
                {
                    toEdit && <DialogActions>
                        <Button
                            autoFocus
                            type="submit"
                            color="primary"
                            variant="outlined"
                            onClick={() => setToEdit(false)}
                        >
                            cancel
                        </Button><Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!touched || !dayOff.dayoffType}
                            onClick={() => isRecurrentDayOff ? openRecurrenceTargetToEditModal() : handleEdit()}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                }
            </>}
        </Dialog>
        <RecurrenceTargetEventModal
            resource="day-off"
            ref={eventTargetModalRef}
            onDone={(recurrenceTargetType, action) => action === RESOURCE_ACTIONS.ACTION_UPDATE ?
                handleEdit(recurrenceTargetType) :
                handleDelete(recurrenceTargetType)
            }
        />
    </div>;
});

const mapStateToProps = state => {
    return {
        // app reducers
        dayOffTypes: state.app.dayOffTypes,
        // users reducers
        users: state.user.users,
        loading: state.user.loading,
        // day-off reducers
        dayOffLoading: state.dayoff.loading,
        dayoffs: state.dayoff.dayoffs,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditDayOff: (id, dayOffBody, calendarApi) => dispatch(actions.editDayOff(id, dayOffBody, calendarApi)),
        onDeleteDayOff: (idDayoff, calendarApi) => dispatch(actions.deleteDayoff(idDayoff, calendarApi)),
        // on recurrent actions
        onEditRecurrentDayOff: (id, data, resourcesIds, queryParams, dayOffQueryParams, calendarApi) =>
            dispatch(actions.editRecurrentDayOff(id, data, resourcesIds, queryParams, dayOffQueryParams, calendarApi)),
        onBreakRecurrentDayOff: (id, resourcesIds, dayOffQueryParams, calendarApi) =>
            dispatch(actions.breakRecurrentdayOff(id, resourcesIds, dayOffQueryParams, calendarApi)),
        onDeleteRecurrentDayOffAndFollowing: (id, resourcesIds, dayOffQueryParams, calendarApi) =>
            dispatch(actions.deleteRecurrentDayOffAndFollowing(id, resourcesIds, dayOffQueryParams, calendarApi)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalUIViewDayOff)
