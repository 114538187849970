import React from "react";
import {
    FormControl,
    Chip,
    TextField,
    InputAdornment
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import classes from "./PlanningProjectSelectFilter.module.scss";

const PlanningProjectSelectFilter = ({ loading, setSelected, selected = [], projects = [] }) => {
    React.useEffect(() => {
        if (loading) {
            setSelected([]);
        }
    }, [loading, setSelected])

    return (
        <div className={classes.select}>
            <FormControl className={classes.formControl}>
                <Autocomplete
                    multiple
                    id="planning-projects-filter"
                    clearText="Clear all"
                    popupIcon={null}
                    options={projects.sort((a, b) => a.localeCompare(b))}
                    getOptionLabel={(option) => option}
                    value={selected}
                    onChange={(_e, projects) => setSelected(projects)}
                    renderInput={(params) => (
                        <div className={classes.input}>
                            <TextField
                                {...params}
                                label=""
                                variant="standard"
                                placeholder="Select projects"
                                InputLabelProps={{
                                    style: { color: 'yellow' }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FilterListIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    )}
                />
            </FormControl>
            <div>
                {selected.map((projectName) => (
                    <Chip
                        deleteIcon={<ClearRoundedIcon />}
                        key={projectName}
                        label={projectName}
                        className={classes.chip}
                        onDelete={() => setSelected(selected.filter(item => item !== projectName))}
                    />
                ))}
            </div>
        </div>
    );
};

export default PlanningProjectSelectFilter;
