import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    sideDrawerOpen: false,
    mainServiceId: 0,
}

const setDrawerOpenstate = (state, action) => {
    return updateObject(state, {
        sideDrawerOpen: action.openState
    })
}

// with return ~~~~ implmentation + break
const uiUtilsReducer = (state = initialState, action = {}) => {
    if (action.type === actionTypes.SET_DRAWER_STATE) {
        return setDrawerOpenstate(state, action);
    }
    return state;
}

export default uiUtilsReducer;
