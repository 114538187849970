export const PATH_PROFILE = '/profile';
export const PATH_USERS = '/users';
export const PATH_PROJECTS = '/projects';
export const PATH_RESET = '/reset';
export const PATH_DASHBOARD = '/dashboard';
export const PATH_REPORTS = '/reports';
export const PATH_SETTINGS = '/settings';

export const NOT_AUTHORIZED_LOGGED_OUT_ROUTES = [
    PATH_PROFILE,
    PATH_USERS,
    PATH_PROJECTS,
    PATH_RESET,
    PATH_DASHBOARD,
    PATH_REPORTS,
    PATH_SETTINGS,
];
