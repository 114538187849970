import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce, isEqual } from 'lodash';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';

import * as actions from '../../store/actions/index';
import { updateObject } from '../../shared/utility';
import { PROJECT_FILTERS } from '../../services/utils/filters';
import BidProjectSpan from '../../components/UI/Span/BidProjectSpan';

import classes from './Projects.module.css';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            itemsPerPage: 50,
            keyFilters: {
                name: '',
                description: '',
                projectManagerName: '',
            }
        }
    }

    getProjectQueryParams = () => {
        const { itemsPerPage, page } = this.state;
        const { description, projectManagerName, name } = this.state.keyFilters;

        return {
            [PROJECT_FILTERS.PAGE]: page + 1,
            [PROJECT_FILTERS.ITEMS_PER_PAGE]: itemsPerPage,
            [PROJECT_FILTERS.NAME]: name,
            [PROJECT_FILTERS.DESCRIPTION]: description,
            [PROJECT_FILTERS.PROJECT_MANAGER_FULL_NAME]: projectManagerName,
            [PROJECT_FILTERS.PAGINATION]: true,
        }
    }

    componentDidMount() {
        this.props.onFetchProjects(this.getProjectQueryParams());
    }

    componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(prevState, this.state)) {
            this.props.onFetchProjects(this.getProjectQueryParams())
        }
    }

    onPageChangeHandler = (_e, newPage) => {
        this.setState({ page: newPage })
    }

    onRowsPerPageHandler = (pageSize) => {
        this.setState({
            itemsPerPage: pageSize,
            page: 0
        });
    }

    waitFilterUp = debounce((e) => {
        const g = [...e];
        let updatedElements = {
            name: '',
            description: '',
            projectManagerName: '',
        }
        g.forEach(element => {
            updatedElements = updateObject(updatedElements, {
                [element.column.field]: element.value
            })
        })
        this.setState({
            keyFilters: updatedElements,
            page: 0,
        });
    }, 500);

    render() {
        const { page, itemsPerPage } = this.state;
        const projectsTableData = [];
        const columns = [
            { title: '#', field: 'counter', editable: 'never', filtering: false },
            {
                title: 'Project Name',
                field: 'name',
                editable: 'never',
                render: rowData => <>{rowData.isBid && <BidProjectSpan />}{rowData.name}</>
            },
            { title: 'Description', field: 'description', editable: 'never' },
            { title: 'Project Manager', field: 'projectManagerName', editable: 'never' },
        ]

        let i = (itemsPerPage * page) + 1;
        for (const key in this.props.projects) {
            projectsTableData.push({
                ...this.props.projects[key],
                projectManagerName: this.props.projects[key].projectManager?.fullName || null,
                counter: i
            });
            i++;
        }

        const redirectToProject = (e, project) => {
            e.preventDefault();
            window.open(`${process.env.REACT_APP_TT_URL}/project/${project.externalId}`, '_blank');
        }

        return (
            <div className={classes.bigDiv}>
                <div>
                    <center><h2>Projects List</h2></center>
                </div>
                <MaterialTable
                    title=""
                    onRowClick={redirectToProject}
                    columns={columns}
                    data={projectsTableData}
                    isLoading={this.props.loading}
                    onChangeRowsPerPage={this.onRowsPerPageHandler}
                    onFilterChange={(e) => this.waitFilterUp(e)}
                    options={{
                        actionsColumnIndex: -1,
                        pageSizeOptions: [50, 100, 200],
                        pageSize: this.state.itemsPerPage,

                        headerStyle: {
                            position: 'sticky',
                            top: 0,
                            fontSize: '25px',
                            width: "auto",
                            tableLayout: "auto"
                        },
                        filtering: true,
                        search: false,
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={this.props.total}
                                page={this.state.page}
                                rowsPerPage={this.state.itemsPerPage}
                                onChangePage={this.onPageChangeHandler}
                            />
                        ),
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.project.projects,
        loading: state.project.loading,
        total: state.project.totalcount,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchProjects: (queryParams) => dispatch(actions.fetchProjects(queryParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
