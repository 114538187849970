import React from 'react';
import CardProfile from '../../components/UI/Card/CardProfile/CardProfile';

export default function MyProfile() {
    return (
        <div>
            <CardProfile />
        </div>
    )
}
