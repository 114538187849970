import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store, { history } from './store/store';
import defaultMUITheme from './assets/themes/defaultMUITheme';
import SnackbarProviderSetup from './hoc/SnackBar/SnackbarProviderSetup';

import './index.css';

const SENTRY_FRONT_URL = process.env.REACT_SENTRY_URL;
Sentry.init({ dsn: SENTRY_FRONT_URL });

const app = (
  <>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <ThemeProvider theme={defaultMUITheme}>
            <SnackbarProviderSetup>
              <App />
            </SnackbarProviderSetup>
          </ThemeProvider>
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  </>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
