import React, { Component } from 'react';
import moment from 'moment';
import {debounce, isEmpty} from 'lodash';
import Draggable from 'react-draggable';
import {connect} from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Paper,
    IconButton,
    Divider,
    Zoom,
    Grid,
    FormLabel,
    Box,
    Avatar,
    FormControlLabel,
    Radio,
    TextField,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    Check as CheckIcon,
    BorderColorRounded as BorderColorRoundedIcon,
    PersonRounded as PersonRoundedIcon,
    DateRange as DateRangeIcon,
    PriorityHighRounded as PriorityHighRoundedIcon,
    NotesRounded as NotesRoundedIcon,
    RepeatRounded as RepeatRoundedIcon,
    HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import { LocalizationProvider, TimePicker } from '@material-ui/pickers';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";

import * as actions from '../../../../store/actions/index';
import {
    updateObject,
    transformDaySlot,
    transformEndDate,
    findObjInArray,
    toTitleCase,
    DATE_TIME_FORMAT,
    DATE_FORMAT
} from '../../../../shared/utility';
import BigTextField from '../../BigTextFiled/BigTextField';
import SelectBox from '../../SelectBox/SelectBox';
import Toggle from '../../Toggle/Toggle';
import DatePickerForm from '../../DatePicker/DatePickerForm';
import RadioGroups from '../../RadioGroups/RadioGroups';
import RecurrenceSelect from '../../Select/Recurrence/RecurrenceSelect';
import AutoCompleteGlobal from '../../AutoComplete/AutoCompleteGlobal';
import RecurrenceTargetEventModal from '../../Select/Recurrence/RecurrenceTargetEventModal';
import { API_RESOURCES, API_URL } from '../../../../services/utils/apiResources';
import {AFFECTATION_FILTERS, PROJECT_FILTERS, RECURRENT_RESOURCES_FILTERS} from '../../../../services/utils/filters';
import { buildRrulePayload, RESOURCE_ACTIONS, TARGET_EVENT } from '../../../../shared/rRule/rrule';
import BidProjectSpan from '../../Span/BidProjectSpan';
import { generateEventDurationText, getEndWorkHour, getStartWorkHour } from '../../../../shared/fullCalendarUtility';
import { getmainSubServiceName } from '../../../../shared/userUtility';
import { canApproveAffectation, canDeletePassedAffectation, canUDAffectation } from '../../../../shared/authorizationUtility';

import classes from './ModaUIView.module.scss';

const UPDATE_TYPE_PROJECT = 'editProject';
const UPDATE_TYPE_RESOURCE = 'editResource';
const UPDATE_TYPE_PRIORITY = 'editPriority';
const UPDATE_TYPE_DAY_SLOT = 'editDaySlot';
const UPDATE_TYPE_DATE = 'editDate';
const UPDATE_TYPE_GLOBAL = 'editGlobal';
const UPDATE_TYPE_APPROVE = 'approve';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title1" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

class ModalUIViewAffectation extends Component {
    // event body that full calendar accepts
    constructor(props) {
        super(props);
        this.eventTargetModalRef = React.createRef();
        this.state = {
            open: false,
            openDelete: false,
            affectationData: {
                idAffectation: '',
                project: null,
                isBidProject: false,
                user: null,
                title: '',
                isConfirmed: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                daySlotOrHourly: 0,
                priority: '',
                notes: '',
                projectManagerId: null,
                projectManagerName: '',
                createdById: null,
                createdByName: '',
                userSelect: null,
                projectSelect: null,
            },
            // partial edit
            showEditResource: false,
            showEditProject: false,
            showEditDaySlot: false,
            showEditDate: false,
            showEditPriority: false,
            // disable check partial edit to minimse requests
            partialEditTouched: false,
            daySlot: '',
            // currentView
            currentView: 'viewDetails',
            editType: null,
            rrule: {},
        }
    }

    componentDidMount() {
        this.props.onRef(this.handleClickOpenView.bind(this));
        this.calendarApi = this.props.calendarRef.current.getApi();
    }

    ////////////////////////////  delete inside view dialog
    handlClickOpenDelete = () => {
        this.setState({ openDelete: true })
    }

    handlCloseDelete = () => {
        this.setState({ openDelete: false })
    }

    handleDelete = (target = null) => {
        const { idAffectation, user } = this.state.affectationData;

        /**
         * We distinguish 3 use case:
         * 1st: recurrent affectation to delete and its following (`/this_and_following_affectations`);
         * 2nd: only this recurrent affectation to delete (simple delete);
         * 3rd: non-recurrent affectation to delete (simple delete);
         */
        target?.toString() === TARGET_EVENT.THIS_AND_THE_FOLLOWING ?
            this.props.onDeleteRecurrentAffectaionAndFollowing(
                idAffectation,
                [user],
                {
                    ...this.props.affectQueryParams,
                    [AFFECTATION_FILTERS.ASSIGNED_USER_ID]: [user]
                },
                this.calendarApi
            ) :
            this.props.onDeleteAffectation(idAffectation, this.calendarApi);
        this.setState({
            openDelete: false,
            open: false
        });
    }

    ////////////////////////////// dialog closing + partial and global edit
    //  modal { open + close }
    handleClickOpenView = () => {
        const {
            idAffectation,
            project,
            isBidProject,
            user,
            title,
            isConfirmed,
            startDate,
            endDate,
            startTime,
            endTime,
            daySlotOrHourly,
            priority,
            notes,
            projectManagerId,
            projectManagerName,
            createdById,
            createdByName,
            rRule,
        } = this.props.affectationData;

        const selectedProject = this.props.projects.find(x => parseInt(x.id) === parseInt(project));
        const findedUser = this.props.users.find(x => parseInt(x.id) === parseInt(user));
        const updatedElements = updateObject(this.state.affectationData, {
            idAffectation: idAffectation,
            project: project,
            isBidProject,
            user: user,
            title: title,
            isConfirmed: isConfirmed,
            startDate,
            endDate,
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            daySlotOrHourly,
            priority: priority,
            notes: notes,
            projectManagerId: projectManagerId,
            projectManagerName: projectManagerName,
            createdById: createdById,
            createdByName: createdByName,
            userSelect: {
                id: findedUser?.id || 0,
                fullname: findedUser?.fullName || ' None',
                isFictive: findedUser?.isFictive || true,
                mainSubServiceName: findedUser ? getmainSubServiceName(findedUser) : 'None',
            },
            projectSelect:{
                id: selectedProject?.id || 0,
                name: selectedProject?.name || 'none',
            }
        })
        this.setState({
            open: true,
            affectationData: updatedElements,
            daySlot: transformDaySlot(startDate, endDate),
            rrule: rRule
        });
    }

    handleClose = () => {
        this.setState({
            open: false,
            // close opened partial editors
            showEditDate: false,
            showEditDaySlot: false,
            showEditPriority: false,
            // update the global edit view
            currentView: 'viewDetails'
        });
    };

    // handle prtial edition show / hide
    handleViewPartialEdit = (editType) => {
        switch (editType) {
            case UPDATE_TYPE_RESOURCE:
                this.setState({
                    showEditResource: true,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_DAY_SLOT:
                this.setState({
                    showEditDaySlot: true,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_DATE:
                this.setState({
                    showEditDate: true,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_PRIORITY:
                this.setState({
                    showEditPriority: true,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_PROJECT:
                this.setState({
                    showEditProject: true,
                    partialEditTouched: false,
                })
                break;
            default:
        }
    }

    // toggle general update + details view || get back to previous state when cancel
    handleClickOpenUpdate = (currentViewName) => {
        const { user, startDate, endDate, priority, startTime, endTime, daySlotOrHourly } = this.props.affectationData;
        const findedUser = this.props.users.find(x => parseInt(x.id) === parseInt(user));

        const updatedElements = updateObject(this.state.affectationData, {
            user,
            startDate: moment(startDate).format(DATE_TIME_FORMAT),
            endDate: moment(endDate).format(DATE_TIME_FORMAT),
            priority: priority,
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            daySlotOrHourly,
            userSelect: {
                id: findedUser?.id || 0,
                fullname: findedUser?.fullName || ' None',
                isFictive: findedUser?.isFictive || true,
                mainSubServiceName: findedUser ? getmainSubServiceName(findedUser) : 'None',
            },
        });

        this.setState({
            currentView: currentViewName,
            affectationData: updatedElements,
            daySlot: transformDaySlot(startDate, endDate),
            showEditDaySlot: false,
            showEditDate: false,
            showEditPriority: false,
            partialEditTouched: false
        });
    }

    // get back to previous state for partial edit
    handleCancel = (e) => {
        switch (e) {
            case UPDATE_TYPE_RESOURCE:
                this.setState({
                    showEditResource: false,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_DAY_SLOT:
                this.setState({
                    showEditDaySlot: false,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_DATE:
                this.setState({
                    showEditDate: false,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_PRIORITY:
                this.setState({
                    showEditPriority: false,
                    partialEditTouched: false,
                });
                break;
            case UPDATE_TYPE_PROJECT:
                this.setState({
                    showEditProject: false,
                    partialEditTouched: false,
                })
                break;
            default:
        }

        const { user, startDate, endDate, priority, startTime, endTime, daySlotOrHourly } = this.props.affectationData;

        const findedUser = this.props.users.find(x => parseInt(x.id) === parseInt(user));
        const updatedElements = updateObject(this.state.affectationData, {
            user: user,
            startDate: moment(startDate).format(DATE_TIME_FORMAT),
            endDate: moment(endDate).format(DATE_TIME_FORMAT),
            priority: priority,
            startTime: new Date(startTime),
            endTime: new Date(endTime),
            daySlotOrHourly,
            userSelect: {
                id: findedUser?.id || 0,
                fullname: findedUser?.fullName || ' None',
                isFictive: findedUser?.isFictive || true,
                mainSubServiceName: findedUser ? getmainSubServiceName(findedUser) : 'None',
            },
        })
        this.setState({
            affectationData: updatedElements,
            daySlot: transformDaySlot(startDate, endDate)
        });
    }
    //////////////////////////////////////////   onChange handlers
    handleAutoComplet = (_e, value) => {
        const updatedElements = updateObject(this.state.affectationData, {
            userSelect: value,
            user: value.id
        });

        this.setState({
            affectationData: updatedElements,
            partialEditTouched: true,
        });
    }

    // onChange state daySlot   { update endStartDate }
    onChangeDaySlot = (_e, value) => {
        const { startDate } = this.state.affectationData;
        const updatedElements = updateObject(this.state.affectationData, {
            endDate: transformEndDate(startDate, value)
        });
        this.setState({
            affectationData: updatedElements,
            partialEditTouched: true,
            daySlot: value
        });
    }

    // handle date picker
    onChangeDatePicker = (e) => {
        if (moment(e).isValid()) {
            const dateFormatted = moment(e).format(DATE_TIME_FORMAT);
            const { daySlot } = this.state;
            const updatedElements = updateObject(this.state.affectationData, {
                startDate: dateFormatted,
                endDate: transformEndDate(dateFormatted, daySlot)
            });

            this.setState({
                affectationData: updatedElements,
                partialEditTouched: true,
            });
        }
    }

    handleStartEndTimeOnChange = (date, type = 'start') => {
        const updatedElements = updateObject(
            this.state.affectationData,
            type === 'start' ? { startTime: date } : { endTime: date }
        );
        this.setState({
            affectationData: updatedElements,
            partialEditTouched: true,
        })
    }

    // onChange state standard
    onChangeStandard = (e) => {
        const { name, value } = e.target;
        const updatedElements = updateObject(this.state.affectationData, {
            [name]: value
        });
        this.setState({
            affectationData: updatedElements,
            partialEditTouched: true
        });
    }

    adjustStartEndHourSlots = (startDate, endDateTime, startTime, endTime) => {
        const startHours = getStartWorkHour(moment(startTime), false);
        const endHours = getEndWorkHour(moment(endTime), false);

        const startDateTime = moment(startDate).set('hours', startHours).set('minutes', 0).set('seconds', 0);
        endDateTime.set('hours', endHours).set('minutes', 0).set('seconds', 0);

        // An event that lasts to the end of day, its' end should be considered as the next day midnight
        if (endHours === 0) {
            endDateTime.add(endDateTime.weekday() === 5 ? 3 : 1, 'days');
        } else if (moment(this.props.affectationData.endDate).get('hours') === 0 && endHours !== 0) {
            endDateTime.subtract(endDateTime.weekday() === 5 ? 3 : 1, 'days');
        }

        return {
            "start": startDateTime,
            "end": endDateTime
        }
    }

    // handle partial Edit
    handleEdit = (inputName, recurrenceTargetType = null) => {
        const { idAffectation, user, project, priority, startDate, endDate, notes, startTime, endTime, daySlotOrHourly } = this.state.affectationData;
        const prevUserId = parseInt(this.props.affectationData.user);
        let affectBody = null;

        switch (inputName) {
            case UPDATE_TYPE_RESOURCE:
                this.setState({
                    showEditResource: false,
                    partialEditTouched: false
                });
                affectBody = { "assignedUser": `${API_URL}${API_RESOURCES.USERS}/${user}` }
                break;
            case UPDATE_TYPE_PROJECT:
                this.setState({
                    showEditProject: false,
                    partialEditTouched: false
                });
                affectBody = { "assignedProject": `${API_URL}${API_RESOURCES.PROJECTS}/${project}` }
                break;
            case UPDATE_TYPE_PRIORITY:
                this.setState({
                    showEditPriority: false,
                    partialEditTouched: false
                });
                affectBody = { "exigencyLevel": priority }
                break;
            case UPDATE_TYPE_DAY_SLOT:
                this.setState({
                    showEditDaySlot: false,
                    partialEditTouched: false
                });

                let endDateTime = moment(endDate);
                if (parseInt(daySlotOrHourly) === 1) {
                    const { start, end } = this.adjustStartEndHourSlots(startDate, endDateTime, startTime, endTime)
                    endDateTime = end;
                    affectBody = {
                        "startDateTime": start.format(DATE_TIME_FORMAT)
                    }
                }

                affectBody = {
                    ...affectBody,
                    "endDateTime": endDateTime.format(DATE_TIME_FORMAT)
                }
                break;
            case UPDATE_TYPE_DATE:
                this.setState({
                    showEditDate: false,
                    partialEditTouched: false
                });
                affectBody = {
                    "startDateTime": startDate,
                    "endDateTime": endDate
                }
                break;
            case UPDATE_TYPE_GLOBAL:
                this.setState({
                    currentView: 'viewDetails',
                    partialEditTouched: false
                });

                let [startEndTimeGlobal, endDateTimeGlobal] = [moment(startDate), moment(endDate)];
                if (parseInt(daySlotOrHourly) === 1) {
                    const { start, end } = this.adjustStartEndHourSlots(startEndTimeGlobal, endDateTimeGlobal, startTime, endTime);
                    startEndTimeGlobal = start
                    endDateTimeGlobal = end;
                }

                affectBody = {
                    "assignedUser": `${API_URL}${API_RESOURCES.USERS}/${user}`,
                    "startDateTime": startEndTimeGlobal.format(DATE_TIME_FORMAT),
                    "endDateTime": endDateTimeGlobal.format(DATE_TIME_FORMAT),
                    "exigencyLevel": priority,
                    "notes": notes
                }
                break;
            // action type will be valid just for recurrent events because we have already
            // an approve handler `handleApprove` for a non-recurrent event
            case UPDATE_TYPE_APPROVE:
                affectBody = {
                    "isConfirmed": true
                }
                break;
            default:
        }

        /**
         * We can distinguich 5 scenarios:
         * 1st: default rRule exist `{...}` and is updated to none `{}` (if case --> `/doesNotRepeat`);
         * 2nd: default rRule exist `{...}` and is updated to another `{...}` (else case -->`/updateRRule?updateRRule=true`);
         * 3rd: default rRule exist `{...}` and isn't updated (else case -->`/updateRRule?updateRRule=false`);
         * 4th: no defaultRrule `{}` and we define a rRule `{...}` (simpleUpdate);
         * 5th: no defaultRrule `{}` and isn't updated (simpleUpdate);
         */
        const isRRuleStateEmpty = isEmpty(this.state.rrule);
        const isDefaultRruleEmpty = isEmpty(this.props.affectationData.rRule);
        if (!isDefaultRruleEmpty && isRRuleStateEmpty) {
            const targetResourcesIds = [
                user,
                ...(prevUserId !== 0 && parseInt(user) !== prevUserId) ? [prevUserId] : []
            ]
            this.props.onBreakRecurrentAffectation(
                idAffectation,
                targetResourcesIds,
                {
                    ...this.props.affectQueryParams,
                    [AFFECTATION_FILTERS.ASSIGNED_USER_ID_EXACT]: targetResourcesIds
                },
                this.calendarApi
            );
        } else {
            const isSameRRule = this.state.rrule === this.props.affectationData.rRule;
            const isRRuleRemained = (!isRRuleStateEmpty && !isDefaultRruleEmpty);
            affectBody = {
                ...affectBody,
                ...(!isSameRRule && buildRrulePayload(this.state.rrule)),
            }

            if (isRRuleRemained) {
                const targetResourcesIds = [
                    user,
                    ...(prevUserId !== 0 && parseInt(user) !== prevUserId) ? [prevUserId] : []
                ]
                this.props.onEditRecurrentAffectation(
                    idAffectation,
                    affectBody,
                    targetResourcesIds,
                    {
                        [RECURRENT_RESOURCES_FILTERS.UPDATE_R_RULE]: !isSameRRule,
                        [RECURRENT_RESOURCES_FILTERS.THIS_AND_THE_FOLLOWING_EVENTS]: recurrenceTargetType?.toString() === TARGET_EVENT.THIS_AND_THE_FOLLOWING
                    },
                    {
                        ...this.props.affectQueryParams,
                        [AFFECTATION_FILTERS.ASSIGNED_USER_ID_EXACT]: targetResourcesIds
                    },
                    this.calendarApi
                )
            } else {
                this.props.onEditAffectation(
                    idAffectation,
                    affectBody,
                    this.calendarApi
                );
            }
        }
        this.handleClose();
    }

    handleApprove = () => {
        const { idAffectation } = this.state.affectationData;
        this.props.onApproveAffectation({
            requests: [parseInt(idAffectation)]
        }, this.calendarApi);
        this.setState({ open: false });
    }

    openRecurrenceTargetToEditModal = (editType) => {
        this.setState({ editType });
        this.eventTargetModalRef.current.openModal();
    }

    openRecurrenceTargetToDeleteModal = () => this.eventTargetModalRef.current.openModal(RESOURCE_ACTIONS.ACTION_DELETE);

    disableSubmitResource = () => {
        const { partialEditTouched, affectationData } = this.state;
        return !partialEditTouched ||
            affectationData.userSelect === null ||
            parseInt(affectationData.userSelect?.id) === 0;
    }

    getMatchedProjects = debounce((e) => {
        e.persist();
        this.props.onFetchProjects({
            [PROJECT_FILTERS.NAME]: e.target.name,
            [PROJECT_FILTERS.PAGINATION]: false,
        });
    }, 400);

    handleProjectChange = (e, value) => {
        const updatedElements = updateObject(this.state.affectationData, {
            projectSelect: value,
            project: value.id
        });

        this.setState({
            affectationData: updatedElements,
            partialEditTouched: true,
        });
    }

    render() {
        const isRecurrentAffectation = !isEmpty(this.state.rrule);
        const {
            idAffectation,
            user,
            project,
            title,
            isConfirmed,
            priority,
            notes,
            startDate,
            endDate,
            projectManagerId,
            isBidProject,
            projectManagerName,
            createdById,
            createdByName,
            userSelect,
            projectSelect,
            startTime,
            endTime,
            daySlotOrHourly,
        } = this.state.affectationData;
        const {
            open,
            openDelete,
            showEditResource,
            showEditProject,
            showEditPriority,
            showEditDaySlot,
            showEditDate,
            partialEditTouched,
            daySlot,
            currentView,
        } = this.state;
        const {
            users,
            projects,
            loadingAffect,
            affectationData,
            exigencyLevelTypes,
            loggedUserId,
        } = this.props;

        // fetch user Data
        const usersResourcesData = [{
            id: 0,
            fullname: ' None',
            isFictive: true,
            mainSubServiceName: ' None',
        }];

        for (const key in users) {
            const singleUser = users[key];
            usersResourcesData.push({
                id: singleUser.id,
                fullname: singleUser.fullName,
                isFictive: singleUser.isFictive,
                mainSubServiceName: getmainSubServiceName(singleUser),
            });
        }

        //fetch projects data
        const projectsData = [{
            id: 0,
            name: ' None'
        }];

        projects?.map((singleProject) => {
           projectsData.push({
               id: singleProject.id,
               name: singleProject.name
           });
        });

        for (const key in projects) {
          const singleProject = projects[key];
        }
        const disableEndTime = timeValue => 14 === timeValue ||
            (
                startTime?.getDate() === endTime?.getDate() &&
                timeValue <= startTime?.getHours()
            )
        // control who is able to update and click
        // isConfirmed is returned as a number 0 or 1 ** double not works only with number
        let roleslimits = canUDAffectation(createdById, isConfirmed);

        if (moment(endDate).diff(moment(), 'days') <= 0) {
            roleslimits = false;
        }

        const canApprove =
            canApproveAffectation(isConfirmed) &&
            moment(endDate).isAfter(moment(), 'hour')
            ;

        const dateRange = `${moment(startDate).format(DATE_FORMAT)} - ${moment(endDate).format(DATE_FORMAT)}`
        const SkeletonShape = (<Skeleton style={{ height: '50px' }} width="300" animation="wave" />);

        ///////////////// project manager name display
        const projManagerFieldDiv = (
            <div className={classes.divitem}>
                <p>
                    <strong>Project Manager :</strong>
                    &nbsp;&nbsp;
                    {parseInt(loggedUserId) === parseInt(projectManagerId) ?
                        'Me' :
                        projectManagerName
                    }
                </p>
            </div>
        );
        ///////////////// event creator name display
        const createdByNameConcat = `  ${createdByName}`;
        const createdByNameFieldDiv = (
            <div className={classes.divitem}>
                <p>
                    <strong>Created By :</strong>
                    {createdByNameConcat}
                </p>
            </div>
        );
        ///////////////// handle resource partial edit
        const resourceFullName = findObjInArray(usersResourcesData, user);

        let resourceFieldDiv = null;
        const resourceEditButton = (<IconButton
            name={UPDATE_TYPE_RESOURCE}
            onClick={() => this.handleViewPartialEdit(UPDATE_TYPE_RESOURCE)} >
            <BorderColorRoundedIcon />
        </IconButton>
        );

        let projectFieldDiv = null;
        const projectEditButton = (<IconButton
            name={UPDATE_TYPE_PROJECT}
            onClick={() => this.handleViewPartialEdit(UPDATE_TYPE_PROJECT)} >
            <BorderColorRoundedIcon />
        </IconButton>
        );
        const resourceFieldView = (
            <div className={classes.divitem}>
                <p><strong>Assigned To :</strong> {resourceFullName ? resourceFullName.fullname : null}</p>
                {roleslimits ? resourceEditButton : null}
            </div>
        );

        const autoCompleteResourceCommon = (
            <AutoCompleteGlobal
                id="country-select-demo"
                label="Choose a resource"
                onChange={(e, v) => this.handleAutoComplet(e, v)}
                value={userSelect}
                options={usersResourcesData
                    .sort((a, b) => -b.mainSubServiceName.localeCompare(a.mainSubServiceName) ||
                        -b.isFictive - a.isFictive ||
                        -b.fullname.localeCompare(a.fullname)
                    )
                }
                groupBy={option => option.mainSubServiceName}
                getOptionSelected={(option1, option2) => parseInt(option1.id) === parseInt(option2.id)}
                getOptionLabel={(option) => option.fullname}
                renderOption={(option) => (
                    <React.Fragment>
                        <Avatar
                            key={option.id}
                            className={classes.avatar}
                        >
                            {option.isFictive ? <HelpOutlineIcon /> : toTitleCase(option.fullname)}
                        </Avatar>
                        {option.fullname}
                    </React.Fragment>
                )}
            />
        );

        const autoCompleteProjectCommon = (
            <AutoCompleteGlobal
                id="project-select-demo"
                label="Choose a project"
                handleKeyUp={(e) => {
                    e.persist();
                    this.getMatchedProjects(e);
                }}
                onChange={(e, v) => this.handleProjectChange(e, v)}
                value={projectSelect}
                options={projectsData}
                handleOpenAuto={() => this.setState({ openAutoProject: true })}
                handleCloseAuto={() => this.setState({ openAutoProject: false })}
                getOptionSelected={(option1, option2) => parseInt(option1.id) === parseInt(option2.id)}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                    <React.Fragment>
                        {option.name}
                    </React.Fragment>
                )}
            />
        );

        const resourceFieldEdit = (
            <Zoom in={showEditResource}>
                <div className={classes.divitem}>
                    {autoCompleteResourceCommon}
                    <div>
                        <IconButton
                            color="secondary"
                            name={UPDATE_TYPE_RESOURCE}
                            onClick={() => this.handleCancel(UPDATE_TYPE_RESOURCE)} >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            name={UPDATE_TYPE_RESOURCE}
                            disabled={this.disableSubmitResource()}
                            onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_RESOURCE) : this.handleEdit(UPDATE_TYPE_RESOURCE)} >
                            <CheckIcon style={{ color: 'green' }} />
                        </IconButton>
                    </div>
                </div>
            </Zoom >
        );

        const projectFieldEdit = (
            <Zoom in={showEditProject}>
                <div className={`${classes.divitem}`}>
                    <div style={{margin: 'auto'}}>
                        {autoCompleteProjectCommon}
                    </div>
                    <div>
                        <IconButton
                            color="secondary"
                            name={UPDATE_TYPE_PROJECT}
                            onClick={() => this.handleCancel(UPDATE_TYPE_PROJECT)} >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            name={UPDATE_TYPE_PROJECT}
                            disabled={this.disableSubmitResource()}
                            onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_PROJECT) : this.handleEdit(UPDATE_TYPE_PROJECT)} >
                            <CheckIcon style={{ color: 'green' }} />
                        </IconButton>
                    </div>
                </div>
            </Zoom >
        );

        if (!showEditResource) {
            if (loadingAffect) {
                resourceFieldDiv = SkeletonShape;
            } else {
                resourceFieldDiv = resourceFieldView;
            }
        }
        else {
            resourceFieldDiv = resourceFieldEdit;
        }

        if (!showEditProject) {
            if (loadingAffect) {
                projectFieldDiv = SkeletonShape;
            } else {
                projectFieldDiv = resourceFieldView;
            }
        }
        else {
            projectFieldDiv = projectFieldEdit;
        }

        ///////////////// handle datePicker partial edit
        let dateFieldDiv = null;
        const dateEditButton = (<IconButton
            name={UPDATE_TYPE_DATE}
            onClick={() => this.handleViewPartialEdit(UPDATE_TYPE_DATE)}
        >
            <BorderColorRoundedIcon />
        </IconButton>
        );
        const dateFieldView = (
            <div className={classes.divitem}>
                <p><strong>Date Range :</strong> {dateRange}</p>
                {roleslimits ? dateEditButton : null}
            </div>
        );

        const dateFieldEdit = (
            <Zoom in={showEditDate}>
                <div className={classes.divitem}>
                    <DatePickerForm
                        name={UPDATE_TYPE_DATE}
                        defaultValue={startDate}
                        onChangeData={(event) => this.onChangeDatePicker(event)} />
                    <div>
                        <IconButton
                            color="secondary"
                            name={UPDATE_TYPE_DATE}
                            onClick={() => this.handleCancel(UPDATE_TYPE_DATE)} >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            name={UPDATE_TYPE_DATE}
                            disabled={!partialEditTouched}
                            onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_DATE) : this.handleEdit(UPDATE_TYPE_DATE)} >
                            <CheckIcon style={{ color: 'green' }} />
                        </IconButton>
                    </div>
                </div>
            </Zoom>
        );

        if (!showEditDate) {
            if (loadingAffect) {
                dateFieldDiv = SkeletonShape;
            } else {
                dateFieldDiv = dateFieldView;
            }
        }
        else {
            dateFieldDiv = dateFieldEdit;
        }
        ///////////////// handle toggle daySlot partial edit
        let daySlotFieldDiv = null;
        const daySlotEditButton = (<IconButton
            name={UPDATE_TYPE_DAY_SLOT}
            onClick={() => this.handleViewPartialEdit(UPDATE_TYPE_DAY_SLOT)}  >
            <BorderColorRoundedIcon
            />
        </IconButton>
        );
        const daySlotdFieldView = (
            <div className={classes.divitem}>
                <p><strong>Duration :</strong> {generateEventDurationText(idAffectation, this?.calendarApi)}</p>
                {roleslimits && daySlotEditButton}
            </div>
        );

        const dayHourSlotsInputs = (
            <div>
                <div>
                    <FormControlLabel
                        name="daySlotOrHourly"
                        checked={parseInt(daySlotOrHourly) === 0}
                        onChange={e => this.onChangeStandard(e)}
                        value={0}
                        control={<Radio color="primary" />}
                        label="Duration (Day slot)"
                    />
                    <br />
                    <div style={parseInt(daySlotOrHourly) === 1 ? { pointerEvents: 'none' } : {}}>
                        <Toggle
                            id="dayslot"
                            value={daySlot}
                            onChangeData={(event, value) => this.onChangeDaySlot(event, value)}
                        />
                    </div>
                </div>
                <br />

                <div>
                    <FormControlLabel
                        name="daySlotOrHourly"
                        className={classes.formControlLabel}
                        checked={parseInt(daySlotOrHourly) === 1}
                        onChange={e => this.onChangeStandard(e)}
                        value={1}
                        control={<Radio color="primary" />}
                        label="Duration (hourly)"
                    />
                    <br />

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        ...(parseInt(daySlotOrHourly) === 0 && { pointerEvents: 'none' })
                    }}>
                        <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} dateFormats="HH" className={classes.timePicker} >
                            <TimePicker
                                label=""
                                views={['hours']}
                                inputFormat='HH:00'
                                clearable={false}
                                ampm={false}
                                minTime={new Date(0, 0, 0, 9, 0, 0)}
                                maxTime={new Date(0, 0, 0, 17, 0, 0)}
                                shouldDisableTime={timeValue => 13 === timeValue}
                                renderInput={(props) => <TextField {...props} />}
                                value={startTime}
                                onChange={dateTime => this.handleStartEndTimeOnChange(dateTime, 'start')}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateLibInstance={moment} dateAdapter={DateFnsAdapter} dateFormats="HH" className={classes.timePicker} >
                            <TimePicker
                                label=""
                                views={['hours']}
                                inputFormat='HH:00'
                                clearable={true}
                                ampm={false}
                                minTime={new Date(0, 0, 0, 10, 0, 0)}
                                maxTime={new Date(0, 0, 0, 18, 0, 0)}
                                shouldDisableTime={timeValue => disableEndTime(timeValue)}
                                renderInput={(props) => <TextField {...props} />}
                                value={endTime}
                                onChange={dateTime => this.handleStartEndTimeOnChange(dateTime, 'end')}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
        );
        const daySlotFieldEdit = (
            <Zoom in={showEditDaySlot}>
                <div className={classes.divitem}>
                    {dayHourSlotsInputs}

                    <br />
                    <div>
                        <IconButton
                            color="secondary"
                            name={UPDATE_TYPE_DAY_SLOT}
                            onClick={() => this.handleCancel(UPDATE_TYPE_DAY_SLOT)} >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            name={UPDATE_TYPE_DAY_SLOT}
                            disabled={!partialEditTouched}
                            onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_DAY_SLOT) : this.handleEdit(UPDATE_TYPE_DAY_SLOT)} >
                            <CheckIcon style={{ color: 'green' }} />
                        </IconButton>
                    </div>
                </div>
            </Zoom>
        );

        if (!showEditDaySlot) {
            if (loadingAffect) {
                daySlotFieldDiv = SkeletonShape;
            } else {
                daySlotFieldDiv = daySlotdFieldView;
            }
        }
        else {
            daySlotFieldDiv = daySlotFieldEdit;
        }
        ////////////////   handle priority partial edit
        let priorityFieldDiv = null;
        const priorityEditButton = (<IconButton
            name={UPDATE_TYPE_PRIORITY}
            onClick={() => this.handleViewPartialEdit(UPDATE_TYPE_PRIORITY)} >
            <BorderColorRoundedIcon
            />
        </IconButton>
        );
        const priorityFieldView = (
            <div
                className={classes.divitem}>
                <p><strong>Priority Level :</strong> {priority}</p>
                {roleslimits ? priorityEditButton : null}
            </div>
        );

        const priorityFieldEdit = (
            <Zoom in={showEditPriority}>
                <div className={classes.divitem}>
                    <SelectBox
                        name="priority"
                        label="Priority"
                        defaultValue={priority}
                        dataArray={exigencyLevelTypes}
                        none={false}
                        required={false}
                        // for options
                        optValueKey="value"
                        optLabelKey="label"
                        onChangeData={(event) => this.onChangeStandard(event)}
                    />
                    <div>
                        <IconButton
                            color="secondary"
                            name={UPDATE_TYPE_PRIORITY}
                            onClick={() => this.handleCancel(UPDATE_TYPE_PRIORITY)} >
                            <CloseIcon />
                        </IconButton>
                        <IconButton
                            name={UPDATE_TYPE_PRIORITY}
                            disabled={!partialEditTouched}
                            onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_PRIORITY) : this.handleEdit(UPDATE_TYPE_PRIORITY)} >
                            <CheckIcon style={{ color: 'green' }} />
                        </IconButton>
                    </div>
                </div>
            </Zoom>
        );

        if (!showEditPriority) {
            if (loadingAffect) {
                priorityFieldDiv = SkeletonShape;
            } else {
                priorityFieldDiv = priorityFieldView;
            }
        }
        else {
            priorityFieldDiv = priorityFieldEdit;
        }

        /////////////////////   delete Dialog view
        const deleteDilog = (
            <div>
                <Dialog
                    open={openDelete}
                    onClose={this.handlCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle id="alert-dialog-title">Delete Assignment</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure, you want to delete this ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlCloseDelete} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToDeleteModal() : this.handleDelete()} color="primary" autoFocus>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
        const dialogTitleActions = (
            <React.Fragment>
                <Button onClick={() => this.handleClickOpenUpdate('globalUpdate')} >
                    Edit
                </Button>
                <div>
                    <Button onClick={this.handlClickOpenDelete}>
                        Delete
                    </Button>
                    {deleteDilog}
                </div>
            </React.Fragment>
        )

        let dialogDeletePast = null;
        if (canDeletePassedAffectation(endDate)) {
            dialogDeletePast = (
                <div>
                    <Button onClick={this.handlClickOpenDelete}>
                        Delete
                    </Button>
                    {deleteDilog}
                </div>
            )
        }

        const DialogActionsField = (
            <DialogActions style={{ margin: '0 auto' }}>
                <Button
                    autoFocus
                    color="primary"
                    variant="outlined"
                    onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToDeleteModal() : this.handleDelete()} >
                    Decline
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_APPROVE) : this.handleApprove()} >
                    Approve
                </Button>
            </DialogActions>
        )
        // toggle between update dialog and view details dialog
        let viewDetailsUpdate = null;
        if (currentView === 'viewDetails') {
            viewDetailsUpdate = (
                <div>
                    <DialogTitle disableTypography className={classes.dialogTitle} id="draggable-dialog-title1">
                        Assigned Project
                        {roleslimits && dialogTitleActions}
                        {dialogDeletePast}
                        <IconButton onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent >
                        {isBidProject &&
                            <BidProjectSpan isConfirmed={isConfirmed} />
                        }
                        {showEditProject ?
                            projectFieldDiv :
                            <div className={classes.divTitle}>
                                <h6>{title}</h6>
                                {roleslimits ? projectEditButton : null}
                            </div>
                        }

                        <br />
                        {projManagerFieldDiv}
                        <Divider variant="middle" />
                        {createdByNameFieldDiv}
                        <Divider variant="middle" />
                        {resourceFieldDiv}
                        <Divider variant="middle" />
                        {daySlotFieldDiv}
                        <Divider variant="middle" />
                        {dateFieldDiv}
                        <Divider variant="middle" />
                        {priorityFieldDiv}
                        <Divider variant="middle" />
                        <div className={classes.divitem}>
                            <p><strong>Frequency: </strong>
                                {`${this.state.rrule?.frequency || 'Does not repeat'}`}
                            </p>
                        </div>
                        <br />
                        <div style={{ paddingBottom: '10px' }}>
                            <BigTextField
                                name="notes"
                                label="Notes"
                                value={notes}
                                disabled={true}
                            />
                            <br />
                        </div>
                    </DialogContent>
                    {canApprove && DialogActionsField}
                </div>
            );
        } else if (currentView === 'globalUpdate') {
            viewDetailsUpdate = (
                <div>
                    <DialogTitle disableTypography className={classes.dialogTitle} id="draggable-dialog-title1">
                        Update
                        <IconButton onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {showEditProject ?
                              projectFieldDiv :
                              <div className={classes.divTitle}>
                                  <h6>{title}</h6>
                                  {roleslimits ? projectEditButton : null}
                              </div>
                        }
                        <br />
                        {projManagerFieldDiv}
                        <br />
                        {createdByNameFieldDiv}
                        <br />
                        <Grid container direction="column" >
                            <Grid container direction="column" alignItems="baseline" >
                                <FormLabel style={{ color: 'black' }}><strong>
                                    Assigned To :
                                </strong></FormLabel>
                                <Box style={{ borderRadius: '5px', padding: '10px' }}>
                                    <Grid item style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <PersonRoundedIcon style={{ marginRight: '7px', marginTop: '7px' }} />
                                        {autoCompleteResourceCommon}
                                    </Grid>
                                </Box>
                            </Grid>
                            <br />
                            <Grid container direction="column" alignItems="baseline" >
                                <FormLabel style={{ color: 'black' }}><strong>
                                    Date and Dayslot
                                </strong></FormLabel>
                                <Box style={{ borderRadius: '5px', padding: '10px' }}>
                                    <Grid item >
                                        <DateRangeIcon style={{ marginRight: '7px', marginTop: '7px' }} />
                                        {dayHourSlotsInputs}
                                    </Grid>
                                    <br />
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                                        <DatePickerForm
                                            name="start"
                                            defaultValue={startDate}
                                            onChangeData={(event) => this.onChangeDatePicker(event)} />
                                        TO
                                        <DatePickerForm
                                            label="end date"
                                            name="start"
                                            disabled={true}
                                            defaultValue={transformEndDate(startDate, daySlot)}
                                        />
                                    </div>
                                </Box>
                            </Grid>
                            <br />
                            <Grid container direction="column" alignItems="baseline">
                                <FormLabel style={{ color: 'black' }} ><strong>
                                    Priority And Notes
                                </strong></FormLabel>
                                <Box style={{ borderRadius: '5px', padding: '10px' }}>
                                    <Grid item>
                                        <PriorityHighRoundedIcon style={{ marginRight: '7px', marginTop: '7px' }} />
                                        <RadioGroups
                                            name="priority"
                                            label="Priority"
                                            defaultValue={priority}
                                            dataArray={exigencyLevelTypes}
                                            required={false}
                                            // for options
                                            optValueKey="value"
                                            optLabelKey="label"
                                            onChangeData={(event) => this.onChangeStandard(event)}
                                        />
                                    </Grid>
                                    <Grid item style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <NotesRoundedIcon style={{ marginRight: '7px', marginTop: '2px' }} />
                                        <BigTextField
                                            name="notes"
                                            label="Notes"
                                            value={notes}
                                            disabled={false}
                                            onChangeData={(event) => this.onChangeStandard(event)}
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                            <br />
                            <Grid container direction="column" alignItems="baseline">
                                <FormLabel style={{ color: 'black' }}>
                                    <strong>
                                        Other options
                                    </strong>
                                </FormLabel>
                                <Box style={{ borderRadius: '5px', padding: '10px', display: 'flex', alignItems: 'baseline' }}>
                                    <span><RepeatRoundedIcon /> Frequency :</span>
                                    <RecurrenceSelect
                                        toEdit
                                        defaultRcurrenceOption={affectationData.rRule}
                                        startDate={startDate}
                                        setRrule={rrule => {
                                            this.setState({
                                                rrule,
                                                partialEditTouched: true
                                            })
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            type="submit"
                            color="primary"
                            variant="outlined"
                            onClick={() => this.handleClickOpenUpdate('viewDetails')}
                        >
                            cancel
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={this.disableSubmitResource()}
                            onClick={() => isRecurrentAffectation ? this.openRecurrenceTargetToEditModal(UPDATE_TYPE_GLOBAL) : this.handleEdit(UPDATE_TYPE_GLOBAL)}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </div>
            );
        }

        return (
            <div>
                <Dialog
                    disableEnforceFocus
                    scroll="body"
                    open={open}
                    onClose={this.handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title1"
                >
                    <div>
                        {viewDetailsUpdate}
                    </div>
                </Dialog>

                <RecurrenceTargetEventModal
                    resource="affectation"
                    ref={this.eventTargetModalRef}
                    onDone={(recurrenceTargetType, actionType) => actionType === RESOURCE_ACTIONS.ACTION_UPDATE ?
                        this.handleEdit(this.state.editType, recurrenceTargetType) :
                        this.handleDelete(recurrenceTargetType)
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        // app reducer
        exigencyLevelTypes: state.app.exigencyLevelTypes,
        loggedUserId: state.app.user.userId,
        // auth redcuer
        token: state.auth.token,
        // projects + users reducers
        users: state.user.users,
        projects: state.project.projects,
        loading: state.user.loading,
        // affectation reducer
        affectations: state.affecta.affectations,
        loadingAffect: state.affecta.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditAffectation: (id, affectBody, calendarApi) => dispatch(actions.editAffectation(id, affectBody, calendarApi)),
        onDeleteAffectation: (idAffectation, calendaApi) => dispatch(actions.deleteAffectation(idAffectation, calendaApi)),
        onApproveAffectation: (requestsListBody, calendarApi) => dispatch(actions.approveListAffectation(requestsListBody, calendarApi)),
        // on recurrent actions
        onEditRecurrentAffectation: (id, data, resourcesIds, queryParams, affectationQueryParams, calendarApi) =>
            dispatch(actions.editRecurrentAffectation(id, data, resourcesIds, queryParams, affectationQueryParams, calendarApi)),
        onBreakRecurrentAffectation: (id, resourcesIds, affectationQueryParams, calendarApi) =>
            dispatch(actions.breakRecurrentAffectation(id, resourcesIds, affectationQueryParams, calendarApi)),
        onDeleteRecurrentAffectaionAndFollowing: (id, resourcesIds, affectationQueryParams, calendarApi) =>
            dispatch(actions.deleteRecurrentAffectaionAndFollowing(id, resourcesIds, affectationQueryParams, calendarApi)),
        onFetchProjects: (queryParams) => dispatch(actions.fetchProjects(queryParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalUIViewAffectation)
