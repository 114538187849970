import React, { Component } from 'react';
import './ConfirmPassword.scss';
import { Button } from '@material-ui/core';
import { checkValidity, updateObject } from '../../../shared/utility';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions/index';
import { Redirect } from 'react-router-dom';

class ConfirmPassword extends Component {

    state = {
        controls: {
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            },
            confirmPassword: {
                value: '',
                validation: {
                    required: true,
                    minLength: 2
                },
                valid: false,
                touched: false
            }
        },
        token: null
    }

    componentDidMount() {
        this.setState({
            token: this.props.location.search.split('=')[1]
        })
    }

    inputChangeHandler = (event) => {

        // capture event names and their correposnding values
        const eventValue = event.target.value;
        const inputName = event.target.name;

        const updatedElements = updateObject(this.state.controls, {
            [inputName]: updateObject(this.state.controls[inputName], {
                value: eventValue,
                valid: checkValidity(eventValue, this.state.controls[inputName].validation),
                touched: true
            })
        });

        // updatedElements to update state values
        this.setState({ controls: updatedElements });
    }

    comparePasswords = () => {
        const { password, confirmPassword } = this.state.controls;
        return password.value === confirmPassword.value;
    }

    // handling form submission
    submitHandler = () => {
        const { password } = this.state.controls;

        const resetPassBody = {
            token: this.state.token,
            pass: password.value
        }
        this.props.onResetPwd(resetPassBody);
    }

    disableSubmitHandler = () => {
        return !this.state.controls.password.valid || this.props.loading || !this.comparePasswords();
    }

    render() {
        let loginRedirect = null;
        if (this.props.loading) {
            loginRedirect = <Redirect to='/' />;
        }

        return (
            <div>
                {loginRedirect}
                <br /> <br /><br /><br /><br /><br />
                <div className="card col-md-10 col-lg-6 m-auto">
                    <div className="card-body">
                        <h2>Reset your password</h2>

                        <div className="form-group">
                            <label className="required" htmlFor="password">Password</label>
                            <input type="password" className="form-control" id="password" required="required" autoFocus=""
                                name="password"
                                onChange={(event) => this.inputChangeHandler(event)} />
                            <div style={{ fontSize: 12, color: "red" }}>
                                {!this.state.controls.password.valid && this.state.controls.password.touched ? "invalid Password" : null}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="required" htmlFor="confirmPassword">Confirm password</label>
                            <input type="password" className="form-control" id="confirmPassword" required="required" autoFocus=""
                                name="confirmPassword"
                                onChange={(event) => this.inputChangeHandler(event)} />
                            <div style={{ fontSize: 12, color: "red" }}>
                                {this.state.controls.confirmPassword.touched && !this.comparePasswords() ? "Passwords don't match" : null}
                            </div>
                        </div>
                        <div>
                            <center><Button
                                onClick={() => this.submitHandler()}
                                disabled={this.disableSubmitHandler()}
                                className="resetPassword"
                                color="primary"
                                variant="contained" >Submit</Button></center>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


const mapStateTpProps = state => {
    return {
        loading: state.resetPwd.loading,
        error: state.resetPwd.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPwd: (body) => dispatch(actions.resetPwd(body))
    };
};

export default connect(mapStateTpProps, mapDispatchToProps)(ConfirmPassword)
