import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import * as actions from '../../store/actions/index';
import PlaylistAddRoundedIcon from '@material-ui/icons/PlaylistAddRounded';
import NumericInput from '../../components/UI/Input/NumericInput';
import { SERVICE_FILTERS } from '../../services/utils/filters';
import SwitchToggle from '../../components/UI/SwicthToggle/SwitchToggle';
import { Tooltip } from '@material-ui/core';

const EnableEmoji = (enabled = true) =>
    <Tooltip title={enabled ? 'Active' : 'Non-Active'}>
        {enabled ?
            <DoneRoundedIcon fontSize='large' color='primary' /> :
            <CloseRoundedIcon fontSize='large' color='error' />
        }
    </Tooltip>;

const IsFreelanceEmoji = (isFreelance = true) =>
    <Tooltip title={isFreelance ? 'Is a Freelance' : 'Is not a Freelance'}>
        {isFreelance ?
            <DoneRoundedIcon fontSize='large' color='primary' /> :
            <CloseRoundedIcon fontSize='large' color='error' />
        }
    </Tooltip>;

class Services extends Component {
    componentDidMount() {
        this.props.onFetchServices({ [SERVICE_FILTERS.PAGINATION]: false });
    }

    columns = [
        {
            title: "Code (unique)",
            field: "code",
            editable: 'onAdd',
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Short Name",
            field: "shortName",
        },
        {
            title: "Order",
            field: "position",
            editComponent: props => (<NumericInput {...props} />),
        },
        {
            title: "Active",
            field: "enabled",
            type: "boolean",
            render: rowData => EnableEmoji(rowData.enabled),
            editComponent: props => (<SwitchToggle {...props} />),
        },
    ]

    subColumns = [
        {
            title: "Code (unique)",
            field: "code",
            editable: 'onAdd',
        },
        {
            title: "Name",
            field: "name",
        },
        {
            title: "Order",
            field: "position",
            editComponent: props => (<NumericInput {...props} />),
        },
        {
            title: "Active",
            field: "enabled",
            type: "boolean",
            render: rowData => EnableEmoji(rowData.enabled),
            editComponent: props => (<SwitchToggle {...props} />),
        },
        {
            title: "Is Freelance",
            field: "isFreelance",
            type: "boolean",
            render: rowData => IsFreelanceEmoji(rowData.isFreelance),
            editComponent: props => (<SwitchToggle {...props} />),
        },
    ]

    // Adding actions
    handleAddService = (servicePayLoad) => this.props.onAddService(
        {
            ...servicePayLoad,
            ...(servicePayLoad.position && {
                position: parseInt(servicePayLoad.position)
            }),
        },
        this.props.services
    );

    handleAddSubService = (subServicePayLoad, serviceId) => this.props.onAddSubService(
        {
            ...subServicePayLoad,
            ...(subServicePayLoad.position && { position: parseInt(subServicePayLoad.position) }),
        },
        serviceId,
        this.props.services
    );

    // Edtiting actions
    handleEditService = (serviceNewData) => this.props.onEditService(
        serviceNewData.id,
        {
            name: serviceNewData.name,
            shortName: serviceNewData.shortName,
            position: parseInt(serviceNewData.position),
            enabled: serviceNewData.enabled,
        },
        this.props.services
    );

    handleEditSubService = (parentId, subServiceData) => this.props.onEditSubService(
        subServiceData.id,
        {
            name: subServiceData.name,
            position: parseInt(subServiceData.position),
            enabled: subServiceData.enabled,
            isFreelance: subServiceData.isFreelance,
        },
        parentId,
        this.props.services
    );

    // Deleting actions
    handleDeleteService = (id) => this.props.onDeleteService(id, this.props.services);

    handleDeletesubService = (subId, parentId) => this.props.onDeleteSubService(subId, parentId, this.props.services);

    render() {
        const servicesTableData = [];
        for (const key in this.props.services) {
            servicesTableData.push({
                ...this.props.services[key],
            });
        }
        return (
            <div>
                <MaterialTable
                    title="Services Data"
                    columns={this.columns}
                    data={servicesTableData}
                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, _reject) => {
                                setTimeout(() => {
                                    this.handleAddService(newData);
                                    resolve();
                                })
                            }),
                        onRowUpdate: (newData, _oldData) =>
                            new Promise((resolve, _reject) => {
                                setTimeout(() => {
                                    this.handleEditService(newData);
                                    resolve();
                                })
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, _reject) => {
                                setTimeout(() => {
                                    this.handleDeleteService(oldData.id);
                                    resolve();
                                })
                            }),
                    }}
                    options={{
                        paging: false,
                        actionsColumnIndex: -1,
                        search: false,
                    }}
                    isLoading={this.props.loading}
                    onRowClick={(_event, _rowData, togglePanel) => togglePanel()}
                    detailPanel={
                        rowData => (
                            <div style={{ border: "1px solid blue", margin: "10px" }}>
                                <MaterialTable
                                    title={`${rowData?.name} Sub-Services`}
                                    columns={this.subColumns}
                                    data={rowData.subServices}
                                    options={{
                                        paging: false,
                                        search: false,
                                        padding: 'dense',
                                        actionsColumnIndex: -1,
                                    }}
                                    editable={{
                                        onRowAdd: newData =>
                                            new Promise((resolve, _reject) => {
                                                setTimeout(() => {
                                                    const subServicePayLoad = {
                                                        ...newData,
                                                        service: `/api/services/${rowData.id}`,
                                                    };
                                                    this.handleAddSubService(subServicePayLoad, rowData.id);
                                                    resolve();
                                                })
                                            }),
                                        onRowUpdate: (newData, _oldData) =>
                                            new Promise((resolve, _reject) => {
                                                setTimeout(() => {
                                                    this.handleEditSubService(rowData.id, newData);
                                                    resolve();
                                                })
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise((resolve, _reject) => {
                                                setTimeout(() => {
                                                    this.handleDeletesubService(oldData.id, rowData.id);
                                                    resolve();
                                                })
                                            }),
                                    }}
                                    icons={{
                                        Add: PlaylistAddRoundedIcon,
                                    }}
                                />
                            </div>
                        )
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        // auth reducer
        token: state.auth.token,
        // service reducer
        services: state.service.services,
        loading: state.service.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchServices: (queryParams) => dispatch(actions.fetchServices(queryParams)),
        onAddService: (data, services) => dispatch(actions.addService(data, services)),
        onAddSubService: (data, serviceId, services) => dispatch(actions.addSubService(data, serviceId, services)),
        onEditService: (id, data, services) => dispatch(actions.editService(id, data, services)),
        onEditSubService: (id, data, parentId, services) => dispatch(actions.editSubService(id, data, parentId, services)),
        onDeleteService: (id, services) => dispatch(actions.deleteService(id, services)),
        onDeleteSubService: (id, parentId, services) => dispatch(actions.deletSubService(id, parentId, services)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
