import { API_RESOURCES, axiosInstance } from './utils/apiResources';
import { buildFilters } from './utils/filters';

export const teamService = {
    getServices,
    addService,
    updateService,
    deleteService,
    getSubServices,
    addSubService,
    updateSubService,
    deleteSubService,
    getAffectationsPerWeekAndServices,
    getWorkingDaysPerWeekAndServices,
}

function getServices(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.SERVICES}${buildFilters(queryParams)}`
    );
}

function addService(data) {
    return axiosInstance.post(
        `${API_RESOURCES.SERVICES}`,
        data
    );
}

function updateService(id, data) {
    return axiosInstance.put(
        `${API_RESOURCES.SERVICES}/${id}`,
        data
    );
}

function deleteService(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.SERVICES}/${id}`
    );
}

function getSubServices(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.SUB_SERVICES}${buildFilters(queryParams)}`
    );
}

function addSubService(data) {
    return axiosInstance.post(
        `${API_RESOURCES.SUB_SERVICES}`,
        data
    );
}

function updateSubService(id, data) {
    return axiosInstance.put(
        `${API_RESOURCES.SUB_SERVICES}/${id}`,
        data
    );
}

function deleteSubService(id) {
    return axiosInstance.delete(
        `${API_RESOURCES.SUB_SERVICES}/${id}`
    );
}

function getAffectationsPerWeekAndServices(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.AFFECTATIONS_PER_WEEK_AND_SERVICES}${buildFilters(queryParams)}`
    );
}

function getWorkingDaysPerWeekAndServices(queryParams = {}) {
    return axiosInstance.get(
        `${API_RESOURCES.WORKING_DAYS_PER_WEEK_AND_SERVICES}${buildFilters(queryParams)}`
    );
}