import React from 'react';
import DefaultSelect from '../Default/DefaultSelect';

export default function ServiceSelect(props) {
    const [serviceId, setServiceId] = React.useState(props.mainServiceFilterId);

    const services = [...props.services];
    const avaialbleServices = [];

    for (const key in services) {
        avaialbleServices.push({
            id: services[key].id,
            code: services[key].code,
            name: services[key].name,
        });
    }

    const handleFilterChange = (value) => {
        // refactor value data type to avoid tableData.filterValue.toUpperCase is not a function error
        // which is fire filter event
        setServiceId(value);
        props.onFilterChanged(props.columnDef.tableData.id, `${value}`);
    };

    return (
        <DefaultSelect
            id="service-select"
            none={true}
            multiple={false}
            isFilter={props.isFilter}
            value={props.isFilter ? serviceId : props.value}
            onChange={e => props.isFilter ? handleFilterChange(e.target.value) : props.onChange(e.target.value)}
            data={avaialbleServices}
            itemKey="code"
            itemValue="id"
            itemName="name"
        />
    )
}
