import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Tooltip } from '@material-ui/core';
import ServiceNavigationSkeleton from './ServiceNavigationSkeleton';

// Styles
import dashBoardStyles from '../../../containers/Dashboard/dashboard.module.scss';

const allProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

const getServiceTabClass = (activeValue, serviceValue) => {
    return activeValue === serviceValue ? dashBoardStyles.activeTab : dashBoardStyles.inactiveTab;
};

export default function ServiceNavigation(props) {
    return (
        <div className={dashBoardStyles.serviceDiv}>
            {props.loadingService ?
                <ServiceNavigationSkeleton
                    getClass={getServiceTabClass}
                /> : <ToggleButtonGroup
                    className="maintabs"
                    onChange={props.onChange}
                    value={props.value}
                    exclusive
                    size="small">
                    {
                        props.services?.map(singleService =>
                            <Tooltip key={singleService.code} title={singleService.name}>
                                <ToggleButton
                                    {...allProps(props.id)}
                                    key={singleService.code}
                                    value={singleService.id}
                                    className={getServiceTabClass(props.value, singleService.id)}
                                >
                                    {singleService.shortName}
                                </ToggleButton>
                            </Tooltip>
                        )
                    }
                    <Tooltip key="all" title="ALL Services">
                        <ToggleButton
                            {...allProps(0)}
                            key="all_services"
                            value={0}
                            className={getServiceTabClass(props.value, 0)}
                        >
                            ALL
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
            }
        </div>)
}
