import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actions from '../../store/actions/index';
import {
    dateRangesIntersect,
    SERVICE_OTHERS_CODE,
    SERVICE_DEV_CODE,
    SERVICE_DESIGN_CODE,
    SERVICE_CONTENT_CODE,
} from '../../shared/utility';

import ServiceCards from '../../components/GanttPro/ServiceCards/ServiceCards';
import LineChart from "../../components/GanttPro/GanttReports/LineChart";
import classes from "../../hoc/Layout/Layout.module.scss";
import AppHashLoader from "../../components/UI/Loader/HashLoader/AppHashLoader";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

class Reports extends Component {
    constructor(props) {
        super(props);

        const currentWeekDay = moment().startOf('isoWeek');
        const XAxisLabels = [];

        XAxisLabels.push(currentWeekDay.format(DATE_FORMAT));
        for (let index = 0; index < 10; index++) {
            XAxisLabels.push(currentWeekDay.add(7, 'days').format(DATE_FORMAT));
        }

        this.state = {
            XAxisLabels: XAxisLabels
        }
    }

    componentDidMount() {
        this.props.onFetchResoucresPerTasks();
    }

    // used to adjust total services % of digits after comma
    roundToTwo(num) {
        return +(Math.round(`${(num * 100)}e+2`) + "e-2");
    }

    calculateNbAllocatedResources(nb) {
        return Math.ceil(nb / 40);
    }

    getAverageOfAverages(array, index) {
        const percentageSum = [0, 1, 2, 3].map(pointer => array[pointer][index]).reduce((prev, next) => prev + next) / 4;
        return this.roundToTwo(percentageSum / 100);
    }

    getNestedSum(array, attr) {
        return Object.values(array).reduce((a, b) => attr ? a + b[attr] : a + b, 0);
    }

    render() {
        const { XAxisLabels } = this.state;
        const allocatedResources = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ];
        const projectsInProgress = [
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        ];
        const treatedProjectsIds = [
            [], [], [], [], [], [], [], [], [], []
        ];
        const services = { [SERVICE_OTHERS_CODE]: 0, [SERVICE_DEV_CODE]: 1, [SERVICE_DESIGN_CODE]: 2, [SERVICE_CONTENT_CODE]: 3 };

        const totalResources = [
            {
                name: 'All Services',
                subheader: '* the others service is counted',
                currentAllocations: 0,
                total: this.getNestedSum(this.props.totalResources, null),
                allocations: [],
                projects: [],
                color: '#45276A'
            },
            {
                name: 'Dev',
                currentAllocations: 0,
                total: this.props.totalResources[SERVICE_DEV_CODE],
                allocations: [],
                projects: [],
                color: '#A6CEE3'
            },
            {
                name: 'Design',
                currentAllocations: 0,
                total: this.props.totalResources[SERVICE_DESIGN_CODE],
                allocations: [],
                projects: [],
                color: '#6587FF'
            },
            {
                name: 'Content/Market',
                currentAllocations: 0,
                total: this.props.totalResources[SERVICE_CONTENT_CODE],
                allocations: [],
                projects: [],
                color: '#FFE4E4'
            }
        ];
        // filter data that are in range (we suppose that haven't duplicated data having same firstDayWeek & lastDayWeek)
        const servicesInRange = this.props.reourcesPerTasks.filter(ele => dateRangesIntersect(ele.firstDayWeek, ele.lastDayWeek, XAxisLabels[0], XAxisLabels[XAxisLabels.length - 1]));

        servicesInRange.forEach(ele => {
            // lastDayWeek of the back is considerd the Sunday (yyyy-mm-dd),
            // and in the front axisLabel the end of week is by monday (yyyy-mm-dd 00:00:00)
            // due to displaying reasons we aren't considering dates in xAxisLabel as those in the back
            // and we assume that there is a single weekService (no duplicate for a 30th week in a year for example)
            const weekServiceIndex = XAxisLabels.findIndex((_weekService, dateIndex) =>
                moment(ele.firstDayWeek).isSameOrAfter(XAxisLabels[dateIndex])
                && moment(ele.lastDayWeek).isBefore(XAxisLabels[dateIndex + 1])
            );
            if (weekServiceIndex !== -1) {
                for (const serviceKey in ele.services) {
                    // treat every single service {allocated resoucres + projects in progress}
                    const serviceValue = ele.services[serviceKey];
                    const percentage = serviceValue.totalTaskDuration / (serviceValue.totalResourceHour - serviceValue.dayOff);
                    const allocatedAmount = this.roundToTwo(percentage);

                    allocatedResources[services[serviceKey]][weekServiceIndex] = allocatedAmount;
                    projectsInProgress[services[serviceKey]][weekServiceIndex] = serviceValue.projectsNumber;
                    // push every service data(contain duplicates)
                    treatedProjectsIds[weekServiceIndex].push(...serviceValue.projectsId);
                }
                // remove duplicates from treatead projects
                treatedProjectsIds[1] = [...new Set(treatedProjectsIds[weekServiceIndex])];
            }
        });
        // calculate total allocations + projects
        allocatedResources[0].forEach((_ele, index) => {
            // get average of averages tp calculate average of total's allocations
            allocatedResources[0][index] += this.getAverageOfAverages(allocatedResources, index);
            // logically contains nb of projects of Others service---> destruct it and get length of total treated projects
            projectsInProgress[0][index] = treatedProjectsIds[index].length;
        });

        if (!this.props.loading && servicesInRange.length > 0) {
            const currentWeekData = servicesInRange[0].services;
            for (const serviceKey in currentWeekData) {
                totalResources[services[serviceKey]].currentAllocations = this.calculateNbAllocatedResources(currentWeekData[serviceKey].totalTaskDuration);
            }
            totalResources[0].currentAllocations += this.getNestedSum(totalResources, 'currentAllocations');
        }
        totalResources.forEach((totalResource, index) => {
            totalResource.allocations = allocatedResources[index];
            totalResource.projects = projectsInProgress[index];
        });
        totalResources.sort((obj1, obj2) => obj1.name.localeCompare(obj2.name));

        return (
            <div>
                <h3>Reports this week</h3>
                <br />
                {this.props.totalResources &&
                    <ServiceCards
                        totalResources={this.props.globalStats}
                        loadingGantt={this.props.loading}
                    />
                }

                <div className="ganttLineService" style={{display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: 'column'}}>
                    <div style={{width: '100%', paddingBottom: '1rem', paddingTop: '1rem'}}>
                        <h3>OverView</h3>
                    </div>
                    <div style={{width: '70%'}}>
                        {this.props.data ?
                          <LineChart data={this.props.data} style={{width: '80%', height: '80%'}}/> :
                          <div className={classes.hashLoader}>
                              <AppHashLoader />
                          </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        // gantt pro all projects tasks
        reourcesPerTasks: state.ganttpro.reourcesPerTasks,
        tasksCurrentWeek: state.ganttpro.tasksCurrentWeek,
        totalResources: state.ganttpro.totalResources,
        loading: state.ganttpro.loadingReosurcesPerTask,
        data: state.affecta.data,
        globalStats: state.affecta.globalStats,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // pro store storer
        // onFetchTrafficStats: () => dispatch(actions.fetchTrafficStats())
        onFetchResoucresPerTasks: () => dispatch(actions.fetchTrafficStats())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports)
