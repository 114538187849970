import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    loading: false,
    // only for administrators
    resetToken: null,
    error: null,
}

const resetPwdEmailStart = (state, _action) => {
    return updateObject(state, {
        loading: true,
        error: null,
    });
}

const resetPwdEmailSuccess = (state, action) => {
    return updateObject(state, {
        resetToken: action.resetToken,
        loading: false,
        error: null,
    });
}

const resetPwdEmailFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
    });
}

const resetPwdReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.RESET_PWD_MAIL_START: return resetPwdEmailStart(state, action);
        case actionTypes.RESET_PWD_MAIL_SUCCESS: return resetPwdEmailSuccess(state, action);
        case actionTypes.RESET_PWD_MAIL_FAIL: return resetPwdEmailFail(state, action);

        default: return state;
    }
}

export default resetPwdReducer;
