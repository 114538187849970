import { axiosInstance } from "./utils/apiResources"

export const appService = {
    getDBAL,
    getMe,
}

function getDBAL(type) {
    return axiosInstance.get(
        `/dbal?type=${type}`
    );
}

function getMe() {
    return axiosInstance.get(
        '/me'
    );
}
