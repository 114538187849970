import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import Dashboard from './containers/Dashboard/Dashboard';
import Users from './containers/Users/Users';
import Projects from './containers/Projects/Projects';
import Layout from './hoc/Layout/Layout';
import MyProfile from './containers/MyProfile/MyProfile';
import ForgetPasswordEmail from './containers/Auth/ForgetPasswordEmail/ForgetPasswordEmail';
import ConfirmPassword from './containers/Auth/ConfirmPassword/ConfirmPassword';
import NotFound from './containers/NotFound/NotFound';
import LoadPlan from './containers/LoadPlan/LoadPlan';
import Reports from './containers/Stats/Reports';
import NotFoundLogin from './containers/NotFound/NotFoundLogin';
import Services from './containers/Settings/Services';

class App extends Component {
  render() {
    return (
      <div className="App">
        {this.props.isAuthenticated ?
          <Layout>
            <Switch>
              <Route path="/profile" component={MyProfile} />
              <Route path="/users" component={Users} />
              <Route exact path="/projects" component={Projects} />
              <Route path="/logout" component={Logout} />
              <Route path="/reset" component={ConfirmPassword} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/loadPlan" component={LoadPlan} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/settings" component={Services} />

              <Route path="*" component={NotFound} />
            </Switch>
          </Layout> :
          <Switch>
            <Route path="/resetting/request" component={ForgetPasswordEmail} />
            <Route path="/reset" component={ConfirmPassword} />
            <Route exact path="/" component={Auth} />

            <Route path="*" component={NotFoundLogin} />
          </Switch>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

export default connect(mapStateToProps, null)(withSnackbar(withRouter(App)));
