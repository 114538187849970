import * as actionTypes from './actions-types';
import { userService } from '../../services/userService';
import {
    HYDRA_MEMBER,
    HYDRA_TOTAL_ITEMS
} from '../../services/utils/hydras';
import SnackbarUtils from '../../shared/SnackbarUtils';

///////////////////////////////////////////////    FOR USERS FETCHING
export const fetchUserSuccess = (users, total) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users: users,
        total: total
    };
};

export const fectUserFail = (error) => {
    return {
        type: actionTypes.FETCH_USERS_FAIL,
        error: error
    };
};

export const fetchUserStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    };
};

export const fetchUsers = (queryParams) => {
    return dispatch => {
        dispatch(fetchUserStart());
        userService.getUsers(queryParams)
            .then(res => dispatch(fetchUserSuccess(res.data[HYDRA_MEMBER], res.data[HYDRA_TOTAL_ITEMS])))
            .catch(err => dispatch(fectUserFail(err)));
    }
}

///////////////////////////////////////////////    FOR USERS UPDATE
export const setUserSuccess = (users) => {
    return {
        type: actionTypes.SET_USER_SUCCESS,
        users: users
    };
};

export const setUserFail = (error) => {
    return {
        type: actionTypes.SET_USER_FAIL,
        error: error
    };
};

export const setUserStart = () => {
    return {
        type: actionTypes.SET_USER_START
    };
};

export const setUser = (id, data, usersOutsideStore) => {
    return dispatch => {
        dispatch(setUserStart());
        userService.updateUser(id, data)
            .then(res => {
                if (res.status === 200) {
                    const index = usersOutsideStore.findIndex(x => x.id === id);
                    usersOutsideStore[index] = {
                        ...res.data,
                        counter: usersOutsideStore[index].counter,
                    };

                    dispatch(setUserSuccess(usersOutsideStore));
                    SnackbarUtils.success('User updated successfully');
                }
            })
            .catch(err => dispatch(setUserFail(err)));
    }
}

/////////////////////////////////////////////// FOR GET SINGLE USER DETAILS
export const getUserSuccess = (user) => {
    return {
        type: actionTypes.GET_USER_SUCCESS,
        user: user,
    };
};

//  FOR GET SINGLE USER FOR SINGLE SHIFTS
export const getSingleUserSuccess = (user) => {
    return {
        type: actionTypes.GET_SINGLE_USER_SUCCESS,
        users: [user],
    };
};
export const getUserFail = (error) => {
    return {
        type: actionTypes.GET_USER_FAIL,
        error: error
    };
};

export const getUserStart = () => {
    return {
        type: actionTypes.GET_USER_START
    };
};

export const getUser = (id, singleShiftsBool) => {
    return dispatch => {
        dispatch(getUserStart());
        userService.getUser(id)
            .then(res => {
                if (res.status === 200) {
                    const userDetails = res.data;
                    if (singleShiftsBool) {
                        dispatch(getSingleUserSuccess(userDetails));
                    } else {
                        dispatch(getUserSuccess(userDetails))
                    }
                }
            })
            .catch(err => dispatch(getUserFail(err)));
    }
}
