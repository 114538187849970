import React, { Component } from 'react';
import { Popover, Tooltip, Avatar } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { toTitleCase } from '../../../../shared/utility';

import ModalUIUserStyles from './ModalUIUser.module.scss';

export default class ModalUIUser extends Component {
    state = {
        anchorEl: null
    }

    componentDidMount() {
        this.props.onRefUser(this.handleClickUserPop.bind(this));
    }

    handleClickUserPop = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseUserPop = () => {
        this.setState({ anchorEl: null });
    };

    returnAvatars = (tableData) => {
        const avatars = []
        tableData.forEach(obj => {
            avatars.push(
                <Tooltip key={obj.id} title={obj.fullName}>
                    <Avatar className={ModalUIUserStyles.avatar} key={obj.id}>{toTitleCase(obj.fullName)}</Avatar>
                </Tooltip>

            );
        });
        return (
            <AvatarGroup spacing={-10} max={tableData.length} style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '20px' }}>
                {avatars}
            </AvatarGroup>);
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const { usersData, service } = this.props;
        const data = [...usersData];
        const bordeauxResoucre = [];
        const LondonResoucre = [];
        const parisResoucre = [];
        const tunisResources = [];

        let serviceName = '';

        data.forEach((obj) => {
            switch (obj.location) {
                case 'Bordeaux': bordeauxResoucre.push({ id: obj.id, fullName: obj.fullName });
                    break;
                case 'Londres': LondonResoucre.push({ id: obj.id, fullName: obj.fullName });
                    break;
                case 'Paris': parisResoucre.push({ id: obj.id, fullName: obj.fullName });
                    break;
                case 'Tunis': tunisResources.push({ id: obj.id, fullName: obj.fullName });
                    break;
                default:
            }
        });

        switch (service) {
            case 0: serviceName = 'Dev Service';
                break;
            case 1: serviceName = 'Design Service';
                break;
            case 2: serviceName = 'Crea Service';
                break;
            case 3: serviceName = 'All Services';
                break;
            default:
        }

        return (
            <div>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleCloseUserPop}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div style={{ height: '200px', width: '320px' }} className={ModalUIUserStyles.service}>
                        <div className={ModalUIUserStyles.divServiceName}>
                            <h6>{serviceName} : {this.props.usersData.length} Members</h6>
                        </div>
                        <div>
                            {bordeauxResoucre.length !== 0 ?
                                (
                                    <React.Fragment>
                                        <p style={{ marginTop: '5px', marginLeft: '7px' }}><strong>Bordeaux</strong></p>
                                        {this.returnAvatars(bordeauxResoucre)}

                                    </React.Fragment>
                                ) : null}

                            {LondonResoucre.length !== 0 ?
                                (
                                    <React.Fragment>
                                        <p style={{ marginTop: '5px', marginLeft: '7px' }}><strong>London</strong></p>
                                        {this.returnAvatars(LondonResoucre)}

                                    </React.Fragment>
                                ) : null}

                            {parisResoucre.length !== 0 ?
                                (
                                    <React.Fragment>
                                        <p style={{ marginTop: '5px', marginLeft: '7px' }}><strong>Paris</strong></p>
                                        {this.returnAvatars(parisResoucre)}

                                    </React.Fragment>
                                ) : null}


                            {tunisResources.length !== 0 ?
                                (
                                    <React.Fragment>
                                        <p style={{ marginTop: '5px', marginLeft: '7px' }}><strong>Tunis</strong></p>
                                        {this.returnAvatars(tunisResources)}

                                    </React.Fragment>
                                ) : null}
                        </div>
                    </div>
                </Popover>
            </div>
        )
    }
}
