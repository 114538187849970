import axios from 'axios';

import store from '../../store/store';
import * as actions from '../../store/actions/index';
import { HYDRA_DESCRIPTION } from './hydras';
import { authService } from '../authService';
import { setLocalStorageValues } from '../../shared/authUtility';
import SnackbarUtils from '../../shared/SnackbarUtils';

const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
const API_URL = process.env.REACT_APP_API_URL || '/api';
const MERCURE_PATTERN = /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/;
const MERCURE_TOKEN_KEY = 'mercure-token';

const DBAL_TYPES = {
    DAY_OFF: 'day_off_type',
    EXIGENCY_LEVEL: 'exigency_level_type',
    // Front has select options for that with a built rrule data. For now no retrieve from API DBAL.
    RECURRENCE_FREQUENCY: 'frequency_type',
}

const API_AUTH = {
    LOGIN: '/login_check',
    REFRESH_TOKEN: '/token/refresh'
}

const API_RESOURCES = {
    AFFECTATIONS: '/affectations',
    DAY_OFFS: '/dayoffs',
    EXTERNAL_DAY_OFF: '/external_day_offs',
    USERS: '/users',
    PROJECTS: '/projects',
    SERVICES: '/services',
    SUB_SERVICES: '/sub_services',
    RECURRENCE_RULES: '/recurrence_rules',
    GANTT: '/gantt',
    AFFECTATIONS_PER_WEEK_AND_SERVICES: '/affectations_per_week_and_services',
    WORKING_DAYS_PER_WEEK_AND_SERVICES: '/working_days_per_week_and_services',
}

const axiosInstance = axios.create({
    baseURL: `${BACK_BASE_URL}`,
});

// On every request pre-send set the `Authorization` header.
axiosInstance.interceptors.request.use(config => {
    config.headers = {
        ...config.headers,
        ...(
            !config.url.includes(API_AUTH.REFRESH_TOKEN) &&
            localStorage.getItem('token') &&
            {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        ),
    }
    return config;
});

axiosInstance.interceptors.response.use(
    res => res,
    err => {
        if (!err.response) {
            SnackbarUtils.error(
                'Please check your internet connection.',
                {
                    displayClose: false,
                    autoHideDuration: 10000,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                    },
                }
            );
            return Promise.reject(err);
        }

        if (err.response.config.url.includes('mercure')) {
            console.warn('Real time bad sync');
            return Promise.reject(err);
        }

        if (err.config.url.includes(API_AUTH.LOGIN) && err.response.status === 401) {
            SnackbarUtils.warning('Bad credentials');
            return Promise.reject(err);
        }

        if (err.config.url.includes(API_AUTH.REFRESH_TOKEN) && err.response.status === 401) {
            return rejectAndLogout(err);
        }

        // In case the error status isn't anuthorized type (401) we handle it in switch cases blocks
        if (err.response.status !== 401) {
            switch (err.response.status) {
                case 400:
                    SnackbarUtils.error(err.response?.data[HYDRA_DESCRIPTION] || 'An error occured');
                    break;
                case 403:
                    SnackbarUtils.warning('Unallowed to perform this action');
                    break;
                case 404:
                    SnackbarUtils.warning('Not found');
                    break;
                case 500:
                    SnackbarUtils.error('Internal server error');
                    break;
                default:
                    SnackbarUtils.error('Something went wrong !')
            }
            return Promise.reject(err);
        }

        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            return authService.refreshToken({ refreshToken })
                .then(response => {
                    setLocalStorageValues(response.data, true);
                    err.response.config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
                    return axiosInstance(err.response.config);
                })
                .catch(error => rejectAndLogout(error))
        }

        return rejectAndLogout(err);
    }
);

function rejectAndLogout(error) {
    // localStorage content is cleared inside the `logout` redux action.
    store?.dispatch(actions.logout());
    SnackbarUtils.warning('your session has expired');
    return Promise.reject(error);
}

export {
    BACK_BASE_URL,
    DBAL_TYPES,
    API_URL,
    API_AUTH,
    API_RESOURCES,
    MERCURE_PATTERN,
    MERCURE_TOKEN_KEY,
    axiosInstance,
}
