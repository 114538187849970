import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    dayoffs: [],
    loading: false,
    totalcount: 0
}

// default dayoff reducers
const getDayoffDefaultSuccess = (state, action) => {
    return updateObject(state, {
        dayoffs: action.dayoffs,
        loading: false,
        totalcount: action.total
    });
}

// fecth dayoff actions
const fectchDayoffStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const fetchDayoffSuccess = (state, action) => {
    return getDayoffDefaultSuccess(state, action);
}

const fetchDayoffFail = (state, _action) => {
    return updateObject(state, { loading: false });
}

// add dayoff actions
const addDayoffStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const addDayoffSuccess = (state, _action) => {
    return updateObject(state, {
        loading: false
    });
}

const addDayoffFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

// delete dayoff actions
const deleteDayoffStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const deleteDayoffSuccess = (state, action) => {
    return stopLoading(state, action);
}

const deleteDayoffFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

// edit dayoff actions
const editDayoffStart = (state, _action) => {
    return updateObject(state, { loading: true });
}

const editDayoffSuccess = (state, action) => {
    return stopLoading(state, action)
}

const editDayoffFail = (state, _action) => {
    return updateObject(state, { loading: false })
}

const emptyDayOffsList = (state, _action) => {
    return updateObject(
        state,
        {
            dayoffs: [],
        }
    );
}

const dayoffReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        // reset list
        case actionTypes.RESET_APP_DASHBOARD_DAY_OFFS: return emptyDayOffsList(state, action);
        // fecth actions
        case actionTypes.FETCH_DAY_OFF_START: return fectchDayoffStart(state, action);
        case actionTypes.FETCH_DAY_OFF_SUCCESS: return fetchDayoffSuccess(state, action);
        case actionTypes.FETCH_DAY_OFF_FAIL: return fetchDayoffFail(state, action);
        // add actions
        case actionTypes.ADD_DAY_OFF_START: return addDayoffStart(state, action);
        case actionTypes.ADD_DAY_OFF_SUCCESS: return addDayoffSuccess(state, action);
        case actionTypes.ADD_DAY_OFF_FAIL: return addDayoffFail(state, action);
        // add recurrent day off actions
        case actionTypes.ADD_RECURRENT_DAY_OFF_SUCCESS: return updateObject(state, { loading: false });
        // edit reducers
        case actionTypes.EDIT_DAY_OFF_START: return editDayoffStart(state, action);
        case actionTypes.EDIT_DAY_OFF_SUCCESS: return editDayoffSuccess(state, action);
        case actionTypes.EDIT_DAY_OFF_FAIL: return editDayoffFail(state, action);
        // delete actions
        case actionTypes.DELETE_DAY_OFF_START: return deleteDayoffStart(state, action);
        case actionTypes.DELETE_DAY_OFF_SUCCESS: return deleteDayoffSuccess(state, action);
        case actionTypes.DELETE_DAY_OFF_FAIL: return deleteDayoffFail(state, action);

        default: return state;
    }
}

export default dayoffReducer;

const stopLoading = (state, _action) => {
    return updateObject(state, {
        loading: false,
    });
}
