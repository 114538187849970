import React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { NOT_AUTHORIZED_LOGGED_OUT_ROUTES } from '../../shared/pathUtility';

function NotFoundLogin(props) {
    return (
        <div>
            {
                localStorage.getItem('token') === null &&
                props.location &&
                NOT_AUTHORIZED_LOGGED_OUT_ROUTES.includes(props.location.pathname) &&
                <Redirect to='/' />
            }
            <h3>Page Not Found</h3>
            <Link to="/">Back To Login</Link>
        </div>
    )
}

export default withRouter(NotFoundLogin);
