const AFFECTATION_FILTERS = {
    START_END_RANGE: 'startEndRange',
    IS_CONFIRMED: 'isConfirmed',
    ASSIGNED_USER_ID: 'assignedUser.id',
    ASSIGNED_USER_ID_EXACT: 'assignedUser.id[]',
    ASSIGNED_USER_MAIN_SERVICE_ID: 'assignedUser.mainService.id',
    ASSIGNED_USER_MAIN_SERVICE_ID_EXACT: 'assignedUser.mainService.id[]',
    RECURRENCE_RULE_ID: 'recurrenceRule.id',
    ASSIGNED_USER_IS_FICTIVE: 'assignedUser.isFictive',
    PAGINATION: 'pagination',
}

const DAY_OFF_FILTERS = {
    START_END_RANGE: 'startEndRange',
    USER_DAY_OFF_ID: 'userDayOff.id',
    USER_DAY_OFF_ID_EXACT: 'userDayOff.id[]',
    RECURRENCE_RULE_ID: 'recurrenceRule.id',
    PAGINATION: 'pagination',
}

const EXTERNAL_DAY_OFF_FILTERS = {
    START_END_RANGE: 'startEndRange',
    USER_DAY_OFF_ID: 'user.id',
    USER_DAY_OFF_ID_EXACT: 'user.id[]',
    PAGINATION: 'pagination',
}

const USER_FILTERS = {
    IS_ACTIVE: 'isActive',
    IS_ENABLE: 'isEnable',
    FULL_NAME: 'fullName',
    ROLES: 'roles',
    EMAIL: 'email',
    LOCATION: 'location',
    LOCATION_EXACT: 'location[]',
    JOB_TITLE: 'jobTitle',
    MAIN_SERVICE: 'mainService',
    MAIN_SERVICE_EXACT: 'mainService[]',
    SUB_SERVICE: 'subService',
    SUB_SERVICE_EXACT: 'subService[]',
    MAIN_SERVICE_EXIST: 'exists[mainService]',
    SUB_SERVICE_EXIST: 'exists[subService]',
    PAGINATION: 'pagination',
    PAGE: 'page',
    ITEMS_PER_PAGE: 'itemsPerPage',
    IS_FICTIVE: 'isFictive',
}

const PROJECT_FILTERS = {
    IS_ACTIVE: 'isActive',
    NAME: 'name',
    DESCRIPTION: 'description',
    PROJECT_MANAGER_FULL_NAME: 'projectManager.fullName',
    PAGINATION: 'pagination',
    PAGE: 'page',
    ITEMS_PER_PAGE: 'itemsPerPage',
}

const SERVICE_FILTERS = {
    POSITION_ORDER: 'service[position]',
    ENABLED: 'enabled',
    PAGINATION: 'pagination',
}

const RECURRENT_RESOURCES_FILTERS = {
    UPDATE_R_RULE: 'updateRRule',
    THIS_AND_THE_FOLLOWING_EVENTS: 'thisAndFollowingEvents',
}

// Non-resource filter
const ORDER_FILTERS = {
    ASC: 'asc',
    DESC: 'desc',
}

/**
 * BuildFilters function handle values of type `array`, `number` and `string`.
 */
const buildFilters = (filters = {}) => Object.keys(filters)
    .reduce((filterQuery, filterKey, filterIndex) => {
        filterQuery += filterIndex === 0 ? '?' : '&';
        filterQuery += Array.isArray(filters[filterKey]) ?
            filters[filterKey].map(value => `${filterKey}=${value}`).join("&") :
            `${filterKey}=${filters[filterKey]}`;
        return filterQuery;
    }, '');

export {
    AFFECTATION_FILTERS,
    DAY_OFF_FILTERS,
    EXTERNAL_DAY_OFF_FILTERS,
    USER_FILTERS,
    PROJECT_FILTERS,
    SERVICE_FILTERS,
    RECURRENT_RESOURCES_FILTERS,
    buildFilters,
    ORDER_FILTERS,
}
