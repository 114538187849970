import { API_RESOURCES, axiosInstance } from './utils/apiResources';
import { buildFilters, PROJECT_FILTERS } from './utils/filters';

export const projectService = {
    getProjects,
}

function getProjects(queryParams) {
    // By default we fetch only active projects accounts
    queryParams = {
        ...queryParams,
        [PROJECT_FILTERS.IS_ACTIVE]: true,
    }
    return axiosInstance.get(
        `${API_RESOURCES.PROJECTS}${buildFilters(queryParams)}`
    );
}
