import React from 'react';
import { TextField } from '@material-ui/core';

export default function NumericInput(props) {
    return (
        <TextField
            id={props.id || 'numeric-input'}
            name={props.name || 'numeric-input'}
            type="number"
            autoComplete="false"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: props.min || 0 } }}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            style={props.style || {}}
            variant={props.variant || "standard"}
            className={props.className || ''}
        />
    )
}
