import React from 'react';
import { OptionsObject, useSnackbar, WithSnackbarProps } from 'notistack';

let useSnackbarRef: WithSnackbarProps

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

/**
 * @see https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
 */
const SnackbarUtils =  {
  success(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'success' })
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'warning' })
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'info' })
  },
  error(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'error' })
  },
  toast(msg: string, options: OptionsObject = {}) {    
    useSnackbarRef.enqueueSnackbar(msg, options)
  }
}

export default SnackbarUtils
