import { API_RESOURCES, axiosInstance } from './utils/apiResources';
import { buildFilters, USER_FILTERS } from './utils/filters';

export const userService = {
    getUsers,
    getUser,
    updateUser,
    resetPasswordEmail,
    resetPassword,
}


function getUsers(queryParms = {}) {
    // By default we fetch only active users accounts
    queryParms = {
        ...queryParms,
        [USER_FILTERS.IS_ACTIVE]: true,
    }
    return axiosInstance.get(
        `${API_RESOURCES.USERS}${buildFilters(queryParms)}`
    );
}

function getUser(id) {
    return axiosInstance.get(
        `${API_RESOURCES.USERS}/${id}`
    );
}

function updateUser(id, data) {
    return axiosInstance.put(
        `${API_RESOURCES.USERS}/${id}`,
        data
    );
}

function resetPasswordEmail(data) {
    return axiosInstance.post(
        `${API_RESOURCES.USERS}/reset_password`,
        data
    );
}

function resetPassword(data) {
    return axiosInstance.post(
        `${API_RESOURCES.USERS}/new_password`,
        data
    );
}
