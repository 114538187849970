import React from 'react';
import {
    Skeleton,
    ToggleButton,
    ToggleButtonGroup,
} from '@material-ui/lab';

export default function ServiceNavigationSkeleton(props) {
    return (
        <ToggleButtonGroup
            className="maintabs"
            exclusive
            size="small"
        >
            {
                Array(5).fill(0).map((_, i) => (
                    <ToggleButton
                        key={i}
                        value={i}
                        className={props.getClass(0, 1)}
                        disabled
                    >
                        <Skeleton style={{width: '5rem', height: '3rem'}} variant="text" />
                    </ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    )
}
